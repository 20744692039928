<template>
  <v-container pt-0 fluid>
    <v-layout row wrap>
      <v-flex>
        <v-card
          elevation="12"
        >
          <v-toolbar flat color="primary">
            <v-toolbar-title class="yellow-color-text">
              {{ $t(`messages.alerts`) }}
            </v-toolbar-title>
          </v-toolbar>
           <v-card-text class="py-2">
            <v-layout row wrap pa-1 align-center justify-center>           
              <v-flex xs12 md6 class="pl-2 pr-2">
                   <v-text-field
                    v-model="object.name"
                    :label="$t(`users.name`)" 
                    :disabled="!isEditting"
                    :color="inputColor"
                    :error="objectErrors.name !== undefined"
                    :error-messages="parseErrors(objectErrors.name)"
                  />
                </v-flex>
                <v-flex xs12 md6 class="pl-2 pr-2">
                   <v-text-field
                    v-model="object.phone"
                    :label="$t(`users.phone`)" 
                    :disabled="!isEditting"
                    :color="inputColor"
                    :error="objectErrors.phone !== undefined"
                    :error-messages="parseErrors(objectErrors.phone)"
                  />
                </v-flex>
                <v-flex xs12 class="pl-2 pr-2" v-if="activationCode">
                   <v-text-field
                    v-model="object.validationCode"
                    :label="$t(`users.code`)" 
                    :disabled="!activationCode"
                    :color="inputColor"
                    :error="objectErrors.validationCode !== undefined"
                    :error-messages="parseErrors(objectErrors.validationCode)"
                  />
                </v-flex>
              </v-layout>
           </v-card-text>

           <v-card-actions  style="max-height: 70px;">
              <a-button v-if="(!activationCode && !isEditting && !user.phoneConfirmation) && (!user.phoneConfirmation)" :state="buttonState" color="warning" @click="requestActivationCode">{{ $t('actions.requestCode') }}</a-button>

              <a-button  v-if="activationCode || isEditting"  color="error" @click="discardChanges">{{ $t('actions.discard') }}</a-button>
              
              <v-spacer />
              
              <a-button :state="buttonState" v-if="!activationCode && !isEditting" color="primary" @click="editUserInformation">{{ $t('actions.editUser') }}</a-button>

              <a-button :state="buttonState" v-if="activationCode || isEditting" color="primary" @click="saveUserInformation">{{ $t('actions.save') }}</a-button>

            </v-card-actions>             
         
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import AButton from '@/components/Button.vue'
import requestCode from '@/graphql/mutations/users/requestCode.gql'
import updateUser from '@/graphql/mutations/users/updateUser.gql'
import { mapState, mapGetters } from 'vuex'
import moment from 'moment'

const objectName = {
  single: 'message',
  multiple: 'messages',
  object: {
    single: 'Message',
    multiple: 'Messages'
  }
}
export default {
  components: {
    AButton
  },

	data () {
		return {
			objectName,
			search: '',
      object: {},
      objectErrors: {},
      isEditting: false,
      activationCode: false,
      buttonState: false,

		}
	},

  watch: {
    user: function () {
      this.object = Object.assign({}, {
        name: this.user.name,
        phone: this.user.phone,
        validationCode: '',
      })
    }
  },
	computed: {
		...mapState(['messagesMonitor', 'user', 'width', 'dark', 'language']),
    ...mapGetters(['inputColor']),
    
    messagesParsed () {
    	let messages = []
    	let monitor = Object.entries(this.messagesMonitor)
    	for (var i = monitor.length - 1; i >= 0; i--) {
    		messages.push(monitor[i][1])
    	}
    	return messages
    }
	},

  mounted () {
    this.object = Object.assign({}, {
      name: this.user.name,
      phone: this.user.phone,
      validationCode: '',
    })
  },

	methods: {

    discardChanges () {

      this.object = Object.assign({}, {
        name: this.user.name,
        phone: this.user.phone,
        validationCode: '',
      })
      this.activationCode = false

      this.isEditting = false

    },

    parseErrors (array) {
      if (array === undefined) {
        return []
      }
      return array.map((currentValue, index, array) => {
        return currentValue.charAt(0).toUpperCase() + currentValue.substr(1)
      }).join(', ')
    },

    date (date) {
      return moment(date).format("YYYY-MM-DD")
    },

    time (time) {
      return moment(time).format("HH:mm:ss")
    },
    
    updateMessages () {
      this.$store.dispatch('getMessages')
    },

    requestActivationCode () {
      this.activationCode = true
      this.requestCode()
    },

    requestCode () {
      this.buttonState = true
      this.objectErrors = {}
      let variables = {
        apiToken: this.user.apiToken,
        language: this.language.toUpperCase()
      }

      let mutation = requestCode

      this.$apollo.mutate({
        mutation,
        variables
      }).then(response => {
        let { status, errors, result } = response.data['requestCode']

        if (status === 'OK') {
          this.objectErrors = {}

          this.$store.commit('toggleAlert', {
            type: 'success',
            message: this.$i18n.t(`users.requests.active`)
          })
        } else {
          this.objectErrors = errors
          this.$store.commit('toggleAlert', {
            type: 'warning',
            message: this.$i18n.t('errors.invalidFields')
          })
        }
      }).catch(error => {
        
        this.$store.commit('toggleAlert', {
          type: 'error',
          message: this.$i18n.t('errors.internalError')
        })
      }).finally(() => {
        this.buttonState = false
      })
    },


    editUserInformation () {
      this.isEditting = true
    },

    saveUserInformation () {
      this.updateUserInformation()
    },

    updateUserInformation () {
      
      this.buttonState = true
      this.objectErrors = {}

      let variables = {
        object : this.object,
        apiToken: this.user.apiToken,
        language: this.language.toUpperCase(),
        validationCode: this.activationCode
      }

      let mutation = updateUser

      this.$apollo.mutate({
        mutation,
        variables
      }).then(response => {
        
        let { status, errors, result } = response.data['updateUser']

        if (status === 'OK') {
          
          this.objectErrors = {}
          this.$store.commit('toggleAlert', {
            type: 'success',
            message: this.$i18n.t(`${this.activationCode ? 'users.success.activationCode' : 'users.success.updated'}`)
          })
        this.$store.dispatch(`getUserChanges`)
          this.activationCode = false
          this.isEditting = false
        } else {
          this.objectErrors = errors
          this.$store.commit('toggleAlert', {
            type: 'warning',
            message: this.$i18n.t('errors.invalidFields')
          })
        }
      }).catch(error => {
        
        this.$store.commit('toggleAlert', {
          type: 'error',
          message: this.$i18n.t('errors.internalError')
        })
      }).finally(() => {
        this.buttonState = false
      })
    },
  }
}
</script>