// Main dependencies
import Vue from 'vue'
import moment from 'moment'

// Import core-js
import 'core-js'

// Plugins
import vuetify from '@/plugins/vuetify'
import i18n from '@/plugins/i18n'
import router from '@/plugins/router'
import store from '@/plugins/vuex'
import apolloProvider from '@/plugins/apollo'
import assets from '@/plugins/assets'
import cookies from '@/plugins/cookies'

// Template
import App from '@/App.vue'

// Components
import '@mdi/font/css/materialdesignicons.css'
import "vuetify/dist/vuetify.min.css";

Vue.use(assets)
Vue.use(cookies)

document.addEventListener('DOMContentLoaded', () => {
  const el = document.body.appendChild(document.createElement('app'))
  /* eslint-disable no-new */
  new Vue({
    el,
    store,
    router,
    vuetify,
    i18n,
    apolloProvider,
    created () {
      this.$store.subscribe(mutation => {
        switch (mutation.type) {
          case 'setDark':
            this.$cookies.set('dark', mutation.payload)
            this.$vuetify.theme.dark = mutation.payload
            break

          case 'setSidebar':
            this.$cookies.set('sidebar', mutation.payload)
            break

          case 'setMini':
            this.$cookies.set('mini', mutation.payload)
            break
        }
      })

      let serverVersion = ''

      try {
        serverVersion = document.querySelector('meta[name="app_version"]').getAttribute('content')
      } catch (e) {
        serverVersion = ''
      }

      
      this.$store.commit('setServerVersion', serverVersion)
    },

    beforeMount () {
      if (this.$cookies.get('dark') !== undefined) {
        if (this.$cookies.get('dark') === 'true') {
          this.$store.commit('setDark', true)
        } else {
          this.$store.commit('setDark', false)
        }
      }

      if (this.$cookies.get('sidebar') !== undefined) {
        if (this.$cookies.get('sidebar') === 'true') {
          this.$store.commit('setSidebar', true)
        } else {
          this.$store.commit('setSidebar', false)
        }
      }

      if (this.$cookies.get('mini') !== undefined) {
        if (this.$cookies.get('mini') === 'true') {
          this.$store.commit('setMini', true)
        } else {
          this.$store.commit('setMini', false)
        }
      }
    },

    render (h) { return h(App) }
  })
})


// Add date and time function for vue prototype

Vue.prototype.$date = function (date) {
  return moment.unix(date).format('YYYY-MM-DD')
}

Vue.prototype.$time = function (date) {
  return moment.unix(date).format('HH:mm:ss')
}

Vue.prototype.$lastUpdate = function (message) {
  if (message !== undefined) {
    return this.parseDate(message.lastEventNodeDate)
  } else {
    return this.$i18n.t('node.noMessages')
  }
}

Vue.prototype.$nodeMessageColor = function (message) {
  if (message === undefined) {
    return 'red darken-4'
  }

  const statusInt = message.lastEventNode

  if (statusInt === 100 || statusInt === 20) {
    return 'red darken-4'
  }

  const messageTime = message.lastEventNodeDate
  const seconds = (Date.now() / 1000) - messageTime
  if (seconds < 6400) {
    return 'green'
  } else if (seconds < 7200) {
    return 'yellow darken-2'
  }

  return 'red darken-4'
}

Vue.prototype.$nodeMessageToText = function (message) {
  if (message === undefined) {
    return this.$i18n.t('node.noMessages')
  }
  
  const messageEvent = message.lastEventNodeString
  if (messageEvent === null) {
    return this.$i18n.t('node.noMessages')
  }

  const messageTime = message.lastEventNodeDate
  const seconds = (Date.now() / 1000) - messageTime
  if (seconds < 6400) {
    return messageEvent
  } else if (seconds < 7200) {
    return 'Falla Reporte Periódico'
  }

  return 'Falla Reporte Periódico'
}