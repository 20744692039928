<template>
  <v-app>
    <c-sidebar />    

    <v-content class="test" :style="sidebarStyle">
      <c-header />

      <div class="px-4" style="margin-top: 32px !important">
        <transition name="component-fade" mode="out-in">
          <router-view />
        </transition>
      </div>
    </v-content>
  
    <c-footer />
  </v-app>
</template>

<script>
import { Header, Footer, Sidebar } from '@/components/platform'

export default {
  components: {
    'c-sidebar': Sidebar,
    'c-footer': Footer,
    'c-header': Header
  },
  computed: {
    sidebarState() {
      return this.$store.state.mini
    },
    isMobile () {
      return this.$vuetify.breakpoint.mobile
    },
    sidebarStyle() {
      if (this.isMobile) {
        return 'padding: 0px 0px 0px 0px !important;'
      }
      if (!this.sidebarState) {
        return 'padding: 0px 0px 0px 256px !important;'
      } else {
        return 'padding: 0px 0px 0px 56px !important;'
      }
    } 
  }
}
</script>
