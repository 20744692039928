<template>
  <v-container pt-0 fluid>
    <v-layout row wrap pb-3>
      <div id="kitsSearchFixed">
        <div class="searchFixed">
          <v-card outlined elevation="2" class="ma-3">
            <v-card-text>
              <v-text-field
                v-model="search"
                append-icon="search"
                :label="$t('actions.search')"
                single-line
                :color="inputColor"
                hide-details
              />
            </v-card-text>
          </v-card>
        </div>
      </div>
      <v-flex>
        <v-card
          id="kitsCard"
          elevation="12"
        >
          <v-toolbar flat color="primary">
            <v-toolbar-title class="yellow-color-text">
              {{ $t(`pages.${$route.name}`) }}
            </v-toolbar-title>
            <v-spacer />
            <v-text-field
              id="kitsSearch"
              v-model="search"
              append-icon="search"
              :label="$t('actions.search')"
              single-line
              :color="inputColor"
              hide-details
            />
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  color="white"
                  icon
                  ma-0
                  style="margin: 0;"
                  v-on="on"
                  @click="loraNodeAssignationModal = true"
                >
                  <v-icon class="yellow-color-text">mdi-satellite-uplink</v-icon>
                </v-btn>  
              </template> 
              <span class="white--text">{{ $t(`${objectName.multiple}.multipleAssignationLoraNodeId`) }}</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  slot="activator"
                  color="white"
                  text
                  icon
                  ma-0
                  rounded
                  style="margin: 0;"
                  v-on="on"
                  @click="showConfiguration"
                >
                  <v-icon class="yellow-color-text">mdi-cog</v-icon>
                </v-btn>
              </template>

              <span class="white--text">{{ $t(`actions.configuration`) }}</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  color="white"
                  icon
                  ma-0
                  style="margin: 0;"
                  v-on="on"
                  @click="multipleAssignationModal = true"
                >
                  <v-icon class="yellow-color-text">mdi-account-supervisor-circle</v-icon>
                </v-btn>  
              </template> 
              <span class="white--text">{{ $t(`${objectName.multiple}.multipleAssignation`) }}</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  color="white"
                  icon
                  ma-0
                  style="margin: 0;"
                  v-on="on"
                  @click="loadKitsDialog = true"
                >
                  <v-icon class="yellow-color-text">mdi-upload</v-icon>
                </v-btn>  
              </template> 
              <span class="white--text">{{ $t(`${objectName.multiple}.loadKits`) }}</span>
            </v-tooltip>
            <!-- <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  color="white"
                  icon
                  ma-0
                  style="margin: 0;"
                  v-on="on"
                  @click="download"
                >
                  <v-icon class="yellow-color-text">mdi-download</v-icon>
                </v-btn>  
              </template> 
              <span class="white--text">{{ $t(`${objectName.multiple}.download`) }}</span>
            </v-tooltip> -->
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  color="white"
                  icon
                  ma-0
                  style="margin: 0;"
                  v-on="on"
                  @click="newItem"
                >
                  <v-icon class="yellow-color-text">mdi-plus-circle-outline</v-icon>
                </v-btn>  
              </template> 

              <span class="white--text">{{ $t(`${objectName.multiple}.titleNew`) }}</span>
            </v-tooltip>
            <refresh-button v-if="!isMobile"></refresh-button>
            <export-button :headers="userConfiguration" :items="data" :file-name="'Kits'" :search="search"></export-button>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  slot="activator"
                  :color="(filtered && viewType === 'kits') ? 'yellow' : 'white'"
                  text
                  icon
                  ma-0
                  rounded
                  outlined
                  style="margin: 0;"
                  v-on="on"
                  @click="() => changeType(viewType === 'filters' ? 'kits' : 'filters')"
                >
                  <v-icon>{{ viewType === 'kits' ? 'mdi-filter' : 'mdi-package-variant' }}</v-icon>
                </v-btn>
              </template>
              <span class="white--text">{{ $t(`${objectName.multiple}.changeFilter`) }}</span>
            </v-tooltip>
          </v-toolbar>
          <v-card-text v-if="viewType ==='kits'">
            <v-data-table
              fixed-header
              height="800"
              :headers="renderHeaders"
              :items="filtered ? filteredKits : data"
              :search="search"
              sort-by="reference"
              :items-per-page="50"
              :footer-props="{
                'next-icon': 'mdi-chevron-right',
                'prev-icon': 'mdi-chevron-left'
              }"
            >
              <template v-slot:body="{ items }">
                <tbody>
                  <tr v-for="item in items" :key="item.id">
                    <!-- <td>{{ item.reference }}</td>
                    <td>{{ item.bagName }}</td>
                    <td>{{ item.loraNodeName }}</td>
                    <td>{{ item.companyLocalName }}</td> -->
                    <td v-for="(header, index) in userConfiguration" :key="`${header.value}-${item.id}`">
                      <span :style="index === 0 ? 'font-weight: bold;' : ''" v-html="header.format(item[header.value])"></span>
                    </td>
                    <td style="text-align: right; padding: 0;">
                      <v-btn
                        text
                        icon
                        rounded
                        color="info"
                        @click="showItem(item.id)"
                      >
                        <v-icon>mdi-file-find-outline</v-icon>
                      </v-btn>
                      <v-btn
                        v-if="!item.assigned && user.parentType === 'Company::Central'"
                        text
                        icon
                        rounded
                        color="warning"
                        @click="editItem(item.id)"
                      >
                        <v-icon>mdi-file-document-edit-outline</v-icon>
                      </v-btn>
  
                      <v-btn
                        v-if="!item.assigned && user.parentType === 'Company::Central'"
                        text
                        icon
                        rounded
                        color="error"
                        @click="deleteItem(item.id, item.reference)"
                      >
                        <v-icon>mdi-file-cancel-outline</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
              <tr slot="no-results">
                <td :colspan="headers.length" v-html="$t('helpers.notFound', { search })" />
              </tr>
            </v-data-table>
          </v-card-text>
          <v-card-text v-if="viewType === 'filters'">
            <v-layout row wrap pa-1 align-center justify-center>
              <v-flex class="pl-1 pr-1" xs12 md4>
                <v-autocomplete
                  v-model="bag"
                  :items="bags"
                  :label="$t(`${objectName.multiple}.bags`)" 
                  :color="inputColor"
                  item-text="name"
                  item-value="value"
                />
              </v-flex>
              <v-flex class="pl-1 pr-1" xs12 md4>
                <v-autocomplete
                  v-model="node"
                  :items="nodes"
                  :label="$t(`${objectName.multiple}.nodes`)" 
                  :color="inputColor"
                  item-text="name"
                  item-value="name"
                />
              </v-flex>
              <v-flex class="pl-1 pr-1" xs12 md4>
                <v-autocomplete
                  v-model="companyLocal"
                  :items="companyLocals"
                  :label="$t(`${objectName.multiple}.companyLocals`)" 
                  :color="inputColor"
                  item-text="name"
                  item-value="name"
                />
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-card-actions v-if="viewType === 'filters'">
            <v-spacer></v-spacer>
            <v-btn
              color="danger"
              text
              @click="cleanFilters"
            >
              {{ $t(`${objectName.multiple}.cleanFilters`) }}
            </v-btn>
            <v-btn
              color="primary"
              @click="filterData"
            >
              {{ $t(`${objectName.multiple}.filter`) }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>

    <v-dialog
      id="formModal"
      v-model="formModal"
      scrollable
      persistent
      :fullscreen="isFullScreen"
      :overlay="false"
      :max-width="width.dialog"
      transition="dialog-transition"
      :dark="dark"
    >
      <v-card>
        <v-toolbar flat color="primary">
          <v-toolbar-title class="yellow-color-text">
            {{ title }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-layout row wrap pa-1 align-center>
            <v-flex class="pl-2 pr-2" xs12 sm4>
              <v-text-field
                v-model="object.reference"
                :label="$t(`${objectName.multiple}.reference`)" 
                :disabled="!isEditting"
                :color="inputColor"
                :error="objectErrors.reference !== undefined"
                :error-messages="parseErrors(objectErrors.reference)"
              />
            </v-flex>
            <v-flex class="pl-2 pr-2" xs12 sm4>
              <v-autocomplete
                v-model="object.companyLocalId"
                :items="companyLocals"
                :label="$t(`${objectName.multiple}.companyLocalId`)" 
                :disabled="!isEditting"
                :color="inputColor"
                :error="objectErrors.companyLocalId !== undefined"
                :error-messages="parseErrors(objectErrors.companyLocalId)"
                item-text="name"
                item-value="id"
              />
            </v-flex>
            <v-flex class="pl-2 pr-2" xs12 sm3>
              <v-autocomplete
                v-model="loraSensorId"
                :items="availableSensors()"
                :label="$t(`${objectName.multiple}.loraSensors`)" 
                :disabled="!isEditting"
                :color="inputColor"
                :error="objectErrors.loraSensors !== undefined"
                :error-messages="parseErrors(objectErrors.loraSensors)"
                item-text="deviceEui"
                item-value="id"
              >
                <template v-slot:selection="{ item, index }">
                  {{ item.deviceEui }}
                </template>
                <template v-slot:item="{ item, index }">
                  {{ item.deviceEui }}
                </template>
              </v-autocomplete>
            </v-flex> 
            <v-flex class="pl-2 pr-2" xs12 sm1>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    color="grey"
                    :disabled="!isEditting"
                    icon
                    ma-0
                    style="margin: 0;"
                    v-on="on"
                    @click="addSensor"
                  >
                    <v-icon class="yellow-color-text">mdi-plus-circle-outline</v-icon>
                  </v-btn>  
                </template> 

                <span class="white--text">{{ $t(`${objectName.multiple}.titleNew`) }}</span>
              </v-tooltip>
            </v-flex> 
            <v-flex  xs12 sm11 class="pl-2 pr-2">
              <template
                v-for="(sensor, index) in object.loraSensors"
              >
                <v-layout row wrap :key="index" class="pr-3 pl-3">
                  <v-flex xs12 sm6 class="pr-2">
                    <v-text-field
                      v-model="sensor.name"
                      :label="$t(`${objectName.multiple}.loraSensor`)" 
                      :color="inputColor"
                      :error="objectErrors.loraSensors !== undefined"
                      :disabled="true"
                      :error-messages="parseErrors(objectErrors.loraSensors)"
                    />
                  </v-flex>
                  <v-flex xs12 sm6 class="pl-2">
                    <v-text-field
                      v-model="sensor.identifier"
                      :label="$t(`${objectName.multiple}.identifier`)" 
                      :color="inputColor"
                      :error="objectErrors.loraSensors !== undefined"
                      :disabled="!isEditting"
                    />
                  </v-flex>
                </v-layout>
              </template>
            </v-flex>
            <!-- <v-flex xs12>
              <v-treeview
                :open="open"
                :items="object.loraSensors"
                :open-all="true"
                item-key="id"
              >
                <template v-slot:prepend="{ item, open }">
                  {{ item.name }}
                </template>
                <template v-slot:append="{ item, open }">
                  <v-text-field
                    v-model="item.identifier"
                    class="pl-2 pr-2"
                    style="width: 100%"
                    :label="$t(`${objectName.multiple}.identifier`)" 
                    :disabled="!isEditting"
                    :color="inputColor"
                  />
                  <v-btn
                    v-if="item.type == 'loraSensor'"
                    text
                    icon
                    rounded
                    color="error"
                    :disabled="!isEditting"
                    @click="deleteSensor(item)"
                  >
                    <v-icon>mdi-file-cancel-outline</v-icon>
                  </v-btn>
                </template>
              </v-treeview>
            </v-flex>    -->
          </v-layout>
        </v-card-text>

        <v-card-actions v-if="isEditting" style="max-height: 70px;">
          <v-btn color="error" @click="discardChanges">{{ $t('actions.discard') }}</v-btn>

          <v-spacer />

          <a-button v-if="object.id !== undefined" :state="buttonState" color="primary" @click="saveChanges">{{ $t('actions.save') }}</a-button>
          
          <a-button v-else-if="object.id === undefined" :state="buttonState" color="primary" @click="createItem">{{ $t('actions.create', { name: $t(`${objectName.multiple}.objectName`) }) }}</a-button>
        </v-card-actions>

        <v-card-actions v-else style="max-height: 70px;">
          <v-btn color="error" @click="discardChanges">{{ $t('actions.close') }}</v-btn>
          
          <v-spacer />
          
          <v-btn v-if="user.parentType === 'Company::Central'" color="primary" @click="editItem(object.id)">{{ $t('actions.edit', { name: $t(`${objectName.multiple}.objectName`)}) }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="deleteDialog"
      max-width="290"
    >
      <v-card>
        <v-card-title class="headline" style="font-size: 1.2em !important; text-align: center;">{{ $t('helpers.confirmDeleteReference', { name: $t(`${objectName.multiple}.objectName`), reference: objectReference }) }} </v-card-title>

        <v-card-actions style="text-align: center;">
          <v-spacer />

          <v-btn
            color="error"
            flat
            icon
            @click="deleteConfirm = false; deleteDialog = false; deleteId = 0"
          >
            <v-icon>mdi-cancel</v-icon>
          </v-btn>

          <v-btn
            color="success"
            flat
            icon
            @click="deleteConfirmation"
          >
            <v-icon>mdi-checkbox-marked-circle-outline</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      id="multipleAssignationModal"
      v-model="multipleAssignationModal"
      scrollable
      persistent
      :fullscreen="isFullScreen"
      :overlay="false"
      :max-width="width.dialog"
      transition="dialog-transition"
      :dark="dark"
    >
      <v-card>
        <v-toolbar flat color="primary">
          <v-toolbar-title class="yellow-color-text">
            {{ assignationTittle }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-layout row wrap pa-2 align-center>
            <v-flex class="pl-2 pr-2" xs12 sm6>
              <v-autocomplete
                v-model="multiple.companyLocalId"
                :items="companyLocals"
                :label="$t(`${objectName.multiple}.companyLocalId`)" 
                :color="inputColor"
                item-text="name"
                item-value="id"
              />
            </v-flex>
            <v-flex class="pl-2 pr-2" xs12 sm6>
              <v-autocomplete
                v-model="multiple.kits"
                :items="freeKits"
                :label="$t(`${objectName.multiple}.kits`)" 
                :color="inputColor"
                :multiple="true"
                item-text="reference"
                item-value="id"
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip>{{ item.reference }}</v-chip>
                </template>
                <template v-slot:item="{ item, index }">
                  <v-chip>{{ item.reference }}</v-chip>
                </template>
              </v-autocomplete>
            </v-flex> 
          </v-layout>
        </v-card-text>

        <v-card-actions style="max-height: 70px;">
          <v-btn color="error" @click="discardMultiplesChanges">{{ $t('actions.discard') }}</v-btn>

          <v-spacer />

          <a-button :state="buttonState" color="primary" @click="saveMultipleChanges">{{ $t('actions.save') }}</a-button>
          
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      id="loraAssignationModal"
      v-model="loraNodeAssignationModal"
      scrollable
      persistent
      :fullscreen="isFullScreen"
      :overlay="false"
      :max-width="width.dialog"
      transition="dialog-transition"
      :dark="dark"
    >
      <v-card>
        <v-toolbar flat color="primary">
          <v-toolbar-title class="yellow-color-text">
            {{ assignationTittle }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-layout row wrap pa-2 align-center>
            <v-flex class="pl-2 pr-2" xs12 sm6>
              <v-autocomplete
                v-model="loraNodeObject.loraNodeId"
                :items="loraNodes"
                :label="$t(`${objectName.multiple}.loraNodes`)" 
                :color="inputColor"
                item-text="name"
                item-value="id"
              />
            </v-flex>
            <v-flex class="pl-2 pr-2" xs12 sm6>
              <v-autocomplete
                v-model="loraNodeObject.kits"
                :items="data"
                :label="$t(`${objectName.multiple}.kits`)" 
                :color="inputColor"
                :multiple="true"
                item-text="reference"
                item-value="id"
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip>{{ item.reference }}</v-chip>
                </template>
                <template v-slot:item="{ item, index }">
                  <v-chip>{{ item.reference }}</v-chip>
                </template>
              </v-autocomplete>
            </v-flex> 
          </v-layout>
        </v-card-text>

        <v-card-actions style="max-height: 70px;">
          <v-btn color="error" @click="discardLoraNodeChanges">{{ $t('actions.discard') }}</v-btn>

          <v-spacer />

          <a-button :state="buttonState" color="primary" @click="saveLoraNodeChanges">{{ $t('actions.save') }}</a-button>
          
        </v-card-actions>
      </v-card>
    </v-dialog>
    <configuration-table-dialog 
      :dialog="configurationModal" 
      :headers="configurationHeaders"
      :defaultHeaders="defaultHeaders"
      configurationView="kits"
      @update-dialog="(value) => configurationModal = value"
    />
    <load-kits-dialog 
      :dialog="loadKitsDialog" 
      @update-dialog="(value) => loadKitsDialog = value"
    />
  </v-container>
</template>

<script>
import AButton from '@/components/Button.vue'
import ConfigurationTableDialog from '../../components/platform/ConfigurationTableDialog.vue'
import LoadKitsDialog from '../../components/utils/LoadKitsDialog.vue'
import RefreshButton from '@/components/utils/RefreshButton.vue'
import ExportButton from '@/components/utils/ExportButton.vue'

import { mapState, mapGetters } from 'vuex'

import add from '@/graphql/mutations/kits/add.gql'
import edit from '@/graphql/mutations/kits/edit.gql'
import object from '@/graphql/queries/kits/detail.gql'
import assign from '@/graphql/mutations/kits/assign.gql'
import assignLora from '@/graphql/mutations/kits/assignLora.gql'

import moment from 'moment'
import readXlsxFile from 'read-excel-file'

const objectName = {
  single: 'kit',
  multiple: 'kits',
  object: {
    single: 'Kit',
    multiple: 'Kits'
  }
}

export default {
  components: {
    'a-button': AButton,
    ConfigurationTableDialog,
    RefreshButton,
    LoadKitsDialog,
    ExportButton
  },

  data () {
    return {
      objectName,
      multipleAssignationModal: false,
      loraNodeAssignationModal: false,
      loadKitsDialog: false,
      multiple: {
        companyLocalId: undefined,
        kits: []
      },
      loraNodeObject: {
        loraNodeId: undefined,
        kits: []
      },
      pagination: { rowsPerPage: 20 },
      formModal: false,
      isEditting: true,
      deleteDialog: false,
      showPassword: false,
      deleteConfirm: false,
      buttonState: false,
      deleteId: 0,
      object: {},
      objectErrors: {},
      loraSensor: undefined,
      loraSensorId: undefined,
      search: '',
      objectReference: '',
      open: [],
      configurationModal: false,
      // Filter things
      filtered: false,
      viewType: 'kits',
      filteredKits: [],
      bag: undefined,
      node: undefined,
      companyLocal: undefined,
      headers: [
        {
          text: this.$i18n.t(`${objectName.multiple}.reference`),
          align: 'left',
          sortable: true,
          value: 'reference'
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.bagName`),
          align: 'left',
          sortable: true,
          value: 'bagName'
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.loraNodeName`),
          align: 'left',
          sortable: true,
          value: 'loraNodeName'
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.companyLocalName`),
          align: 'left',
          sortable: true,
          value: 'companyLocalName'
        },
        // {
        //   text: this.$i18n.t(`${objectName.multiple}.loraSensors`),
        //   align: 'left',
        //   sortable: true,
        //   value: 'loraSensors'
        // },
        // {
        //   text: this.$i18n.t(`${objectName.multiple}.assigned`),
        //   align: 'left',
        //   sortable: true,
        //   value: 'assigned'
        // },
        // {
        //   text: this.$i18n.t(`${objectName.multiple}.assignedType`),
        //   align: 'left',
        //   sortable: true,
        //   value: 'assignedType'
        // },
        // {
        //   text: this.$i18n.t(`${objectName.multiple}.companyCentralId`),
        //   align: 'left',
        //   sortable: true,
        //   value: 'companyCentralId'
        // },
        // {
        //   text: this.$i18n.t(`${objectName.multiple}.integer`),
        //   align: 'left',
        //   sortable: true,
        //   value: 'integer'
        // },
        {
          text: this.$i18n.t('helpers.actions'),
          align: 'right',
          sortable: false,
          value: 'actions'
        }
      ]
    }
  },

  computed: {
    ...mapState(['user', 'width', 'dark', 'language', 'loraSensors', 'companyLocals', 'loraNodes']),
    ...mapState({ data: objectName.multiple }),
    ...mapGetters(['inputColor', 'bindedSensors']),
    nodes () {
      return this.$store.state.loraNodes.map(node => {
        return {
          name: node.name,
          value: node.name
        }
      })
    },
    bags () {
      return this.$store.state.bags.map(bag => {
        return {
          name: bag.reference,
          value: bag.reference
        }
      })
    },
    isMobile () {
      return this.width.full < 600
    },
    renderHeaders () {
    return [
        ...this.userConfiguration,
        {
          text: this.$i18n.t('helpers.actions'),
          align: 'right',
          sortable: false,
          value: 'actions'
        }
      ]
    },
    userConfiguration () {
      const basicConfiguration = this.user.viewsConfiguration['kits'] || this.defaultHeaders
      // For in basicConfiguration to get the value and get the format from configurationHeaders
      const configuration = []
      for (var i in basicConfiguration) {
        const index = this.configurationHeaders.findIndex((item) => { return item.value === basicConfiguration[i].value && item.text === basicConfiguration[i].text })
        if (index !== -1) {
          configuration.push(Object.assign({}, this.configurationHeaders[index]))
        }
      }
      return configuration
    },
    configurationHeaders () {
      return [
        {
          text: this.$i18n.t(`${objectName.multiple}.reference`),
          align: 'left',
          sortable: true,
          value: 'reference',
          format: (value) => {
            return value
          }
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.bagName`),
          align: 'left',
          sortable: true,
          value: 'bagName',
          format: (value) => {
            return value
          }
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.loraNodeName`),
          align: 'left',
          sortable: true,
          value: 'loraNodeName',
          format: (value) => {
            return value
          }
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.companyLocalName`),
          align: 'left',
          sortable: true,
          value: 'companyLocalName',
          format: (value) => {
            return value
          }
        }
      ]
    },
    defaultHeaders () {
      return [
        {
          text: this.$i18n.t(`${objectName.multiple}.reference`),
          align: 'left',
          sortable: true,
          value: 'reference',
          format: (value) => {
            return value
          }
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.bagName`),
          align: 'left',
          sortable: true,
          value: 'bagName',
          format: (value) => {
            return value
          }
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.loraNodeName`),
          align: 'left',
          sortable: true,
          value: 'loraNodeName',
          format: (value) => {
            return value
          }
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.companyLocalName`),
          align: 'left',
          sortable: true,
          value: 'companyLocalName',
          format: (value) => {
            return value
          }
        }
      ]
    },
    defaultObject () {
      return {
        id: undefined,
        reference: '',
        loraSensors: [],
        companyLocalId: undefined
        
      }
    },

    styleWidth () {
      if (this.isFullScreen) {
        return 'max-height: 120px; display: flex; flex-direction: column-reverse; justify-content: space-between; align-items: flex-end;'
      } else {
        return 'max-height: 100px; display: flex; flex-direction: row; justify-content: space-between; align-items: flex-end;'
      }
    },

    isFullScreen () {
      return this.width.full < 960
    },

    title () {
      if (!this.isEditting) {
        return this.$i18n.t(`${objectName.multiple}.titleShow`, { name: this.object.name })
      } else {
        if (this.object.id === undefined) {
          return this.$i18n.t(`${objectName.multiple}.titleNew`)
        } else {
          return this.$i18n.t(`${objectName.multiple}.titleEdit`, { name: this.object.name })
        }
      }
    },
    assignationTittle () {
      return this.$i18n.t(`${objectName.multiple}.titleAssignation`)
    },
    assignationLoraNodeTittle () {
      return this.$i18n.t(`${objectName.multiple}.titleLoraNodeAssignation`)
    },
    freeKits () {
      return this.data.filter((kit) => !kit.assigned)
    }
  },

  created () {
    this.object = Object.assign({}, this.defaultObject)
    window.addEventListener('scroll', this.handleScroll)
  },

  destroyed () {
    window.removeEventListener('scroll', this.handleScroll)
  },

  mounted () {
    const card = document.querySelector('#kitsCard')
    const search = document.getElementById('kitsSearchFixed')

    var observer = new ResizeObserver(function (entries) {
      entries.forEach(function (entry) {
        search.style.width = `${card.offsetWidth}px`
      })
    })

    observer.observe(card)
  },

  methods: {
    cleanFilters () {
      this.bag = undefined
      this.node = undefined
      this.companyLocal = undefined
      this.filtered = false
      this.viewType = 'kits'
    },
    filterData () {
      // Validate at least one filter is not null
      if (this.bag === undefined && this.node === undefined && this.companyLocal === undefined) {
        this.$store.commit('toggleAlert', {
          type: 'error',
          message: this.$i18n.t('helpers.noFilterSelected')
        })
        return
      }
      this.filteredKits = []
      for (const kit of this.data) {
        let valid = true
        if (this.bag !== undefined) {
          valid = kit.bagName === this.bag
        }
        if (this.node !== undefined) {
          valid = kit.loraNodeName === this.node
        }
        if (this.companyLocal !== undefined) {
          valid = kit.companyLocalName === this.companyLocal
        }
        if (valid) {
          this.filteredKits.push(kit)
        }
      }
      this.filtered = true
      this.viewType = 'kits'
    },
    changeType (type) {
      this.viewType = type
    },
    showConfiguration () {
      this.configurationModal = true
    },
    discardLoraNodeChanges () {
      this.loraNodeObject = {
        loraNodeId: undefined,
        kits: []
      }
      this.loraNodeAssignationModal = false
    },
    saveLoraNodeChanges () {
      this.buttonState = true
      const assignObject = {
        loraNodeId: this.loraNodeObject.loraNodeId,
        kitIds: this.loraNodeObject.kits
      }
      this.$apollo.mutate({
        mutation: assignLora,
        variables: {
          ...assignObject,
          apiToken: this.user.apiToken,
          language: this.language.toUpperCase()
        }
      }).then(response => {
        const { status, } = response.data.assignKitsToLoraNode

        if (status === 'OK') {
          this.$store.dispatch(`get${objectName.object.multiple}`)
          this.$store.dispatch('getLoraSensors')
          this.$store.commit('toggleAlert', {
            type: 'success',
            message: this.$i18n.t(`${objectName.multiple}.success.assign`)
          })
          this.loraNodeAssignationModal = false
        } else {
          this.objectErrors = errors
          this.$store.commit('toggleAlert', {
            type: 'warning',
            message: this.$i18n.t('errors.invalidFields')
          })
        }
      }).catch(error => {
        console.log(error)
        this.$store.commit('toggleAlert', {
          type: 'error',
          message: this.$i18n.t('errors.internalError')
        })
      }).finally(() => {
        this.buttonState = false
      })
      
    },
    discardMultiplesChanges () {
      this.multiple = {
        companyLocalId: undefined,
        kits: []
      }
      this.multipleAssignationModal = false
      
    },
    saveMultipleChanges () {
      this.buttonState = true
      const assignObject = {
        companyLocalId: this.multiple.companyLocalId,
        kitIds: this.multiple.kits
      }
      this.$apollo.mutate({
        mutation: assign,
        variables: {
          ...assignObject,
          apiToken: this.user.apiToken,
          language: this.language.toUpperCase()
        }
      }).then(response => {
        const { status, } = response.data.assignKits

        if (status === 'OK') {
          this.$store.dispatch(`get${objectName.object.multiple}`)
          this.$store.dispatch('getLoraSensors')
          this.$store.commit('toggleAlert', {
            type: 'success',
            message: this.$i18n.t(`${objectName.multiple}.success.assign`)
          })
          this.multipleAssignationModal = false
        } else {
          this.objectErrors = errors
          this.$store.commit('toggleAlert', {
            type: 'warning',
            message: this.$i18n.t('errors.invalidFields')
          })
        }
      }).catch(error => {
        console.log(error)
        this.$store.commit('toggleAlert', {
          type: 'error',
          message: this.$i18n.t('errors.internalError')
        })
      }).finally(() => {
        this.buttonState = false
      })
      
    },
    handleScroll (event) {
      const elem = document.getElementById('kitsCard')
      const search = document.getElementById('kitsSearchFixed').childNodes[0]
      const searchCard = document.getElementById('kitsSearch')

      var posTopView = window.scrollY
      var elemTop = elem.offsetTop + 126

      if (elemTop > posTopView) {
        search.classList.remove('visible')
        if (this.keyFocus) {
          searchCard.focus()
          this.keyFocus = false
        }
      } else {
        search.classList.add('visible')
        this.keyFocus = true
      }
    },

    customFilter (item, queryText, itemText) {
      return this.availableSensors().filter((sensor) => sensor.deviceEui.toLowerCase().includes(queryText.toLowerCase()))
    },

    availableSensors () {
      let ids = []
      for (var i = this.data.length - 1; i >= 0; i--) {
        const array = this.data[i].loraSensors.map((sensor) => sensor.id)
        ids = ids.concat(array)
      }
      const actualArray = this.object.loraSensors.map((sensor) => sensor.id)

      ids = ids.concat(actualArray)

      return this.loraSensors.filter((sensor) => !ids.includes(sensor.id))
    },
    deleteSensor (item) {
      const i = this.object.loraSensors.indexOf(item)
      this.object.loraSensors.splice(i, 1)
    },
    addSensor () {
      if (this.loraSensorId == undefined) {
        return 
      }

      this.loraSensor = this.availableSensors().find((sensor) => sensor.id == this.loraSensorId)
      this.object.loraSensors.push(
        {
          id: this.loraSensor.id,
          name: this.loraSensor.deviceEui,
          type: 'loraSensor',
          identifier: '',
          children: this.bindedSensors.filter((sensor) => sensor.loraSensorId === this.loraSensor.id).map((sensor) => {
            return {
              name: sensor.sensorId,
              id: sensor.id,
              identifier: '',
              type: 'bluetoothSensor'
            }
          })
        }
      )
      this.loraSensorId = undefined
    },
  
    parseErrors (array) {
      if (array === undefined) {
        return []
      }
      return array.map((currentValue, index, array) => {
        return currentValue.charAt(0).toUpperCase() + currentValue.substr(1)
      }).join(', ')
    },

    saveChanges () {
      this.graphQLWrapper(`edit${objectName.object.single}`)
    },

    async editItem (id) {
      if (this.object.id === undefined) {
        await this.showItem(id)
      }
      
      this.isEditting = true
    },

    async showItem (id) {
      await this.$apollo.query({
        query: object,
        variables: {
          id,
          apiToken: this.user.apiToken,
          language: this.language.toUpperCase()
        },
        fetchPolicy: 'no-cache'
      }).then(response => {
        const result = response.data[objectName.single]
        
        if (result.status) {
          this.object = {
            ...result[objectName.single]
          }
          this.formModal = true
          this.isEditting = false
        } else {
          if (errors.limit === undefined) {
            this.$store.commit('toggleAlert', {
              type: 'error',
              message: (errors.id === undefined ? errors.apiToken : errors.id)
            })
          } else {
            this.$store.commit('toggleAlert', {
              type: 'error',
              message: errors.limit
            })
          }
        }
      }).catch(error => {
        this.$store.commit('toggleAlert', {
          type: 'error',
          message: this.$i18n.t('errors.internalError')
        })
      })
    },

    discardChanges () {
      this.objectErrors = {}
      this.object = {}
      this.object = Object.assign({}, this.defaultObject)
      this.formModal = false
      this.isEditting = false
      this.loraSensorId = undefined
      this.loraSensor = undefined
    },

    newItem () {
      this.objectErrors = {}
      this.object = {}
      this.object = Object.assign({}, this.defaultObject)
      this.formModal = true
      this.isEditting = true
      this.loraSensorId = undefined
      this.loraSensor = undefined
    },

    createItem () {
      this.graphQLWrapper(`add${objectName.object.single}`)
      this.loraSensorId = undefined
      this.loraSensor = undefined
    },

    graphQLWrapper (mutationName) {
      this.buttonState = true
      this.objectErrors = {}
      const variables = {
        object: this.object,
        apiToken: this.user.apiToken,
        language: this.language.toUpperCase()
      }

      delete variables.object.__typename

      let mutation = add

      if (mutationName.includes('edit')) {
        mutation = edit
      }

      this.$apollo.mutate({
        mutation,
        variables
      }).then(response => {
        const { status, errors, result } = response.data[mutationName]

        if (status === 'OK') {
          this.loraSensorId = undefined
          this.loraSensor = undefined
          this.object = {
            ...result
          }
          this.objectErrors = {}
          this.isEditting = false
          this.$store.dispatch(`get${objectName.object.multiple}`)
          this.$store.dispatch('getLoraSensors')
          this.$store.dispatch('getBtSensors')

          this.$store.commit('toggleAlert', {
            type: 'success',
            message: this.$i18n.t(`${objectName.multiple}.success.${(mutationName.includes('edit') ? 'updated' : 'created')}`, { name: result.name })
          })
        } else {
          this.objectErrors = errors
          this.$store.commit('toggleAlert', {
            type: 'warning',
            message: this.$i18n.t('errors.invalidFields')
          })
        }
      }).catch(error => {
        this.$store.commit('toggleAlert', {
          type: 'error',
          message: this.$i18n.t('errors.internalError')
        })
      }).finally(() => {
        this.buttonState = false
      })
    },

    async deleteItem (id, name) {
      this.deleteId = id
      this.deleteDialog = true
      this.objectReference = name
    },

    async deleteConfirmation () {
      await this.$store.dispatch('deleteItem', {
        mutation: {
          graphql: `delete${objectName.object.single}`,
          vuex: `get${objectName.object.multiple}`
        },
        object: objectName.multiple,
        variables: {
          id: this.deleteId,
          apiToken: this.user.apiToken,
          language: this.language
        }
      })

      this.deleteDialog = false
      this.deleteId = 0
      this.deleteConfirm = false
    },
    download () {
      console.log('download')
      // Go to the url to download the file with the api token as a parameter and open it in a new tab
      const currentUrl = window.location.href
      const url = `${window.location.href}/api/kits?api_token=${this.user.apiToken}`.replaceAll('/Platform/Kits', '')
      console.log(url)
      window.open(url, '_blank')
    }
  },
}
</script> 