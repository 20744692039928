<template>
  <v-btn
      class="ml-0 mr-0"
      @click="updateAll"
  >
    <v-icon>
        mdi-refresh
    </v-icon>
    {{ formatDuration() }}
  </v-btn>
</template>
  
<script>
import moment from 'moment'

export default {
  name: 'RefreshButton',
  data: () => ({
    interval: null
  }),
  computed: {
    intervalDate () {
      return this.$store.state.intervalDate
    }
  },
  methods: {
    // Method to format the duration between intervalDate and now
    formatDuration () {
      if (this.intervalDate === null || this.intervalDate === undefined) {
        return '00:00:00'
      } else {
        // Get difference between intervalDate and now
        const diff = this.intervalDate - moment()
        // Get duration in seconds
        const diffSeconds = Math.abs(diff / 1000)
        // Get minutes
        const minutes = Math.floor(diffSeconds / 60)
        // Get seconds
        const seconds = Math.floor(diffSeconds % 60)
        // Format duration
        return `${this.padWithLeadingZeros(minutes, 2)}:${this.padWithLeadingZeros(seconds, 2)}`
      }
    },
    padWithLeadingZeros(num, totalLength) {
      return String(num).padStart(totalLength, '0');
    },
    async updateAll () {
      const date = new Date()
      date.setMinutes(date.getMinutes() + 5)
      this.$store.commit('setIntervalDate', date)
      if (process.env.NODE_ENV !== 'development') {
        this.$store.commit('setLoading', true)
        await this.$store.dispatch('getLoraNodes')
        await this.$store.dispatch('getUsersSelect')
        await this.$store.dispatch('getLoraSensors')
        await this.$store.dispatch('getBtSensors')
        await this.$store.dispatch('getCycles')
        await this.$store.dispatch('getFieldBatches')
        await this.$store.dispatch('getProducts')
        await this.$store.dispatch('getKits')
        await this.$store.dispatch('getNotificationGroups')
        await this.$store.dispatch('getCompanyLocalLinkRequests')
        await this.$store.dispatch('getBags')
        await this.$store.dispatch('getCategoryOrders')
        await this.$store.dispatch('getMessages')
        // if (this.$store.state.user.parentType !== 'Company::Depository') {
        //   this.$store.dispatch('getMessagesReport')
        // }
        await this.$store.dispatch('getCategoryAlerts')
        await this.$store.dispatch('getPriorities')
        await this.$store.dispatch('getIncidents')
        this.$store.commit('setLoading', false)
      }
    }
  },
  // Method to refresh the component every minute
  mounted () {
    this.interval = setInterval(() => {
      this.$forceUpdate()
    }, 1000)
  },
  // Clear the interval when the component is destroyed
  beforeDestroy () {
    clearInterval(this.interval)
  }
}
</script>
