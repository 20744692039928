<template>
    <v-dialog
      id="loadKitsDialog"
      v-model="modal"
      scrollable
      persistent
      :fullscreen="isFullScreen"
      :overlay="false"
      transition="dialog-transition"
      :dark="dark"
    >
      <v-card
      elevation="12"
      >
        <v-toolbar flat color="primary">
          <v-toolbar-title class="yellow-color-text">
          {{ $t('pages.platformLoadKits') }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-layout row wrap>
            <v-flex xs12 md10 pa-4>
              <v-text-field
                v-model="fileName"
                :label="$t('kits.file')"
                disabled
              />
            </v-flex>
            <v-flex xs12 md2 pa-4 style="display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;">
              <v-btn color="success" @click="$refs.inputUpload.click()">{{ $t('kits.upload') }}</v-btn>
              <input v-show="false" id="file" ref="inputUpload" type="file" @change="fileHandler">
            </v-flex>
          </v-layout>
        </v-card-text>
        
        <v-card-actions>
          <v-btn color="primary" text @click="modal = false">Cerrar</v-btn>
          <v-spacer />
          <a-button v-if="file" :state="buttonState" color="primary" @click="send">{{ 'Enviar' }}</a-button>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  import AButton from '@/components/Button.vue'
  import loadKits from '@/graphql/mutations/kits/loadKit.gql'
  
  import { mapState, mapGetters } from 'vuex'
  import readXlsxFile from 'read-excel-file'
  
  const objectName = {
    single: 'message',
    multiple: 'messages',
    object: {
      single: 'Message',
      multiple: 'Messages'
    }
  }
  export default {
    name: 'LoadKitsDialog',
    props: {
      dialog: {
        type: Boolean,
        default: false
      }
    },
    components: {
      AButton
    },
    data () {
      return {
        fileType: 'onlyLora',
        objectName,
        search: '',
        fileName: '',
        kits: [],
        options: [
          {
            value: 'onlyLora',
            text: this.$i18n.t('kits.fileType.onlyLora')
          },
          {
            value: 'mix',
            text: this.$i18n.t('kits.fileType.mix')
          }
        ],
        file: false,
        buttonState: false
      }
    },
  
    computed: {
      ...mapState(['loraNodes', 'fieldBatches', 'bags', 'user', 'width', 'dark', 'language']),
      ...mapGetters(['inputColor']),
      isFullScreen () {
        return this.width.full < 990
      },
      modal: {
        get () {
          return this.dialog
        },
        set (value) {
          this.$emit('update-dialog', value)
        }
      }
    },
  
    methods: {
      fileHandler () {
        this.getFileName()
        this.processFile()
      },
      getFileName () {
        var fullPath = document.getElementById('file').value
        if (fullPath) {
          var startIndex = (fullPath.indexOf('\\') >= 0 ? fullPath.lastIndexOf('\\') : fullPath.lastIndexOf('/'))
          var filename = fullPath.substring(startIndex)
          if (filename.indexOf('\\') === 0 || filename.indexOf('/') === 0) {
            filename = filename.substring(1)
          }
          this.fileName = filename
        }
      },
      processFile () {
        this.kits = []
        const input = document.getElementById('file')
        readXlsxFile(input.files[0]).then((rows) => {
          for (const i in rows) {
            if (i === '0') {
              if (!this.validateHeaders(rows[i])) {
                alert('El formato ingresado no es el correcto')
                this.fileName = ''
                this.readFile = false
                return
              }
            } else {
              let kit = {
                kit: rows[i][0],
                location: rows[i][1],
                loraa: rows[i][2],
                lorab: rows[i][3],
                lorac: rows[i][4],
                lorad: rows[i][5],
                lorae: rows[i][6]
              }
              if (this.fileType === 'mix') {
                kit = {
                  kit: rows[i][0],
                  location: rows[i][1],
                  loraa: rows[i][2],
                  btb: rows[i][3],
                  lorac: rows[i][4],
                  btd: rows[i][5],
                  lorae: rows[i][6]
                }
              }
              this.kits.push(kit)
              if (this.validateRows(rows[i]) !== undefined) {
                alert(`la fila ${parseInt(i) + 1} Tiene un campo vacio`)
                this.fileName = ''
                this.readFile = false
                return ''
              }
            }
            this.file = true
          }
          // `rows` is an array of rows
          // each row being an array of cells.
        })
      },
  
      validateHeaders (row) {
        let headers = [
          this.$i18n.t('loadKits.reference'),
          this.$i18n.t('loadKits.location'),
          this.$i18n.t('loadKits.loraSensorA'),
          this.$i18n.t('loadKits.loraSensorB'),
          this.$i18n.t('loadKits.loraSensorC'),
          this.$i18n.t('loadKits.loraSensorD'),
          this.$i18n.t('loadKits.loraSensorE')
        ]
        if (this.fileType === 'mix') {
          headers = [
            this.$i18n.t('loadKits.reference'),
            this.$i18n.t('loadKits.location'),
            this.$i18n.t('loadKits.loraSensorA'),
            this.$i18n.t('loadKits.loraBTB'),
            this.$i18n.t('loadKits.loraSensorC'),
            this.$i18n.t('loadKits.loraBTD'),
            this.$i18n.t('loadKits.loraSensorE')
          ]
        }
        for (const i in row) {
          (!headers.includes(row[i]))
          if (!headers.includes(row[i])) {
            return false
          }
        }
        
        return true
      },
  
      validateRows (row) {
        return row.find((val) => val === undefined || val === null || val === '')
      },
  
      send () {
        this.buttonState = true
        const variables = {
          objects: this.kits,
          apiToken: this.user.apiToken,
          fileType: this.fileType,
          language: this.language.toUpperCase()
        }
  
        const mutation = loadKits
        const mutationName = 'loadKit'
      
        this.$apollo.mutate({
          mutation,
          variables
        }).then(response => {
          const { status, result } = response.data[mutationName]
  
          if (status === 'OK') {
            window.open('/generated/' + result, '_blank')
            this.$store.dispatch('getKits')
            this.$store.dispatch('getLoraSensors')
            this.$store.dispatch('getBtSensors')
            this.$store.commit('toggleAlert', {
              type: 'success',
              message: this.$i18n.t(`La carga ha sido completada con exito`)
            })
          } else {
            window.open('/generated/' + result, '_blank')
            this.$store.commit('toggleAlert', {
              type: 'warning',
              message: this.$i18n.t('errors.invalidFields')
            })
          }
        }).catch(error => {
          this.$store.commit('toggleAlert', {
            type: 'error',
            message: this.$i18n.t('errors.internalError')
          })
        }).finally(() => {
          this.buttonState = false
        })
      }
    }
  }
  </script>