<template>
  <v-app id="app">
    <toast />
    <transition name="component-fade" mode="out-in">
      <router-view />
    </transition>
    <loading />

    <v-dialog
      :value="false" 
      persistent
      :max-width="width.dialog"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title class="headline">
          {{ $t('helpers.versionDismatch') }}
        </v-card-title>

        <v-card-text class="text-xs-justify">
          {{ $t('helpers.versionDismatchContent') }}
        </v-card-text>

        <v-card-actions class="justify-end">
          <v-btn color="primary" @click="refresh">{{ $t('helpers.refresh') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-bottom-sheet persistent inset v-model="cookiesAccepted">
      <v-sheet>
        <div class="pa-3" :style="`color: #${dark ? 'fff' : '000'} !important`" v-html="$t('helpers.cookiesAlert')"></div>

        <div class="pa-3 justify-end">
          <v-btn color="success" @click="accept">{{ $t('helpers.acceptCookies') }}</v-btn>
        </div>
      </v-sheet>
    </v-bottom-sheet>
  </v-app>
</template>

<script>
import Toast from '@/components/Toast.vue'
import Loading from '@/components/Loading.vue'
import { mapState } from 'vuex'

export default {
  components: { Toast, Loading },

  data () {
    return {
      cookiesAccepted: false
    }
  },

  computed: {
    ...mapState(['user', 'serverVersion', 'localVersion', 'dark', 'width'])
  },

  watch: {
    $route (newVal, oldVal) {
      document.title = `${this.$i18n.t(`pages.${newVal.name}`)} | ${this.$i18n.t('app.title')}`
    }
  },
  
  mounted () {
    this.cookiesAccepted = this.$cookies.get('cookiesAccepted') === undefined

    window.addEventListener('load', () => {
      setTimeout(this.validateCredentials(), 5000)
    })

    window.addEventListener('resize', () => {
      this.$store.commit('updateMaxWidth', window.innerWidth)
    })
  },

  methods: {
    accept () {
      this.$cookies.set('cookiesAccepted', true)
      this.cookiesAccepted = false
    },

    refresh () {
      window.location.reload(true)
    },

    validateCredentials () {
      if (this.$cookies.get('apiToken') === null || this.$cookies.get('apiToken') === undefined) {
        this.$router.push('/Platform/Sessions/New')
        this.$store.commit('setLoading', false)
      } else {
        this.$store.dispatch('getUser', { i18n: this.$i18n, router: this.$router, lastUrl: this.$route.fullPath })
      }
    }
  }
}
</script>

<style scoped>
.parallax-container {
    overflow: hidden;
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
  }
</style>
