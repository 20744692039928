// Main imports
import Vue from 'vue'
import Vuex from 'vuex'

// Vuex files
import { state, mutations, getters, actions, modules } from '@/state'

// Create component
Vue.use(Vuex)

export default new Vuex.Store({
  state,
  mutations,
  modules,
  getters,
  actions
})