<template>
  <v-app-bar v-if="width.full < 960" flat color="transparent">
    <v-app-bar-nav-icon
      v-if="width.full < 960"
      @click.stop="setSidebar(true)"
    ></v-app-bar-nav-icon>
    <!-- Row with the title of the current page -->
    <v-toolbar-title v-if="isMobile()" style="display: flex; justify-content: space-between; width: 100%;">{{ currentTitle() }} <refresh-button></refresh-button></v-toolbar-title>
  </v-app-bar>
</template>

<script>
import RefreshButton from '@/components/utils/RefreshButton.vue'
import { mapState, mapMutations } from 'vuex'
export default {
  components: {
    RefreshButton
  },
  computed: mapState(['sidebar', 'width', 'dark', 'localVersion']),

  methods: {
    ...mapMutations(['setDark', 'setSidebar']),
    currentTitle() {
      return this.$i18n.t(`pages.${this.$route.name}`)
    },
    isMobile () {
      return this.width.full < 600
    },
  }
}
</script>

<style lang="scss">
  .v-toolbar:not(.v-toolbar--fixed) .v-toolbar__content{
    margin-left: 0;
  }
</style>