<template>
  <v-navigation-drawer
    v-model="sidebarState"
    :permanent="width.full >= 960"
    :temporary="width.full < 960"
    :expand-on-hover="mini && width.full >= 960"
    mobile-break-point="960"
    dark
    overflow
    app
    class="elevation-5"
  >
    <v-list
      dense
      nav
      class="py-0"
    >
      <!--  Iconos y nombre de la app -->
      <v-list-item dense one-line>
        <v-list-item-avatar tile>
          <img :src="$assets.logo">
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title style="display: flex; align-items: center; justify-content: space-between;">
            <img style="width: 80%; height:auto" :src="$assets.logo2">

            <v-tooltip right>
              <template v-slot:activator="{ on }">
                <v-btn v-if="width.full >= 960" v-on="on" small icon @click="setMini(!mini)">
                  <v-icon small>mdi-resize</v-icon>
                </v-btn>
              </template>
              <span class="white--text">{{ $t(`app.tools.compact.${mini}`) }}</span>
            </v-tooltip>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <!--  /Iconos y nombre de la app -->

      <v-divider class="mb-1" />
      <!--  Usuario y foto -->
      <v-list-group v-model="userMenu" color="transparent" style="margin: 0px;">
        <template v-slot:activator>
          <v-list-item class="pa-0 ma-0">
            <v-list-item-avatar class="">
              <img
                style="border-radius: 5px;"
                :src="$assets.avatar"
              >
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>
                <h5 class="pa-0 ma-0">
                  {{ lengthCutter(user.name, 10) }}
                </h5>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>

        <v-list-item ripple link>
          <v-list-item-icon>
            <v-icon>mdi-lock</v-icon>
          </v-list-item-icon>

          <v-list-item-content
            @click="logout({ router: $router, i18n: $i18n })"
          >
            <v-list-item-title>
              {{ $t('login.logout') }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
    </v-list>

    <v-list
      dense
      nav
      class="py-0"
    >
      <v-divider class="mb-1" />

      <v-list-item
        v-for="link in links"
        :key="link.name"
        :to="link.to"
        dense
        link
        active-class="active-tab"
      >
        <v-list-item-icon class="text-md-center">
          <v-icon>{{ link.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ lengthCutter($t(`pages.${link.name}`)) }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'

export default {
  data () {
    return {
      userMenu: false
    }
  },
  computed: {
    ...mapState(['width', 'user', 'sidebar', 'mini']),
    ...mapGetters(['inputColor']),

    sidebarState: {
      get () {
        return this.sidebar
      },
      set (value) {
        this.setSidebar(value)
      }
    },

    favicon () {
      if (this.$assets.favicon.normal === undefined) {
        return this.$assets.favicon
      } else {
        return this.$assets.favicon.normal
      }
    },

    appName () {
      return this.$i18n.t('app.title')
    },

    links () {
      let links = [
        {
          to: '/CMS/Home',
          icon: 'mdi-view-dashboard',
          name: 'cmsHome'
        }
      ]

      if (this.user.object !== 'User') {
        links.push({
          to: '/CMS/Users',
          icon: 'mdi-worker',
          name: 'cmsUsers'
        })
        
        if (this.user.object === 'Administrator') {
          links.push({
            to: '/CMS/Categories/Alerts',
            icon: 'mdi-book',
            name: 'cmsCategoryAlerts'
          })
          links.push({
            to: '/CMS/Priorities',
            icon: 'mdi-priority-high',
            name: 'cmsPriorities'
          })
          links.push({
            to: '/CMS/PresetRuleNotifications',
            icon: 'mdi-ruler',
            name: 'cmsPresetRuleNotifications'
          })
        }


        if (['Central', 'Local', 'Promotor'].includes(this.user.object)) {
          links.push({
            to: '/CMS/Installers',
            icon: 'mdi-worker',
            name: 'cmsInstallers'
          })
        }

      } else {
        switch (this.user.parentType) {
          case 'Administrator':
            links = [
              ...links,
              {
                to: '/CMS/Companies/Centrals',
                icon: 'mdi-account-tie',
                name: 'cmsCompanyCentrals'
              },
              {
                to: '/CMS/Companies/Promotors',
                icon: 'mdi-briefcase-account',
                name: 'cmsCompanyPromotors'
              },
              {
                to: '/CMS/Companies/Locals',
                icon: 'mdi-account-search-outline',
                name: 'cmsCompanyLocals'
              },
              {
                to: '/CMS/Companies/Depositories',
                icon: 'mdi-account',
                name: 'cmsCompanyDepositories'
              },
            ]
            break

          case 'Company::Central':
            links = [
              ...links,
              {
                to: '/CMS/Companies/Promotors',
                icon: 'mdi-briefcase-account',
                name: 'cmsCompanyPromotors'
              },
              {
                to: '/CMS/Companies/Locals',
                icon: 'mdi-account-search-outline',
                name: 'cmsCompanyLocals'
              },
              {
                to: '/CMS/Companies/Depositories',
                icon: 'mdi-account',
                name: 'cmsCompanyDepositories'
              },
              {
                to: '/CMS/Categories/Incidents',
                icon: 'mdi-format-list-bulleted',
                name: 'cmsCategoryIncidents'
              },
              {
                to: '/CMS/Categories/Products',
                icon: 'mdi-package',
                name: 'cmsProducts'
              },
              {
                to: '/CMS/Categories/Analyses',
                icon: 'mdi-file',
                name: 'cmsAnalyses'
              },
              {
                to: '/CMS/Cycles',
                icon: 'mdi-recycle',
                name: 'cmsCycles'
              }
            ]
            if (this.user.usersAccess) {
              links.push({
                to: '/CMS/Users',
                icon: 'mdi-account',
                name: 'cmsUsers'
              })
            }
            break

          case 'Company::Promotor':
            links = [
              ...links,
              {
                to: '/CMS/Companies/Locals',
                icon: 'mdi-account-search-outline',
                name: 'cmsCompanyLocals'
              },
              {
                to: '/CMS/Companies/Depositories',
                icon: 'mdi-account',
                name: 'cmsCompanyDepositories'
              },
              {
                to: '/CMS/Categories/Incidents',
                icon: 'mdi-format-list-bulleted',
                name: 'cmsCategoryIncidents'
              },
              {
                to: '/CMS/Categories/Analyses',
                icon: 'mdi-file',
                name: 'cmsAnalyses'
              }
            ]
            break

          case 'Company::Local':
            links = [
              ...links,
              {
                to: '/CMS/Companies/Depositories',
                icon: 'mdi-account',
                name: 'cmsCompanyDepositories'
              },
              {
                to: '/CMS/Categories/Analyses',
                icon: 'mdi-file',
                name: 'cmsAnalyses'
              }
            ]
            break

          default:
            break
        }
      }

      return links
      // return [
      //   {
      //     to: 'Home',
      //     icon: 'mdi-home',
      //     name: 'cmsHome'
      //   },
      //   {
      //     to: 'Users',
      //     icon: 'mdi-account-multiple',
      //     name: 'cmsUsers'
      //   }
      // ]
    }
  },

  watch: {
    mini (newVal, oldVal) {
      
    }
  },

  methods: {
    ...mapActions({
      logout: 'signOut'
    }),
    ...mapMutations(['setSidebar', 'setMini']),

    lengthCutter (string, size = 15) {
      if (string === undefined) {
        return ''
      }

      if (string.length > size) {
        return `${string.substring(0, size)}...`
      } else {
        return string
      }
    },
  }
}
</script>

<style lang="scss">
  .v-list-item__icon.v-list-group__header__append-icon {
    padding: 0;
    margin-top: 18px !important;
    color: #fff;
  }

  .active-tab {
    color: #fbc206 !important;
    background-color: var(--v-anchor-base);
  }

  .theme--dark.v-list-item--active:before, .theme--dark.v-list-item--active:hover:before, .theme--dark.v-list-item:focus:before {
    opacity: 0 !important;
  }

  .v-list-item__avatar:first-child {
    margin-right: 20px;
  }

  .v-list--nav.v-list--dense .v-list-item:not(:last-child):not(:only-child), .v-list--nav .v-list-item--dense:not(:last-child):not(:only-child), .v-list--rounded.v-list--dense .v-list-item:not(:last-child):not(:only-child), .v-list--rounded .v-list-item--dense:not(:last-child):not(:only-child) {
    margin-bottom: 0px;
    margin-top: 0px;
}
</style>
