<template>
  <v-snackbar
    v-model="snackbar"
    :color="color"
    :timeout="6000"
    :left="options.left"
    :right="options.right"
    :top="options.top"
    :bottom="options.bottom"
  >
    {{ text }}
    <v-btn
      icon
      @click="snackbar = false"
    >
      <v-icon>mdi-close-circle-outline</v-icon>
    </v-btn>
  </v-snackbar>
</template>

<script>
export default {
  data () {
    return {
      snackbar: false,
      color: 'primary',
      text: '',
      options: {
        left: false,
        right: true,
        top: true,
        bottom: false
      }
    }
  },

  beforeCreate () {
    this.$store.subscribe(mutation => {
      if (mutation.type === 'toggleAlert') {
        let payload = mutation.payload

        if (payload.options !== undefined) {
          this.options = payload.options
        } else {
          this.options = {
            left: false,
            right: true,
            top: true,
            bottom: false
          }
        }

        switch (payload.type) {
          case 'success':
            this.text = payload.message
            this.color = 'success'
            break
          case 'warning':
            this.text = payload.message
            this.color = 'warning'
            break
          case 'error':
            this.text = (payload.message === undefined ? this.$i18n.t('errors.internalError') : payload.message)
            this.color = 'error'
            break
          default:
            this.text = payload.message
            this.color = payload.type
            break
        }

        this.snackbar = true
      }
    })
  }
}
</script>
