<template>
  <v-speed-dial
    v-model="gotoState"
    bottom
    fixed
    direction="top"
    transition="slide-y-transition"
  >
    <template v-slot:activator>
      <v-tooltip top>
        <template v-slot:activator="{ on: dial }">
          <button class="button-decorator" v-on="dial">
            <transition name="component-fade" mode="out-in">
              <v-icon>{{ gotoState ? 'mdi-close' : 'mdi-open-in-app'}}</v-icon>
            </transition>
          </button>
        </template>

        <span class="white--text">{{ $t('goto.title') }}</span>
      </v-tooltip>
    </template>

    <template v-for="(path, index) in paths">
      <v-tooltip :key="index" right>
        <template v-slot:activator="{ on }">
          <v-btn
            v-if="!$route.path.includes(`${path.path}`)"
            fab
            dark
            small
            :color="`${path.color} darken-2`"
            :to="path.path"
            v-on="on"
          >
            <v-icon>{{ path.icon }}</v-icon>
          </v-btn>
        </template>
        
        <span class="white--text">{{ $t(`goto.${path.name}`) }}</span>
      </v-tooltip>
    </template>
  </v-speed-dial>
</template>

<script>
export default {
  data () {
    return {
      gotoState: false,
      paths: [
        { path: '/CMS', icon: 'mdi-account-alert-outline', color: 'red', name: 'cms' },
        { path: '/Platform', icon: 'mdi-store', color: 'green', name: 'platform' }
      ]
    }
  }
}
</script>

<style lang="scss">
.button-decorator {
  width: 40px;
  height: 40px !important;
  background: var(--v-primary-base);
  border-radius: 5px;

  &:focus {
    outline: none !important;
  }

  &::after {
    content: '';
    height: 5px;
    width: 100%;
    background: var(--v-primary-lighten1);
    position: absolute;
    bottom: 0;
    left: 0;
    border-radius: 5px;
  }
}
</style>