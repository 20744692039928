<template>
  <v-layout row wrap align-center justify-center fill-height style="height: calc(100vh - 100px);">
    <v-flex xs12>
      <h1 style="text-align: center;"><v-icon size="100">mdi-worker</v-icon><br>Under development</h1>
    </v-flex>
  </v-layout>
</template>

<script>
export default {}
</script>