import Layout from '@/layouts/cms/Login.vue'
import Login from '@/pages/cms/Login.vue'

export default {
  path: '/CMS/Sessions',
  component: Layout,
  children: [
    {
      path: 'New',
      name: 'cmsLogin',
      components: { default: Login }
    }
  ]
}