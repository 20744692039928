import app from './app'
import login from './login'

export default [
  {
    path: '/Platform',
    redirect: '/Platform/Sessions/New'
  },
  ...app,
  login
]