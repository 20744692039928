// Main imports
import Vue from 'vue'
import VueApollo from 'vue-apollo'

// Apollo client dependencies
import { ApolloClient } from 'apollo-client'
import { HttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'

// Create component
Vue.use(VueApollo)

const httpLink = new HttpLink({
  uri: (process.env.NODE_ENV === 'development' ? 'http://localhost:1945/graphql' : `https://${window.location.hostname}/graphql`)
})

const cache = new InMemoryCache({
  fetchPolicy: 'no-cache'
})

const apolloClient = new ApolloClient({
  link: httpLink,
  cache
})

export default new VueApollo({
  defaultClient: apolloClient
})