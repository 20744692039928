<template>
  <v-layout
    row
    wrap
    fill-height
    justify-center
    align-center
    style="height: 100vh;"
  >
    <img :src="$assets.favicon.normal" class="arrow-decorator">
    <v-flex xs11 sm9 md6>
      <v-card class="elevation-0 transparent">
        <v-card-title>
          <img :src="$assets.logo2" style="height: 20px;">
          <v-divider vertical class="mx-2" />
          <h4 class="ma-0">{{ $t(`pages.${$route.name}`) }}</h4>
        </v-card-title>

        <v-card-text class="pt-2 pb-0">
          <v-form>
            <v-layout row wrap>
              <v-flex xs12 sm6 class="pr-1 pl-1">
                <v-text-field
                  v-model="credentials.username"
                  name="authentication[username]"
                  autocomplete="off"
                  :label="$t('login.username')"
                  solo
                  light
                  :error="loginErrors.username !== undefined"
                  :error-messages="parseErrors(loginErrors.username)"
                  @keyup.enter="login"
                >
                  <template v-slot:prepend>
                    <v-icon color="secondary">mdi-account-circle-outline</v-icon>
                  </template>
                </v-text-field>
              </v-flex>

              <v-flex xs12 sm6 class="pr-1 pl-1">
                <v-text-field
                  v-model="credentials.password"
                  name="authentication[password]"
                  autocomplete="off"
                  :label="$t('login.password')"
                  :type="showPassword ? 'text' : 'password'"
                  prepend-icon=""
                  light
                  solo
                  :error="loginErrors.password !== undefined"
                  :error-messages="parseErrors(loginErrors.password)"
                  @keyup.enter="login"
                >
                  <template v-slot:prepend>
                    <v-icon color="secondary">mdi-shield-key-outline</v-icon>
                  </template>

                  <template v-slot:append-outer>
                    <v-btn text small @click="showPassword = !showPassword">
                      <v-icon color="secondary">{{ showPassword ? 'mdi-eye-off' : 'mdi-eye' }}</v-icon>
                    </v-btn>
                  </template>
                </v-text-field>
              </v-flex>
            </v-layout>
          </v-form>
        </v-card-text>

        <v-card-actions class="pt-0">
          <h4 class="pa-0 ma-0 d-flex align-center justify-center"><small style="font-size: 10px;">Innovación</small>&nbsp;<strong>GRASSI</strong></h4>
          <v-spacer />
          <a-btn depressed :state="buttonState" color="primary" @click="login">{{ $t('login.signIn') }}</a-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
    <go-to />
    <app-download />
  </v-layout>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import login from '@/graphql/mutations/authentication/login.gql'
import ABtn from '@/components/Button.vue'
import { GoTo, AppDownload } from '@/components/utils'

export default {
  components: { ABtn, GoTo, AppDownload },

  data () {
    return {
      credentials: {
        username: '',
        password: '',
      },
      showPassword: false,
      loginErrors: {}
    }
  },

  computed: {
    ...mapState(['dark', 'buttonState']),
    ...mapGetters(['inputColor']),

    favicon () {
      if (this.$assets.favicon.white === undefined) {
        return this.$assets.favicon
      } else {
        return this.$assets.favicon.white
      }
    },

    faviconStyles () {
      if (this.$assets.favicon.white === undefined) {
        return 'filter: brightness(0) invert(100%);'
      } else {
        return ''
      }
    }
  },

  methods: {
    parseErrors (error) {
      if (error === undefined) {
        return ''
      }

      return error
    },

    login () {
      this.$store.commit('setButtonState', true)
      this.loginErrors = {}

      this.$apollo.mutate({
        mutation: login,
        variables: {
          ...this.credentials,
          access: 'PLATFORM'
        },
        fetchPolicy: 'no-cache'
      }).then((response) => {
        const { status, errors, auth } = response.data.login

        if (status === 'OK') {
          this.$cookies.set('apiToken', auth.apiToken)
          this.$store.commit('setLoading', true)
          this.$store.dispatch('getUser', { i18n: this.$i18n, router: this.$router, lastUrl: '/Platform/Home' })
        } else {
          this.loginErrors = errors
        }
      }).catch((error) => {
        this.$store.commit('toggleAlert', {
          type: 'error',
          message: this.$i18n.t('errors.internalError'),
          options: {
            left: false,
            right: false,
            top: false,
            bottom: true
          }
        })
      }).finally(() => {
        this.$store.commit('setButtonState', false)
      })
    }
  }
}
</script>

<style lang="scss">
.arrow-decorator {
  width: 150px;
  height: 150px;
  position: fixed;
  top: 20px;
  right: 28%;
}
</style>