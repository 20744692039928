// Layout
import Layout from '@/layouts/cms/Panel.vue'

// Users
import Users from '@/pages/cms/Users.vue'
import Installers from '@/pages/cms/Installers.vue'

// Companies
import Centrals from '@/pages/cms/companies/Centrals.vue'
import Promotors from '@/pages/cms/companies/Promotors.vue'
import Locals from '@/pages/cms/companies/Locals.vue'
import Depositories from '@/pages/cms/companies/Depositories.vue'

// Categories
import Incidents from '@/pages/cms/categories/Incidents.vue'
import Products from '@/pages/cms/categories/Products.vue'
import Analyses from '@/pages/cms/categories/Analyses.vue'
import Cycles from '@/pages/cms/Cycles.vue'
import CategoryAlerts from '@/pages/cms/CategoryAlerts.vue'
import Priorities from '@/pages/cms/Priorities.vue'
import Home from '@/pages/cms/Home.vue'

//Preset
import PresetRuleNotifications from '@/pages/cms/PresetRuleNotifications.vue'



export default [
  {
    path: '/CMS',
    component: Layout,
    children: [
      {
        path: 'Home',
        name: 'cmsHome',
        components: { default: Home }
      },
      {
        path: 'Users',
        name: 'cmsUsers',
        components: { default: Users }
      },
      {
        path: 'Installers',
        name: 'cmsInstallers',
        components: { default: Installers }
      },
      {
        path: 'Companies/Centrals',
        name: 'cmsCompanyCentrals',
        components: { default: Centrals }
      },
      {
        path: 'Companies/Promotors',
        name: 'cmsCompanyPromotors',
        components: { default: Promotors }
      },
      {
        path: 'Companies/Locals',
        name: 'cmsCompanyLocals',
        components: { default: Locals }
      },
      {
        path: 'Companies/Depositories',
        name: 'cmsCompanyDepositories',
        components: { default: Depositories }
      },
      {
        path: 'Categories/Incidents',
        name: 'cmsCategoryIncidents',
        components: { default: Incidents }
      },
      {
        path: 'Categories/Products',
        name: 'cmsProducts',
        components: { default: Products }
      },
      {
        path: 'Categories/Analyses',
        name: 'cmsAnalyses',
        components: { default: Analyses }
      },
      {
        path: 'Cycles',
        name: 'cmsCycles',
        components: { default: Cycles }
      },
      {
        path: 'Categories/Alerts',
        name: 'cmsCategoryAlerts',
        components: { default: CategoryAlerts }
      },
      {
        path: 'PresetRuleNotifications',
        name: 'cmsPresetRuleNotifications',
        components: { default: PresetRuleNotifications }
      },
      {
        path: 'Priorities',
        name: 'cmsPriorities',
        components: { default: Priorities }
      }
    ]
  }
]
