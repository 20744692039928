<template>
    <v-btn
        icon
        class="ml-0 mr-0"
        @click="exportInformation"
    >
      <v-icon>
          mdi-download
      </v-icon>
    </v-btn>
  </template>
    
  <script>
  import moment from 'moment'
  import * as XLSX from 'xlsx'
  export default {
    name: 'ExportButton',
    data: () => ({
      interval: null
    }),
    props: {
      msg: String,
      fileName: {
        type: String,
        default: 'testName'
      },
      headers: {
        type: Array,
        default: () => []
      },
      items: {
        type: Array,
        default: () => []
      },
      search: {
        type: String,
        default: ''
      },
      specialFilter: {
        type: Function,
        default: () => true
      },
    },
    computed: {
    },
    methods: {
      async exportInformation () {
        const currentTime = moment().format('YYYY-MM-DD_HH-mm-ss')
        const data = []
        const headers = []
        this.headers.forEach((header) => {
          headers.push(header.text)
        })
        data.push(headers)
        const filteredItems = this.filterItemsBySearch()
        filteredItems.forEach((item) => {
          const row = []
          this.headers.forEach((header) => {
            row.push(header.format(item[header.value]) ? header.format(item[header.value]) : '')
          })
          data.push(row)
        })
        var wb = XLSX.utils.book_new();
        wb.SheetNames.push(this.fileName);
        wb.Sheets[this.fileName] = XLSX.utils.aoa_to_sheet(data);
        XLSX.writeFile(wb, `${this.fileName}_${currentTime}.xlsx`);
      },
      filterItemsBySearch () {
        // Validate if the search is empty
        if (this.search === '') {
          return this.items
        }
        // Filter the items
        const finalItems = []
        this.items.forEach((item) => {
          let found = false
          this.headers.forEach((header) => {  
            const value = header.format(item[header.value]) ? header.format(item[header.value]) : ''
            console.log(value)
            console.log(this.search)
            if (value.toString().toLowerCase().includes(this.search.toLowerCase())) {
              found = true
            }
          })
          if (found) {
            finalItems.push(item)
          }
        })
        return finalItems
      }
    },
    // Method to refresh the component every minute
  }
  </script>
  