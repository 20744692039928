// Main imports
import Vue from 'vue'
import Vuetify from 'vuetify'

// // Vuetify locales
import es from 'vuetify/es5/locale/es'

const colors = {
  primary: '#04a669',
  secondary: '#FCC306',
  accent: '#03be5b'
}
// Create component
Vue.use(Vuetify)

export default new Vuetify({
  lang: {
    locales: { es },
    current: 'es'
  },

  icons: {
    iconfont: 'mdi',
    values: {
      // complete: '...',
      cancel: 'mdi-close',
      // close: '...',
      // delete: '...', // delete (e.g. v-chip close)
      // clear: '...',
      // success: '...',
      // info: '...',
      // warning: '...',
      // error: '...',
      prev: 'mdi-chevron-left',
      next: 'mdi-chevron-right' 
      // checkboxOn: '...',
      // checkboxOff: '...',
      // checkboxIndeterminate: '...',
      // delimiter: '...', // for carousel
      // sort: '...',
      // expand: '...',
      // menu: '...',
      // subgroup: '...',
      // dropdown: '...',
      // radioOn: '...',
      // radioOff: '...',
      // edit: '...'
    }
  },

  theme: {
    dark: true,
    themes: {
      dark: colors,
      light: colors
    },
    
    options: {
      customProperties: true
    }
  }
})