import Layout from '@/layouts/platform/Login.vue'
import Login from '@/pages/platform/Login.vue'

export default {
  path: '/Platform/Sessions',
  component: Layout,
  children: [
    {
      path: 'New',
      name: 'platformLogin',
      components: { default: Login }
    }
  ]
}