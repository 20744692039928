<template>
  <v-container>
    <v-layout column wrap justify-center align-center fill-height>
      <h1 style="font-size: 5em !important; display: flex; flex-direction: row; align-items: center; justify-content: center;"><v-icon size="70">mdi-help-circle-outline</v-icon>404</h1>
      <h4 style="text-align: center; font-size: 4em;">{{ $t('errors.pageNotFound') }} <br> <b style="font-weight: bold; font-size: 1em;">{{ $t('errors.tryAgain') }}</b></h4>
    </v-layout>
  </v-container>
</template>

<script>
export default {}
</script>