import cms from '@/routes/cms'
import platform from '@/routes/platform'
import NotFound from '@/pages/NotFound.vue'

const routes = [
  {
    path: '/',
    redirect: '/Platform/Sessions/New'
  },
  ...cms,
  ...platform,
  {
    path: '*',
    component: NotFound
  }
]

export default routes
