<template>
  <v-dialog
      id="configurationDialog"
      v-model="modal"
      scrollable
      persistent
      :fullscreen="isFullScreen"
      :overlay="false"
      transition="dialog-transition"
      :dark="dark"
    >
      <v-card>
        <v-card-title class="headline primary elevation-3" style="text-align: center; max-height: 58px;"><h5 class="white--text ma-0">{{ title }}</h5></v-card-title>
        <v-card-text style="flex: 1;" class="pa-4">
          <v-layout row wrap pa-1 align-center justify-center>
            <!-- Selector with the possible headers that come from a prop -->
            <v-flex xs11>
              <v-select
                :items="headers"
                item-text="text"
                item-value="text"
                label="Seleeciona una cabecera"
                v-model="selectedHeader"
              ></v-select>
            </v-flex>
            <!-- Button to add a new header -->
            <v-flex xs1>
              <v-btn icon @click="addHeader">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-flex>
            <v-flex xs12>
              <!-- List of the selected headers in vuetify 2--> 
              <v-list>
                <v-list-item v-for="(header, index) in selectedHeaders" :key="index">
                  <v-list-item-content>
                    <v-list-item-title>{{ header.text }}</v-list-item-title>
                  </v-list-item-content>
                  <!-- Interchanable buttons -->
                  <v-list-item-action v-if="index !== 0">
                    <v-btn icon @click="changeHeaderLeft(index)">
                      <v-icon>mdi-arrow-up</v-icon>
                    </v-btn>
                  </v-list-item-action>
                  <v-list-item-action v-if="index !== selectedHeaders.length - 1">
                    <v-btn icon @click="changeHeaderRight(index)">
                      <v-icon>mdi-arrow-down</v-icon>
                    </v-btn>
                  </v-list-item-action>
                  <v-list-item-action>
                    <v-btn icon @click="removeHeader(index)">
                      <v-icon>delete</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" text @click="modal = false">Cerrar</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="saveConfiguration">Guardar</v-btn>
        </v-card-actions>
      </v-card>
  </v-dialog>
</template>

<script>

import { mapState, mapGetters } from 'vuex'
export default {

  name: 'ConfigurationDialog',
  props: {
    msg: String,
    configurationView: {
      type: String,
      default: ''
    },
    headers: {
      type: Array,
      default: () => []
    },
    defaultHeaders: {
      type: Array,
      default: () => []
    },
    dialog: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    title: 'Configuration',
    selectedHeader: null,
    selectedHeaders: [],
    forceUpdate: 0
  }),
  watch: {
    dialog (value) {
      if (value) {
        // If the dialog is opened, the selected headers are the ones that come from the prop
        if (this.user.viewsConfiguration[this.configurationView]) {
          this.selectedHeaders  = this.user.viewsConfiguration[this.configurationView].flat().map(header => Object.assign({},  header))
        } else {
          this.selectedHeaders = this.defaultHeaders.flat().map(header => Object.assign({},  header))
        }
      }
    }
  },
  computed : {
    ...mapState(['user', 'width', 'dark', 'language']),
    ...mapGetters(['inputColor']),
    isFullScreen () {
      return this.width.full < 990
    },
    modal: {
      get () {
        return this.dialog
      },
      set (value) {
        this.$emit('update-dialog', value)
      }
    }
  },
  methods: {
    changeHeaderLeft(index) {
      // Clean selected headers for the list to be updated
      const list = this.selectedHeaders.flat().map(header => Object.assign({},  header));
      this.selectedHeaders = []
      // Change header position to the left
      const header = Object.assign({}, list[index])
      list[index] = Object.assign(list[index - 1])
      list[index - 1] = header
      // Update the list
      this.selectedHeaders = list
    },
    changeHeaderRight(index) {
      // Clean selected headers for the list to be updated
      const list = this.selectedHeaders.flat().map(header => Object.assign({},  header));
      this.selectedHeaders = []
      // Change header position to the left
      const header = Object.assign({}, list[index])
      list[index] = Object.assign(list[index + 1])
      list[index + 1] = header
      // Update the list
      this.selectedHeaders = list
    },
    removeHeader(index) {
      // Remove header from the list
      this.selectedHeaders.splice(index, 1)
    },
    addHeader() {
      // selectedHeader 
      const header = this.headers.find(header => header.text === this.selectedHeader)
      // Get header from the prop
      this.selectedHeaders.push(header)
    },

    saveConfiguration() {
      // Send the configuration to the server
      this.$store.dispatch('saveUserConfiguration', {
        key: this.configurationView,
        value: this.selectedHeaders
      })
      // Save the configuration in mutation
      this.$store.commit('saveUserConfiguration', {
        view: this.configurationView,
        configuration: this.selectedHeaders
      })
      this.modal = false
    }
  }
}
</script>
