<template>
  <v-container pt-0 fluid class="mb-4">
    <v-layout row wrap pb-3>
      <v-flex>
        <v-card
          elevation="12"
        >
          <v-toolbar flat color="primary">
            <v-toolbar-title class="yellow-color-text">
              {{ $t(`pages.${$route.name}`) }}
            </v-toolbar-title>
          </v-toolbar>

          <v-card-text>
            <transition name="component-fade" mode="out-in">
              <v-layout v-show="!loading" row wrap align-center justify-center>
                <dual-list :items="bindedSensors" :keys="keys" :i18n="i18n" :dense="true" :dark="dark" @selected="attachItems" />
              </v-layout>
            </transition>

            <transition name="component-fade" mode="out-in">
              <v-layout v-show="loading" row wrap>
                <h1>{{ $t('helpers.loading') }}</h1>
              </v-layout>
            </transition>
          </v-card-text>

          <v-card-actions style="display: flex; justify-content: flex-end;">
            <a-btn :state="loading" color="primary" @click="bind">{{ $t('bindings.bind') }}</a-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import DualList from 'vuetify-dual-list'
import ABtn from '@/components/Button.vue'
import { mapState, mapGetters } from 'vuex'
import binding from '@/graphql/mutations/binding.gql'

export default {
  components: {
    DualList,
    ABtn
  },

  computed: {
    ...mapState(['dark', 'loraSensors', 'user', 'language']),
    ...mapGetters(['bindedSensors', 'inputColor'])
  },

  data () {
    return {
      loading: false,
      keys: { primary: 'sensorId', secondary: 'locationName' },
      i18n: this.$i18n.t('duallist2'),
      dense: true,
      selected: []
    }
  },

  methods: {
    attachItems (newItems) {
      this.selected = newItems
    },

    bind () {
      this.loading = true

      this.$apollo.mutate({
        mutation: binding,
        variables: {
          loraSensorId: 0,
          btSensors: this.selected.map((item, index, arr) => { return item.id }),
          apiToken: this.user.apiToken,
          language: this.language.toUpperCase(),
          action: 'UNBIND'
        }
      }).then((response) => {
        let { status, errors } = response.data.binding

        if (status === 'OK') {
          this.$store.commit('toggleAlert', {
            type: 'success',
            message: this.$i18n.t('bindings.unbindingSuccess')
          })

          this.$store.dispatch('getBtSensors')
        } else {
          this.$store.commit('toggleAlert', {
            type: 'warning',
            message: (errors.loraSensorId === undefined ? this.$i18n.t('errors.invalidFields') : errors.loraSensorId)
          })
        }
      }).catch((error) => {
        this.$store.commit('toggleAlert', {
          type: 'error',
          message: this.$i18n.t('errors.internalError')
        })
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style>
.component-fade-enter-active, .component-fade-leave-active {
  transition: opacity .3s ease;
}
.component-fade-enter, .component-fade-leave-to
/* .component-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>