<template>
  <v-app-bar flat color="transparent">
    <v-app-bar-nav-icon
      v-if="width.full < 960"
      @click.stop="setSidebar(true)"
    ></v-app-bar-nav-icon>
  </v-app-bar>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
export default {
  computed: mapState(['sidebar', 'width', 'dark', 'localVersion']),

  methods: {
    ...mapMutations(['setDark', 'setSidebar'])
  }
}
</script>

<style lang="scss">
  .v-toolbar:not(.v-toolbar--fixed) .v-toolbar__content{
    margin-left: 0;
  }
</style>