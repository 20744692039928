export default {
  setServerVersion (state, payload) {
    state.serverVersion = payload
  },

  setButtonState (state, payload) {
    state.buttonState = payload
  },

  setDark (state, payload) {
    state.dark = payload
  },

  setLoading (state, payload) {
    state.isLoading = payload
  },

  setMini (state, payload) {
    state.mini = payload
  },

  setSidebar (state, payload) {
    state.sidebar = payload
  },

  setUser (state, payload) {
    state.user = payload
  },

  updateMaxWidth (state, payload) {
    if (payload <= 960) {
      state.width = {
        full: payload,
        dialog: payload
      }
    } else {
      state.width = {
        full: payload,
        dialog: (payload / 8 * 7)
      }
    }
  },

  toggleAlert (state, payload) {},

  setBags (state, payload) {
    const dictList = {}
    for (let i = 0; i < payload.length; i++) {
      payload[i].stateValue = payload[i].state
      dictList[payload[i].id] = payload[i]
    }
    const bags = payload
    bags.sort((a, b) => {
      if (a.reference > b.reference) {
        return 1
      } else if (a.reference < b.reference) {
        return -1
      }
      return 0
    })
    state.bags = bags
    state.dictBags = dictList
  },

  setCompanyLocalLinkRequests (state, payload) {
    console.log('setCompanyLocalLinkRequests', payload)
    state.companyLocalLinkRequests = payload
  },

  setBag (state, payload) {
    state.bag = payload
  },

  setBtSensors (state, payload) {
    state.btSensors = payload
  },

  setLoraSensors (state, payload) {
    const loraSensors = payload
    loraSensors.sort((a, b) => {
      if (a.name > b.name) {
        return 1
      } else if (a.name < b.name) {
        return -1
      }
      return 0
    })
    state.loraSensors = loraSensors
  },

  setLoraNodes (state, payload) {
    let nodes = []
    nodes = payload
    // sort by name
    nodes.sort((a, b) => {
      if (a.name > b.name) {
        return 1
      } else if (a.name < b.name) {
        return -1
      }
      return 0
    })
    state.loraNodes = nodes
  },

  setUsers (state, payload) {
    state.users = payload
  },

  setInstallers (state, payload) {
    const installers = payload
    installers.sort((a, b) => {
      if (a.name > b.name) {
        return 1
      } else if (a.name < b.name) {
        return -1
      }
      return 0
    })
    state.installers = installers
  },

  setCompanyCentrals (state, payload) {
    const companyCentrals = payload
    companyCentrals.sort((a, b) => {
      if (a.name > b.name) {
        return 1
      } else if (a.name < b.name) {
        return -1
      }
      return 0
    })
    state.companyCentrals = companyLocals
  },

  setCompanyPromotors (state, payload) {
    const companyPromotors = payload
    companyPromotors.sort((a, b) => {
      if (a.name > b.name) {
        return 1
      } else if (a.name < b.name) {
        return -1
      }
      return 0
    })
    state.companyPromotors = companyPromotors
  },

  setCompanyLocals (state, payload) {
    const companyLocals = payload
    companyLocals.sort((a, b) => {
      if (a.name > b.name) {
        return 1
      } else if (a.name < b.name) {
        return -1
      }
      return 0
    })
    state.companyLocals = companyLocals
  },

  setCompanyDepositories (state, payload) {
    const companyDepositories = payload
    companyDepositories.sort((a, b) => {
      if (a.name > b.name) {
        return 1
      } else if (a.name < b.name) {
        return -1
      }
      return 0
    })
    state.companyDepositories = companyDepositories
  },

  setCategoryIncidents (state, payload) {
    state.categoryIncidents = payload
  },

  setCategoryAnalyses (state, payload) {
    state.categoryAnalyses = payload
  },

  setProducts (state, payload) {
    const products = payload
    products.sort((a, b) => {
      if (a.name > b.name) {
        return 1
      } else if (a.name < b.name) {
        return -1
      }
      return 0
    })
    state.products = products
  },

  setCycles (state, payload) {
    const cycles = payload
    cycles.sort((a, b) => {
      if (a.name > b.name) {
        return 1
      } else if (a.name < b.name) {
        return -1
      }
      return 0
    })
    state.cycles = cycles
  }, 

  setFieldBatches (state, payload) {
    const fieldBatches = payload
    fieldBatches.sort((a, b) => {
      if (a.name > b.name) {
        return 1
      } else if (a.name < b.name) {
        return -1
      }
      return 0
    })
    state.fieldBatches = fieldBatches
  },

  updateToken (state, payload) {
    state.user.apiToken = payload
  },

  toggleSidebar (state, payload = undefined) {
    state.sidebarStatus = (payload === undefined ? !state.sidebarStatus : payload)
  },

  setOrders (state, payload) {
    state.orders = payload
  },

  setCategoryOrders (state, payload) {
    state.categoryOrders = payload
  },

  setMessages (state, payload) {
    state.messages = payload
  },

  setMessagesReport (state, payload) {
    const messages = {}
    for (var i = payload.length - 1; i >= 0; i--) {
      messages[payload[i].bag_id] = payload[i]
    }
    state.messagesReport = messages
  },
  setMessagesMonitor (state, payload) {
    state.loraNodeMessages = payload[0]
    const loraSensors = payload[1]
    const bags = {}
    for (var i in loraSensors) {
      if (bags[loraSensors[i].bagId] === undefined) {
        bags[loraSensors[i].bagId] = {
          id: loraSensors[i].bagId,
          bagName: loraSensors[i].bagName,
          fieldBatch: loraSensors[i].fieldBatchName,
          node: loraSensors[i].loraNodeName,
          kitName: loraSensors[i].kitName,
          A: {},
          B: {},
          C: {},
          D: {},
          E: {}
        }
      }
      bags[loraSensors[i].bagId][loraSensors[i].ident] = loraSensors[i]
      bags[loraSensors[i].bagId][loraSensors[i].ident].eventCounters = JSON.parse(bags[loraSensors[i].bagId][loraSensors[i].ident].eventCounters)
    }

    const btSensors = payload[2]

    // eslint-disable-next-line no-redeclare
    for (var i in btSensors) {
      if (bags[btSensors[i].bagId] === undefined) {
        bags[btSensors[i].bagId] = {
          id: btSensors[i].bagId,
          bagName: btSensors[i].bagName,
          fieldBatch: btSensors[i].fieldBatchName,
          node: btSensors[i].loraNodeName,
          kitName: btSensors[i].kitName,
          A: {},
          B: {},
          C: {},
          D: {},
          E: {}
        }
      }
      bags[btSensors[i].bagId][btSensors[i].ident] = btSensors[i]
    }
    state.bagsMonitorMessages = bags
  },
  setCategoryAlerts (state, payload) {
    state.categoryAlerts = payload
  },
  
  setPresetRuleNotifications (state, payload) {
    state.presetRuleNotifications = payload
  },

  setIncidents (state, payload) {
    state.incidents = payload
  },
  
  setPriorities (state, payload) {
    state.priorities = payload
  },

  setKits (state, payload) {
    const kits = payload
    kits.sort((a, b) => {
      if (a.reference > b.reference) {
        return 1
      } else if (a.reference < b.reference) {
        return -1
      }
      return 0
    })
    state.kits = kits
  },

  setUsersSelect (state, payload) {
    const usersSelect = payload
    usersSelect.sort((a, b) => {
      if (a.name > b.name) {
        return 1
      } else if (a.name < b.name) {
        return -1
      }
      return 0
    })
    state.usersSelect = usersSelect
  },

  setAlerts (state, payload) {
    // const alerts = payload
    // for (let i = 0; i < alerts.length; i++) {
    //   let object
    //   if (alerts[i].notification_type === 'bag' ) {
    //     object = state.bags.find((bag) => bag.id === alerts[i].related_id)
    //   } else if (alerts[i].notification_type === 'node') {
    //     object = state.loraNodes.find((loraNode) => loraNode.id === alerts[i].related_id)
    //   } else {
    //     continue
    //   }
    //   alerts[i].object = object
    // }
    state.alerts = payload
  },

  updateMessagesMonitor (state, payload) {
    
  },

  setIntervalId (state, payload) {
    if (state.setIntervalId !== undefined) {
      clearInterval(state.IntervalId)
    }
    state.IntervalId = payload
  },

  setIntervalAllId (state, payload) {
    if (state.setIntervalAllId !== undefined) {
      clearInterval(state.setIntervalAllId)
    }
    state.setIntervalAllId = payload
  },

  setNotificationGroups (state, payload) {
    state.notificationGroups = payload
  },

  setBagNotificationGroups (state, payload) {
    console.log('setBagNotificationGroups', payload)
    state.bagNotificationGroups = payload
  },
  setNodeNotificationGroups (state, payload) {
    console.log('setNodeNotificationGroups', payload)
    state.nodeNotificationGroups = payload
  },
  saveUserConfiguration (state, payload) {
    // Clean view configuration before save
    const viewsConfiguration = {
      ...state.user.viewsConfiguration
    }
    viewsConfiguration[payload.view] = payload.configuration.flat().map(header => Object.assign({},  header))
    state.user = {
      ...state.user,
      viewsConfiguration
    }
  },
  setIntervalDate (state, payload) {
    state.intervalDate = payload
  },
  setInitStateNotifications (state, payload) {
    state.initStateNotifications = payload
  }
}
