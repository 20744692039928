const assets = {}

assets.install = function (Vue) {
  Vue.prototype.$assets = new Vue({
    data () {
      return {
        favicon: {
          normal: require('../../assets/images/favicon.svg'),
          white: require('../../assets/images/favicon.svg')
        },
        logo: require('../../assets/images/isologo_cuadro_web.png'),
        logo2: require('../../assets/images/logo_GRAVANZ-solo_blanco.svg'),
        background: require('../../assets/images/background.jpg'),
        platform: require('../../assets/images/platform.jpg'),
        avatar: require('../../assets/images/default-avatar.png')
      }
    }
  })
}

export default assets
