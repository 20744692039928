<template>
  <v-container pt-0 fluid>
    <v-layout row wrap pb-3>
      <div id="nodesSearchFixed">
        <div class="searchFixed">
          <v-card outlined elevation="2" class="ma-3">
            <v-card-text>
              <v-text-field
                v-model="search"
                append-icon="search"
                :label="$t('actions.search')"
                single-line
                :color="inputColor"
                hide-details
              />
            </v-card-text>
          </v-card>
        </div>
      </div>
      <v-flex>
        <v-card
          id="nodesCard"
          elevation="12"
        >
          <v-toolbar flat color="primary">
            <v-toolbar-title class="yellow-color-text">
              {{ $t(`pages.${$route.name}`) }}
            </v-toolbar-title>
            
            <v-spacer />
            <v-text-field
              id="nodesSearch"
              :items-per-page="-1"
              v-model="search"
              append-icon="search"
              :label="$t('actions.search')"
              single-line
              :color="inputColor"
              hide-details
            />
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  slot="activator"
                  color="white"
                  text
                  icon
                  ma-0
                  rounded
                  style="margin: 0;"
                  v-on="on"
                  @click="showConfiguration"
                >
                  <v-icon class="yellow-color-text">mdi-cog</v-icon>
                </v-btn>
              </template>

              <span class="white--text">{{ $t(`actions.configuration`) }}</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  slot="activator"
                  color="white"
                  text
                  icon
                  ma-0
                  rounded
                  style="margin: 0;"
                  v-on="on"
                  @click="newItem"
                >
                  <v-icon class="yellow-color-text">mdi-plus-circle-outline</v-icon>
                </v-btn>
              </template>

              <span class="white--text">{{ $t(`${objectName.multiple}.titleNew`) }}</span>
            </v-tooltip>
            <refresh-button v-if="!isMobile"></refresh-button>
            <export-button :headers="userConfiguration" :items="loraNodeSensors " :file-name="'Nodos'" :search="search"></export-button>
          </v-toolbar>
          <v-card-text class="py-2">
            <v-data-table
              fixed-header
              height="800"
              :headers="renderHeaders"
              :items="loraNodeSensors"
              :search="search"
              :items-per-page="-1"
              :footer-props="{
                'next-icon': 'mdi-chevron-right',
                'prev-icon': 'mdi-chevron-left',
              }"
            >
              <template v-slot:body="{ items }">
                <tbody>
                  <tr v-for="item in items" :key="item.id">
                    <td v-for="(header, index) in userConfiguration" :key="`${header.value}-${item.id}`">
                      <span :style="index === 0 ? 'font-weight: bold;' : ''" v-html="header.format(item[header.value])"></span>
                    </td>
                    <td style="text-align: right; padding: 0;">
                      <v-btn
                        text
                        icon
                        rounded
                        color="info"
                        @click="showItem(item.id)"
                      >
                        <v-icon>mdi-file-find-outline</v-icon>
                      </v-btn>
                      <v-btn
                        text
                        icon
                        rounded
                        color="warning"
                        @click="editItem(item.id)"
                      >
                        <v-icon>mdi-file-document-edit-outline</v-icon>
                      </v-btn>
  
                      <v-btn
                        text
                        icon
                        rounded
                        color="error"
                        @click="deleteItem(item.id)"
                      >
                        <v-icon>mdi-file-cancel-outline</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
              <tr slot="no-results">
                <td :colspan="renderHeaders.length" v-html="$t('helpers.notFound', { search })" />
              </tr>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>

    <v-dialog
      id="formModal"
      v-model="formModal"
      scrollable
      persistent
      :fullscreen="isFullScreen"
      :overlay="false"
      :max-width="width.dialog"
      transition="dialog-transition"
      :dark="dark"
    >
      <v-card>
        <v-toolbar flat color="primary">
          <v-toolbar-title class="yellow-color-text">
            {{ title }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="pa-0">
          <v-tabs
            background-color="primary"
            class="text--white"
            slider-color="primary"
            grow
            dark
          >
            <v-tab>{{ $t(`${objectName.multiple}.tabs.basic`) }}</v-tab>
            <v-tab>{{ $t(`${objectName.multiple}.tabs.rules`) }}</v-tab>

            <v-tab-item class="px-5">
              <v-layout row wrap pa-2 align-center justify-center>
                <v-flex class="pl-1 pr-1" xs12 sm6>
                  <v-text-field
                    v-model="object.name"
                    :label="$t(`${objectName.multiple}.name`)"
                    prepend-icon="mdi-label-variant"
                    :disabled="!isEditting"
                    :color="inputColor"
                    :error="objectErrors.name !== undefined"
                    :error-messages="parseErrors(objectErrors.name)"
                  />
                </v-flex>

                <v-flex class="pl-1 pr-1" xs12 sm6>
                  <v-text-field
                    v-model="object.subscriberId"
                    :label="$t(`${objectName.multiple}.subscriberId`)"
                    prepend-icon="mdi-label-variant"
                    :disabled="!isEditting"
                    :color="inputColor"
                    :error="objectErrors.subscriberId !== undefined"
                    :error-messages="parseErrors(objectErrors.subscriberId)"
                  />
                </v-flex>

                <v-flex class="pl-1 pr-1" xs12 sm6 md4>
                  <v-text-field
                    v-model="object.localContactName"
                    :label="$t(`${objectName.multiple}.localContactName`)"
                    prepend-icon="mdi-label-variant"
                    :disabled="!isEditting"
                    :color="inputColor"
                    :error="objectErrors.localContactName !== undefined"
                    :error-messages="parseErrors(objectErrors.localContactName)"
                  />
                </v-flex>

                <v-flex class="pl-1 pr-1" xs12 sm6 md4>
                  <v-text-field
                    v-model="object.localContactPhone"
                    :label="$t(`${objectName.multiple}.localContactPhone`)"
                    prepend-icon="mdi-label-variant"
                    :disabled="!isEditting"
                    :color="inputColor"
                    :error="objectErrors.localContactPhone !== undefined"
                    :error-messages="parseErrors(objectErrors.localContactPhone)"
                  />
                </v-flex>

                <v-flex class="pl-1 pr-1" xs12 md4>
                  <v-text-field
                    v-model="object.gwLora"
                    :label="$t(`${objectName.multiple}.gwLora`)"
                    prepend-icon="mdi-label-variant"
                    :disabled="!isEditting"
                    :color="inputColor"
                    :error="objectErrors.gwLora !== undefined"
                    :error-messages="parseErrors(objectErrors.gwLora)"
                  />
                </v-flex>
                <v-flex v-if="object.id !== undefined" class="pl-1 pr-1" xs12>
                  <v-text-field
                    v-model="object.createdAtValue"
                    :label="$t(`${objectName.multiple}.createdAt`)"
                    :disabled="true"
                    :color="inputColor"
                  />
                </v-flex>
                <v-flex class="pl-1 pr-1" xs12 sm6 md4>
                  <v-text-field
                    v-model="object.geofence.latitude"
                    :label="$t(`${objectName.multiple}.latitude`)"
                    :disabled="!isEditting"
                    type="number"
                    max="90"
                    min="-90"
                    :color="inputColor"
                    @keypress="locateGeofence"
                  />
                </v-flex>
                <v-flex class="pl-1 pr-1" xs12 sm6 md4>
                  <v-text-field
                    v-model="object.geofence.longitude"
                    :label="$t(`${objectName.multiple}.longitude`)"
                    :disabled="!isEditting"
                    type="number"
                    max="180"
                    min="-180"
                    :color="inputColor"
                    @keypress="locateGeofence"
                  />
                </v-flex>

                <v-flex class="pl-1 pr-1" xs12 md4>
                  <v-text-field
                    v-model="object.geofence.radius"
                    :label="$t(`${objectName.multiple}.radius`)"
                    :disabled="!isEditting"
                    :color="inputColor"
                    @keypress="locateGeofence"
                  />
                </v-flex>

                <v-flex xs12>
                  <div id="mapLora" style="width: 100%; height: 250px;" />
                </v-flex>
              </v-layout>
            </v-tab-item>
            <v-tab-item class="px-5">
              <v-layout row wrap>
                <v-flex v-if="isEditting" xs12 class="mt-2" style="text-align: right;">
                  <v-btn
                    color="white--text"
                    text
                    style="margin: 0;"
                    @click="addRule"
                  >
                    {{ $t('loraNodes.ruleNotifications.add') }}
                    &nbsp;
                    <v-icon class="yellow-color-text">mdi-plus-circle-outline</v-icon>
                  </v-btn>
                </v-flex>

                <v-flex xs12>
                  <v-data-table
                    :headers="rulesHeaders"
                    :disable-pagination="true"
                    hide-default-footer                    
                    :items="object.ruleNotifications"
                    :items-per-page="-1"
                    :footer-props="{
                      'next-icon': 'mdi-chevron-right',
                      'prev-icon': 'mdi-chevron-left'
                    }"
                  >
                    <template v-slot:body="{ items }">
                      <tr v-for="(item, index) in items" :key="item.id">
                        <td>{{ item.name }}</td>
                        <!-- <td></td> -->
                        <td>{{ $t(`loraNodes.ruleNotifications.calculation.${item.calculation.type.toLowerCase()}`) }}</td>
                        <td style="text-align: right; padding: 0;">
                          <v-btn
                            text
                            icon
                            rounded
                            color="warning"
                            @click="editRule(index)"
                          >
                            <v-icon>mdi-file-document-edit-outline</v-icon>
                          </v-btn>

                          <v-btn
                            v-if="isEditting"
                            text
                            icon
                            rounded
                            color="error"
                            @click="deleteRule(index)"
                          >
                            <v-icon>mdi-file-cancel-outline</v-icon>
                          </v-btn>
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-flex>
              </v-layout>
            </v-tab-item>
          </v-tabs>
        </v-card-text>

        <v-card-actions v-if="isEditting" style="max-height: 70px;">
          <v-btn color="error" @click="discardChanges">{{ $t('actions.discard') }}</v-btn>

          <v-spacer />

          <a-button v-if="object.id !== undefined" :state="buttonState" color="primary" @click="saveChanges">{{ $t('actions.save') }}</a-button>
          
          <a-button v-else-if="object.id === undefined" :state="buttonState" color="primary" @click="createItem">{{ $t('actions.create', { name: $t(`${objectName.multiple}.objectName`) }) }}</a-button>
        </v-card-actions>

        <v-card-actions v-else style="max-height: 70px;">
          <v-btn color="error" @click="discardChanges">{{ $t('actions.close') }}</v-btn>
          
          <v-spacer />
          
          <v-btn color="primary" @click="editItem(object.id)">{{ $t('actions.edit', { name: $t(`${objectName.multiple}.objectName`)}) }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      id="ruleModal"
      v-model="ruleModal"
      scrollable
      persistent
      :fullscreen="isFullScreen"
      :overlay="false"
      :max-width="width.dialog"
      transition="dialog-transition"
      :dark="dark"
    >
      <v-card>
        <v-toolbar flat color="primary">
          <v-toolbar-title class="yellow-color-text">
            {{ ruleTitle }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="pa-0">
          <v-tabs 
            v-model="ruleTab"
            background-color="primary"
            class="text--white"
            slider-color="primary"
            grow
            dark
          >
            <v-tab style="font-weight: bold;">{{ $t(`${objectName.multiple}.basicInformation`).toUpperCase() }}</v-tab>
            <v-tab style="font-weight: bold;">{{ $t(`${objectName.multiple}.message`).toUpperCase() }}</v-tab>
            <v-tab style="font-weight: bold;">{{ $t(`${objectName.multiple}.messageVoice`).toUpperCase() }}</v-tab>
            <v-tab style="font-weight: bold;">{{ $t(`${objectName.multiple}.deliveryMethodsLabel`).toUpperCase() }}</v-tab>

            <v-tab-item class="px-5">
              <v-layout row wrap pa-2 align-center justify-center>
                <v-flex class="pl-2 pr-2" xs12 md3>
                  <v-text-field
                    v-model="ruleObject.name"
                    :label="$t('loraNodes.ruleNotifications.name')"
                    :disabled="!isEditting"
                    :color="inputColor"
                  />
                </v-flex>
                
                <v-flex class="pl-2 pr-2" xs12 md3>
                  <v-select
                    v-model="ruleObject.categoryAlertId"
                    :items="categoryAlertsLoraNode"
                    :label="$t('loraNodes.ruleNotifications.types.title')"
                    :disabled="!isEditting"
                    :color="inputColor"
                    item-text="name"
                    item-value="id"
                  />
                </v-flex>

                <v-flex class="pl-2 pr-2" xs12 md3>
                  <v-select
                    v-model="ruleObject.priorityId"
                    :items="priorities"
                    :label="$t('loraNodes.ruleNotifications.priority')"
                    :disabled="!isEditting"
                    :color="inputColor"
                    item-text="name"
                    item-value="id"
                  />
                </v-flex>

                <v-flex class="pl-2 pr-2" xs12 md3>
                  <v-select
                    v-model="ruleObject.calculation.type"
                    :items="[
                      { text: $t('loraNodes.ruleNotifications.calculation.range'), value: 'RANGE' },
                      { text: $t('loraNodes.ruleNotifications.calculation.mask'), value: 'MASK' }
                    ]"
                    :label="$t('loraNodes.ruleNotifications.calculation.title')"
                    :disabled="!isEditting"
                    :color="inputColor"
                  />
                </v-flex>
              </v-layout>

              <v-layout v-if="ruleObject.calculation.type === 'RANGE'" row wrap>
                <v-flex class="pl-2 pr-2" xs12 sm6>
                  <v-select
                    v-model="ruleObject.calculation.operatorMinimum"
                    :items="['>', '>=', '<', '<=']"
                    :label="$t('loraNodes.ruleNotifications.calculation.operatorMinimum')"
                    :disabled="!isEditting"
                    :color="inputColor"
                  />
                </v-flex>

                <v-flex class="pl-2 pr-2" xs12 sm6>
                  <v-select
                    v-model="ruleObject.calculation.operatorMaximum"
                    :items="['>', '>=', '<', '<=']"
                    :label="$t('loraNodes.ruleNotifications.calculation.operatorMaximum')"
                    :disabled="!isEditting"
                    :color="inputColor"
                  />
                </v-flex>

                <v-flex class="pl-2 pr-2" xs12 sm6>
                  <v-text-field
                    v-model="ruleObject.calculation.valueMinimum"
                    :label="$t('loraNodes.ruleNotifications.calculation.valueMinimum')"
                    :disabled="!isEditting"
                    type="number"
                    :color="inputColor"
                  />
                </v-flex>

                <v-flex class="pl-2 pr-2" xs12 sm6>
                  <v-text-field
                    v-model="ruleObject.calculation.valueMaximum"
                    :label="$t('loraNodes.ruleNotifications.calculation.valueMaximum')"
                    :disabled="!isEditting"
                    type="number"
                    :color="inputColor"
                  />
                </v-flex>
              </v-layout>

              <v-layout v-else row wrap>
                <v-flex class="pl-2 pr-2" xs12>
                  <v-text-field
                    v-model="ruleObject.calculation.mask"
                    :label="$t('loraNodes.ruleNotifications.calculation.mask')"
                    :disabled="!isEditting"
                    :color="inputColor"
                  />
                </v-flex>
              </v-layout>
            </v-tab-item>
            <v-tab-item>
              <v-layout row wrap pa-4 align-center justify-center>
                <v-flex xs12 pa-1>
                  <v-textarea
                    v-model="ruleObject.message"
                    :label="$t(`${objectName.multiple}.message`)"
                    :disabled="!isEditting"
                    :color="inputColor"
                    :error="objectErrors.message !== undefined"
                    :error-messages="parseErrors(objectErrors.message)"
                  />
                </v-flex>

                <v-flex xs12>
                  <h5>
                    {{ $t(`${objectName.multiple}.variables`) }}
                  </h5>
                  <v-data-table
                    :headers="messageHeaders"
                    :items="messageValues"
                    hide-default-footer
                    class="elevation-1"
                  >
                    <template v-slot:body="{ items }">
                      <tr v-for="item in items" :key="item.id">
                        <td>{{ item.name }}</td>
                        <td>{{ item.value }}</td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-flex>
              </v-layout>
            </v-tab-item>

            <v-tab-item class="px-5">
              <v-layout row wrap pa-4 align-center justify-center>
                <v-flex xs12 pa-1>
                  <v-textarea
                    v-model="ruleObject.messageVoice"
                    :label="$t(`${objectName.multiple}.messageVoice`)"
                    :disabled="!isEditting"
                    :color="inputColor"
                    :error="objectErrors.messageVoice !== undefined"
                    :error-messages="parseErrors(objectErrors.messageVoice)"
                  />
                </v-flex>

                <v-flex xs12>
                  <h5>
                    {{ $t(`${objectName.multiple}.variables`) }}
                  </h5>
                  <v-data-table
                    :headers="messageHeaders"
                    :items="messageValues"
                    hide-default-footer
                    class="elevation-1"
                  >
                    <template v-slot:body="{ items }">
                      <tr v-for="item in items" :key="item.id">
                        <td>{{ item.name }}</td>
                        <td>{{ item.value }}</td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-flex>
              </v-layout>
            </v-tab-item>
        
            <v-tab-item class="px-5">
              <v-layout row wrap class="pa-4">
                <v-flex xs12>
                  <v-layout row wrap>
                    <v-flex xs12 md4>
                      <v-switch 
                        v-model="ruleObject.deliveryMethods.email.active"
                        :disabled="!isEditting" :label="$t(`${objectName.multiple}.deliveryMethods.email`)"
                      />
                    </v-flex>

                    <v-flex v-if="ruleObject.deliveryMethods.email.active" xs12 md8>
                      <v-autocomplete
                        v-model="ruleObject.deliveryMethods.email.destinations"
                        :items="companyUsers"
                        :label="$t(`helpers.destinations`)"
                        :disabled="!isEditting"
                        :color="inputColor"
                        :dark="dark"
                        multiple
                        small-chips
                        item-text="username"
                        item-value="id"
                      />                      
                    </v-flex>
                  </v-layout>
                  <v-layout row wrap>
                    <v-flex xs12 md4>
                      <v-switch 
                        v-model="ruleObject.deliveryMethods.telegram.active"
                        :disabled="!isEditting" :label="$t(`${objectName.multiple}.deliveryMethods.telegram`)"
                      />
                    </v-flex>

                    <v-flex v-if="ruleObject.deliveryMethods.telegram.active" xs12 md8>
                      <v-text-field
                        v-model="ruleObject.deliveryMethods.telegram.chatId"
                        :label="$t(`helpers.chatId`)"
                        :disabled="!isEditting"
                        :color="inputColor"
                        :dark="dark"
                      />                        
                    </v-flex>
                  </v-layout>

                  <v-layout row wrap>
                    <v-flex xs12 sm4>
                      <v-switch 
                        v-model="ruleObject.deliveryMethods.sendRequest.active"
                        :disabled="!isEditting" :label="$t(`${objectName.multiple}.deliveryMethods.sendRequest`)"
                      />
                    </v-flex>
                    <v-flex v-if="ruleObject.deliveryMethods.sendRequest.active" xs12 sm4 pa-2>
                      <v-select
                        v-model="ruleObject.deliveryMethods.sendRequest.type"
                        :items="['GET', 'POST']"
                        :disabled="!isEditting"
                        :label="$t(`helpers.requestType`)"
                        :color="inputColor"
                        :dark="dark"
                      />
                    </v-flex>

                    <v-flex v-if="ruleObject.deliveryMethods.sendRequest.active" xs12 sm4 pa-2>
                      <v-text-field
                        v-model="ruleObject.deliveryMethods.sendRequest.url"
                        :label="$t(`helpers.url`)"
                        :disabled="!isEditting"
                        :color="inputColor"
                        :dark="dark"
                      />
                    </v-flex>
                  </v-layout>
                  <v-layout row wrap>
                    <v-flex xs12 md12>
                      <v-switch 
                        v-model="ruleObject.deliveryMethods.twilio.active"
                        :disabled="!isEditting" :label="$t(`${objectName.multiple}.deliveryMethods.twilio`)"
                      />
                    </v-flex>

                    <v-flex v-if="ruleObject.deliveryMethods.twilio.active" xs12 md6 class="px-2">
                      <v-autocomplete
                        v-model="ruleObject.deliveryMethods.twilio.destinations"
                        :items="companyUsers"
                        :label="$t(`helpers.destinations`)"
                        :disabled="!isEditting"
                        :color="inputColor"
                        :dark="dark"
                        multiple
                        small-chips
                        item-text="username"
                        item-value="id"
                      />                      
                    </v-flex>
                    <v-flex v-if="ruleObject.deliveryMethods.twilio.active" xs12 md6 class="px-2">
                      <v-autocomplete
                        v-model="ruleObject.deliveryMethods.twilio.callDestinations"
                        :items="companyUsers"
                        :label="$t(`helpers.callDestinations`)"
                        :disabled="!isEditting"
                        :color="inputColor"
                        :dark="dark"
                        multiple
                        small-chips
                        item-text="username"
                        item-value="id"
                      />                      
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-tab-item>
          </v-tabs>
        </v-card-text>

        <v-card-actions v-if="isEditting" style="max-height: 70px;">
          <v-btn v-if="ruleStateNew" color="error" @click="discardNewRule">{{ $t('actions.discard') }}</v-btn>

          <v-btn v-else="" color="error" @click="discardRule">{{ $t('actions.discard') }}</v-btn>

          <v-spacer />

          <v-btn color="success" @click="saveRule">{{ $t('actions.save') }}</v-btn>
        </v-card-actions>

        <v-card-actions v-else style="max-height: 70px;">
          <v-btn color="error" @click="discardRule">{{ $t('actions.discard') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="deleteDialog"
      max-width="290"
    >
      <v-card>
        <v-card-title class="headline" style="font-size: 1.2em !important; text-align: center;">{{ $t('helpers.confirmDelete', { name: $t(`${objectName.multiple}.objectName`) }) }}</v-card-title>

        <v-card-actions style="text-align: center;">
          <v-spacer />

          <v-btn
            color="error"
            flat
            icon
            @click="deleteConfirm = false; deleteDialog = false; deleteId = 0"
          >
            <v-icon>mdi-cancel</v-icon>
          </v-btn>

          <v-btn
            color="success"
            flat
            icon
            @click="deleteConfirmation"
          >
            <v-icon>mdi-checkbox-marked-circle-outline</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <configuration-table-dialog 
      :dialog="configurationModal" 
      :headers="configurationHeaders"
      :defaultHeaders="defaultHeaders"
      configurationView="nodes"
      @update-dialog="(value) => configurationModal = value"
    />
  </v-container>
</template>

<script>
import AButton from '@/components/Button.vue'
import RefreshButton from '@/components/utils/RefreshButton.vue'
import ExportButton from '@/components/utils/ExportButton.vue'
import { mapState, mapGetters } from 'vuex'

import add from '@/graphql/mutations/loraNodes/add.gql'
import edit from '@/graphql/mutations/loraNodes/edit.gql'
import object from '@/graphql/queries/loraNodes/detail.gql'
import moment from 'moment'
import ConfigurationTableDialog from '../../../components/platform/ConfigurationTableDialog.vue'

const objectName = {
  single: 'loraNode',
  multiple: 'loraNodes',
  object: {
    single: 'LoraNode',
    multiple: 'LoraNodes'
  }
}

export default {
  components: {
    AButton,
    RefreshButton,
    ConfigurationTableDialog,
    ExportButton
  },

  data () {
    return {
      objectName,
      configurationModal: false,
      pagination: { rowsPerPage: 20 },
      formModal: false,
      formTab: 0,
      isEditting: true,
      deleteDialog: false,
      showPassword: false,
      deleteConfirm: false,
      buttonState: false,
      deleteId: 0,
      map: null,
      geofence: null,
      fieldGeofences: [],
      object: {},
      objectErrors: {},
      search: '',
      ruleObject: {
        calculation: {},
        deliveryMethods: {
          email: {
            active: false,
            destinations: []
          },
          telegram: {
            active: false,
            chatId: ''
          },
          sendRequest: {
            active: false,
            type: 'GET',
            url: ''
          },
          twilio: {
            active: false,
            destinations: [],
            callDestinations: []
            // approved_by_central: false
          }
        }
      },
      ruleModal: false,
      ruleTab: 0,
      ruleStateNew: false,
      ruleIndex: null,
      headers: [
        {
          text: this.$i18n.t(`${objectName.multiple}.name`),
          align: 'left',
          sortable: true,
          value: 'name'
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.subscriberId`),
          align: 'left',
          sortable: true,
          value: 'subscriberId'
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.isAlive`),
          align: 'left',
          sortable: true,
          value: 'isAlive'
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.updatedAt`),
          align: 'left',
          sortable: true,
          value: 'updatedAt'
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.loraSensorsQuantity`),
          align: 'left',
          sortable: true,
          value: 'loraSensorsQuantity'
        },
        {
          align: 'left',
          sortable: true,
          text: this.$i18n.t(`node.satelitalGW`),
          value: 'satelitalGW'
        },
        {
          align: 'left',
          sortable: true,
          text: this.$i18n.t(`node.loraGW`),
          value: 'gwLora'
        },
        {
          text: this.$i18n.t('helpers.actions'),
          align: 'right',
          sortable: false,
          value: 'actions'
        }
      ]
    }
  },

  computed: {
    ...mapState(['user', 'width', 'dark', 'language', 'priorities', 'fieldBatches']),
    ...mapState({ data: objectName.multiple }),
    ...mapGetters(['inputColor', 'loraNodeSensors', 'categoryAlertsLoraNode', 'companyUsers', 'nodeMessages']),
    isMobile () {
      return this.width.full < 600
    },
    messageHeaders () {
      return [
        {
          text: this.$i18n.t(`variables.name`),
          align: 'left',
          sortable: true,
          value: 'name'
        },
        {
          text: this.$i18n.t(`variables.variable`),
          align: 'left',
          sortable: true,
          value: 'variable'
        }
      ]
    },
    messageValues () {
      return [
        {
          name: this.$i18n.t(`variables.nodeName`),
          value: '{nodeName}'
        },
        {
          name: this.$i18n.t(`variables.nodeSubscriber`),
          value: '{nodeSubscriber}'
        },
        {
          name: this.$i18n.t(`variables.sensorDevice`),
          value: '{sensorDevice}'
        },
        {
          name: this.$i18n.t(`variables.sensorBT`),
          value: '{sensorBT}'
        },
        {
          name: this.$i18n.t(`variables.bagReference`),
          value: '{bagReference}'
        },
        {
          name: this.$i18n.t(`variables.bagNomenclature`),
          value: '{bagNomenclature}'
        },
        {
          name: this.$i18n.t(`variables.bagCycle`),
          value: '{bagCycle}'
        },
        {
          name: this.$i18n.t(`variables.bagProduct`),
          value: '{bagProduct}'
        },
        {
          name: this.$i18n.t(`variables.bagFieldBatch`),
          value: '{bagFieldBatch}'
        },
        {
          name: this.$i18n.t(`variables.messageId`),
          value: '{messageId}'
        },
        {
          name: this.$i18n.t(`variables.messageTime`),
          value: '{messageTime}'
        },
        {
          name: this.$i18n.t(`variables.messageTemps`),
          value: '{messageTemps}'
        },
        {
          name: this.$i18n.t(`variables.messageVbats`),
          value: '{messageVbats}'
        },
        {
          name: this.$i18n.t(`variables.messageVbatg`),
          value: '{messageVbatg}'
        },
        {
          name: this.$i18n.t(`variables.messageEvent`),
          value: '{messageEvent}'
        }
      ]
    },
    rulesHeaders () {
      return [
        {
          text: this.$i18n.t('loraNodes.ruleNotifications.name'),
          value: 'name',
          sortable: false
        },
        // {
        //   text: this.$i18n.t('loraNodes.ruleNotifications.types.title'),
        //   value: 'types',
        //   sortable: false
        // },
        {
          text: this.$i18n.t('loraNodes.ruleNotifications.calculation.title'),
          value: 'calculation',
          sortable: false
        },
        {
          text: this.$i18n.t('helpers.actions'),
          align: 'right',
          value: 'actions',
          sortable: false
        }
      ]
    },
    renderHeaders () {
      return [
        ...this.userConfiguration,
        {
          text: this.$i18n.t('helpers.actions'),
          align: 'right',
          sortable: false,
          value: 'actions'
        }
      ]
    },

    userConfiguration () {
      const basicConfiguration = this.user.viewsConfiguration['nodes'] || this.defaultHeaders
      // For in basicConfiguration to get the value and get the format from configurationHeaders
      const configuration = []
      for (var i in basicConfiguration) {
        const index = this.configurationHeaders.findIndex((item) => { return item.value === basicConfiguration[i].value && item.text === basicConfiguration[i].text })
        if (index !== -1) {
          configuration.push(Object.assign({}, this.configurationHeaders[index]))
        }
      }
      return configuration
    },
    configurationHeaders () {
      return [
        {
          text: this.$i18n.t(`${objectName.multiple}.name`),
          align: 'left',
          sortable: true,
          value: 'name',
          format: (value) => {
            return value
          }
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.subscriberId`),
          align: 'left',
          sortable: true,
          value: 'subscriberId',
          format: (value) => {
            return value
          }
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.isAlive`),
          align: 'left',
          sortable: true,
          value: 'isAlive',
          format: (value) => {
            return value ? 'Si' : 'No'
          }
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.updatedAt`),
          align: 'left',
          sortable: true,
          value: 'message',
          format: (value) => {
            return this.$lastUpdate(value)
          }
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.loraSensorsQuantity`),
          align: 'left',
          sortable: true,
          value: 'loraSensorsQuantity',
          format(value) {
            return `<span class="text-xs-right">${value}<span>`
          }
        },
        {
          align: 'left',
          sortable: true,
          text: this.$i18n.t(`node.satelitalGW`),
          value: 'message',
          format: (value) => {
            return `<span class="v-chip ${this.$nodeMessageColor(value)} v-size--small text-center">
  <span class="v-chip__content">
    <strong>${this.$nodeMessageToText(value)}</strong>
  </span>
</span>`
          }
        },
        {
          align: 'left',
          sortable: true,
          text: this.$i18n.t(`node.loraGW`),
          value: 'gwLora',
          format: (value) => {
            return value
          }
        },
        {
          align: 'left',
          sortable: true,
          text: this.$i18n.t(`${objectName.multiple}.createdAt`),
          value: 'createdAt',
          format: (value) => { return `${this.$date(value)} ${this.$time(value)}` }
        }
      ]
    },
    defaultHeaders () {
      return [
        {
          text: this.$i18n.t(`${objectName.multiple}.name`),
          align: 'left',
          sortable: true,
          value: 'name',
          format: (value) => {
            return value
          }
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.subscriberId`),
          align: 'left',
          sortable: true,
          value: 'subscriberId',
          format: (value) => {
            return value
          }
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.isAlive`),
          align: 'left',
          sortable: true,
          value: 'isAlive',
          format: (value) => {
            return value ? 'Si' : 'No'
          }
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.updatedAt`),
          align: 'left',
          sortable: true,
          value: 'message',
          format: (value) => {
            return this.$lastUpdate(value)
          }
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.loraSensorsQuantity`),
          align: 'left',
          sortable: true,
          value: 'loraSensorsQuantity',
          format(value) {
            return `<span class="text-xs-right">${value}<span>`
          }
        },
        {
          align: 'left',
          sortable: true,
          text: this.$i18n.t(`node.satelitalGW`),
          value: 'message',
          format: (value) => {
            return `<span class="v-chip ${this.$nodeMessageColor(value)} v-size--small text-center">
  <span class="v-chip__content">
    <strong>${this.$nodeMessageToText(value)}</strong>
  </span>
</span>`
          }
        },
        {
          align: 'left',
          sortable: true,
          text: this.$i18n.t(`node.loraGW`),
          value: 'gwLora',
          format: (value) => {
            return value
          }
        },
        {
          align: 'left',
          sortable: true,
          text: this.$i18n.t(`${objectName.multiple}.createdAt`),
          value: 'createdAt',
          format: (value) => { return `${this.$date(value)} ${this.$time(value)}` }
        }
      ]
    },
    defaultObject () {
      return {
        id: undefined,
        name: '',
        subscriberId: '',
        localContactName: '',
        localContactPhone: '',
        gwLora: '',
        geofence: {
          type: 'RADIAL',
          latitude: -32.3671182,
          longitude: -64.4977879,
          radius: 100
        },
        ruleNotifications: []
      }
    },

    isFullScreen () {
      return this.width.full < 990
    },

    title () {
      if (!this.isEditting) {
        return this.$i18n.t(`${objectName.multiple}.titleShow`, { name: this.object.name })
      } else {
        if (this.object.id === undefined) {
          return this.$i18n.t(`${objectName.multiple}.titleNew`)
        } else {
          return this.$i18n.t(`${objectName.multiple}.titleEdit`, { name: this.object.name })
        }
      }
    },
    ruleTitle () {
      return this.$i18n.t(`${objectName.multiple}.ruleNotifications.title`, { name: this.ruleObject.name })
    }
  },

  created () {
    this.object = Object.assign({}, this.defaultObject)
    window.addEventListener('scroll', this.handleScroll)
  },

  destroyed () {
    window.removeEventListener('scroll', this.handleScroll)
  },

  mounted () {
    const card = document.querySelector('#nodesCard')
    const search = document.getElementById('nodesSearchFixed')

    var observer = new ResizeObserver(function (entries) {
      entries.forEach(function (entry) {
        search.style.width = `${card.offsetWidth}px`
      })
    })

    observer.observe(card)
  },

  methods: {
    showConfiguration () {
      this.configurationModal = true
    },
    handleScroll (event) {
      const elem = document.getElementById('nodesCard')
      const search = document.getElementById('nodesSearchFixed').childNodes[0]
      const searchCard = document.getElementById('nodesSearch')

      var posTopView = window.scrollY
      var elemTop = elem.offsetTop + 126

      if (elemTop > posTopView) {
        search.classList.remove('visible')
        if (this.keyFocus) {
          searchCard.focus()
          this.keyFocus = false
        }
      } else {
        search.classList.add('visible')
        this.keyFocus = true
      }
    },
    lastUpdate (message) {
      if (message !== undefined) {
        return this.parseDate(message.lastEventNodeDate)
      } else {
        return this.$i18n.t('node.noMessages')
      }
    },
    messageColor (message) {
      if (message === undefined) {
        return 'red darken-4'
      }

      const statusInt = message.lastEventNode

      if (statusInt === 100 || statusInt === 20) {
        return 'red darken-4'
      }

      const messageTime = message.lastEventNodeDate
      const seconds = (Date.now() / 1000) - messageTime
      if (seconds < 6400) {
        return 'green'
      } else if (seconds < 7200) {
        return 'yellow darken-2'
      }

      return 'red darken-4'
    },
    messageToText (message) {
      if (message === undefined) {
        return this.$i18n.t('node.noMessages')
      }
      
      const messageEvent = message.lastEventNodeString
      if (messageEvent === null) {
        return this.$i18n.t('node.noMessages')
      }

      const messageTime = message.lastEventNodeDate
      const seconds = (Date.now() / 1000) - messageTime
      if (seconds < 6400) {
        return messageEvent
      } else if (seconds < 7200) {
        return 'Falla Reporte Periódico'
      }

      return 'Falla Reporte Periódico'
    },

    addRule () {
      this.object.ruleNotifications.push({
        id: null,
        name: '',
        priorityId: 0,
        categoryAlertId: 0,
        message: '',
        messageVoice: '',
        calculation: {
          type: 'RANGE',
          operatorMinimum: '>=',
          operatorMaximum: '<=',
          valueMinimum: 0,
          valueMaximum: 0,
          mask: '%val%'
        },
        deliveryMethods: {
          email: {
            active: false,
            destinations: []
          },
          telegram: {
            active: false,
            chatId: ''
          },
          sendRequest: {
            active: false,
            type: 'GET',
            url: ''
          },
          twilio: {
            active: false,
            destinations: [],
            callDestinations: []
            // approved_by_central: false
          }
        }
      })
      this.ruleObject = Object.assign({}, {
        id: null,
        name: '',
        priorityId: 0,
        categoryAlertId: 0,
        message: '',
        messageVoice: '',
        calculation: {
          type: 'RANGE',
          operatorMinimum: '>=',
          operatorMaximum: '<=',
          valueMinimum: 0,
          valueMaximum: 0,
          mask: '%val%'
        },
        deliveryMethods: {
          email: {
            active: false,
            destinations: []
          },
          telegram: {
            active: false,
            chatId: ''
          },
          sendRequest: {
            active: false,
            type: 'GET',
            url: ''
          }, 
          twilio: {
            active: false,
            destinations: [],
            callDestinations: []
            // approved_by_central: false
          }
        }
      })
      this.ruleModal = true
      this.ruleStateNew = true
      this.ruleIndex = this.object.ruleNotifications.length - 1
    },

    editRule (i) {
      this.ruleIndex = i
      this.ruleObject = Object.assign({}, this.object.ruleNotifications[i])
      this.ruleModal = true
      this.ruleStateNew = false
    },

    saveRule () {
      if (this.ruleIndex !== null) {
        this.object.ruleNotifications[this.ruleIndex] = Object.assign({}, this.ruleObject)
        this.ruleModal = false
        this.ruleIndex = null

        const rules = this.object.ruleNotifications
        this.object.ruleNotifications = []
        this.object.ruleNotifications = Object.values(Object.assign({}, rules))
      }
    },

    discardNewRule () {
      this.ruleModal = false
      this.ruleObject = { 
        calculation: {},
        deliveryMethods: {
          email: {
            active: false,
            destinations: []
          },
          telegram: {
            active: false,
            chatId: ''
          },
          sendRequest: {
            active: false,
            type: 'GET',
            url: ''
          },
          twilio: {
            active: false,
            destinations: [],
            callDestinations: []
            // approved_by_central: false
          }
        }
      }
      this.object.ruleNotifications.pop()
      this.ruleIndex = null
    },

    discardRule () {
      this.ruleModal = false
      this.ruleObject = { 
        calculation: {}, 
        deliveryMethods: {
          email: {
            active: false,
            destinations: []
          },
          telegram: {
            active: false,
            chatId: ''
          },
          sendRequest: {
            active: false,
            type: 'GET',
            url: ''
          },
          twilio: {
            active: false,
            destinations: [],
            callDestinations: []
            // approved_by_central: false
          }
        }
      }
      this.ruleIndex = null
    },

    deleteRule (i) {
      this.object.ruleNotifications.splice(i, 1)
      this.discardRule()
    },
    parseDate (date) {
      return moment.unix(date).format('YYYY-MM-DD HH:mm:ss')
    },

    locateGeofence () {
      if (this.circle !== null) {
        this.circle.setCenter({ lat: parseFloat(this.object.geofence.latitude), lng: parseFloat(this.object.geofence.longitude) })
        this.map.setCenter({ lat: parseFloat(this.object.geofence.latitude), lng: parseFloat(this.object.geofence.longitude) })
        this.circle.setRadius(parseFloat(this.object.geofence.radius))
      }
    },

    parseErrors (array) {
      if (array === undefined) {
        return []
      }

      return array.map((currentValue, index, array) => {
        return currentValue.charAt(0).toUpperCase() + currentValue.substr(1)
      }).join(', ')
    },

    saveChanges () {
      this.graphQLWrapper(`edit${objectName.object.single}`)
    },

    async editItem (id) {
      if (this.object.id === undefined) {
        await this.showItem(id)
      }
      
      this.isEditting = true
    },

    drawGeofence () {
      if (this.map === null) {
        this.map = new window.google.maps.Map(document.getElementById('mapLora'), {
          zoom: 16,
          center: { lat: parseFloat(this.object.geofence.latitude), lng: parseFloat(this.object.geofence.longitude) }
        })
      } else {
        this.map.setCenter({ lat: parseFloat(this.object.geofence.latitude), lng: parseFloat(this.object.geofence.longitude) })
      }

      this.circle = new google.maps.Circle({
        strokeColor: '#FF0000',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#FF0000',
        fillOpacity: 0.35,
        map: this.map,
        center: { lat: parseFloat(this.object.geofence.latitude), lng: parseFloat(this.object.geofence.longitude) },
        radius: parseFloat(this.object.geofence.radius)
      })
    },

    drawFields () {
      if (this.object.id !== undefined) {
        this.fieldGeofences = []
        const map = this.map
        const fields = this.fieldBatches.filter((item) => item.loraNodeId === this.object.id)
        for (var i in fields) {
          const object = fields[i]
          this.fieldGeofences.push(new window.google.maps.Polygon({
            paths: object.geofence.map((item, index, arr) => { return { lat: item.latitude, lng: item.longitude } }),
            strokeColor: '#1565c0',
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: '#1565c0',
            fillOpacity: 0.35
          }))
          this.fieldGeofences[i].setMap(map)
        }
      }
    },

    cleanFields () {
      for (var i in this.fieldGeofences) {
        this.fieldGeofences[i].setMap(null)
      }
      this.fieldGeofences = []
    },

    async showItem (id) {
      await this.$apollo.query({
        query: object,
        variables: {
          id,
          apiToken: this.user.apiToken,
          language: this.language.toUpperCase()
        },
        fetchPolicy: 'no-cache'
      }).then(response => {
        const result = response.data[objectName.single]
        
        if (result.status) {
          this.object = {
            ...result[objectName.single]
          }
          this.object.createdAtValue = `${this.$date(this.object.createdAt)} ${this.$time(this.object.createdAt)}`
          setTimeout(this.drawGeofence, 1000)
          setTimeout(this.drawFields, 1000)
          this.formModal = true
          this.isEditting = false
        } else {
          if (errors.limit === undefined) {
            this.$store.commit('toggleAlert', {
              type: 'error',
              message: (errors.id === undefined ? errors.apiToken : errors.id)
            })
          } else {
            this.$store.commit('toggleAlert', {
              type: 'error',
              message: errors.limit
            })
          }
        }
      }).catch(error => {
        this.$store.commit('toggleAlert', {
          type: 'error',
          message: this.$i18n.t('errors.internalError')
        })
      })
    },

    discardChanges () {
      this.objectErrors = {}
      this.object = {}
      this.object = Object.assign({}, this.defaultObject)
      this.formModal = false
      this.isEditting = false
      this.cleanFields()
    },

    newItem () {
      this.objectErrors = {}
      this.object = {}
      this.object = Object.assign({}, this.defaultObject)
      this.formModal = true
      this.isEditting = true
      setTimeout(this.drawGeofence, 100)
    },

    createItem () {
      this.graphQLWrapper(`add${objectName.object.single}`)
    },

    graphQLWrapper (mutationName) {
      this.buttonState = true
      this.objectErrors = {}
      const variables = {
        object: this.object,
        apiToken: this.user.apiToken,
        language: this.language.toUpperCase()
      }

      delete variables.object.__typename
      delete variables.object.geofence.__typename

      variables.object.geofence.latitude = parseFloat(variables.object.geofence.latitude)
      variables.object.geofence.longitude = parseFloat(variables.object.geofence.longitude)
      variables.object.geofence.radius = parseFloat(variables.object.geofence.radius)
      variables.object = Object.assign({}, variables.object)
      delete variables.object.createdAt
      delete variables.object.createdAtValue
      let mutation = add
      
      for (const i in variables.object.ruleNotifications) {
        delete variables.object.ruleNotifications[i].__typename

        variables.object.ruleNotifications[i].calculation.valueMinimum = parseFloat(variables.object.ruleNotifications[i].calculation.valueMinimum)
        variables.object.ruleNotifications[i].calculation.valueMaximum = parseFloat(variables.object.ruleNotifications[i].calculation.valueMaximum)

        delete variables.object.ruleNotifications[i].calculation.__typename
      }

      if (mutationName.includes('edit')) {
        mutation = edit
      }

      this.$apollo.mutate({
        mutation,
        variables
      }).then(response => {
        const { status, errors, result } = response.data[mutationName]

        if (status === 'OK') {
          this.object = {
            ...result
          }
          this.object.createdAtValue = `${this.$date(this.object.createdAt)} ${this.$time(this.object.createdAt)}`
          this.objectErrors = {}
          this.isEditting = false
          this.$store.dispatch(`get${objectName.object.multiple}`)
          this.$store.commit('toggleAlert', {
            type: 'success',
            message: this.$i18n.t(`${objectName.multiple}.success.${(mutationName.includes('edit') ? 'updated' : 'created')}`, { name: result.name })
          })
        } else {
          this.objectErrors = errors
          this.$store.commit('toggleAlert', {
            type: 'warning',
            message: this.$i18n.t('errors.invalidFields')
          })
        }
      }).catch(error => {
        this.$store.commit('toggleAlert', {
          type: 'error',
          message: this.$i18n.t('errors.internalError')
        })
      }).finally(() => {
        this.buttonState = false
      })
    },

    async deleteItem (id) {
      this.deleteId = id
      this.deleteDialog = true
    },

    async deleteConfirmation () {
      await this.$store.dispatch('deleteItem', {
        mutation: {
          graphql: `delete${objectName.object.single}`,
          vuex: `get${objectName.object.multiple}`
        },
        object: objectName.multiple,
        variables: {
          id: this.deleteId,
          apiToken: this.user.apiToken,
          language: this.language
        }
      })

      this.deleteDialog = false
      this.deleteId = 0
      this.deleteConfirm = false
    }
  }
}
</script>
<style lang="scss">
  .v-toolbar {
    min-height: 70px;
    border-radius: 3px;
    margin-bottom: 0px; 
  }
</style> 