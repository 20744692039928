<template>
  <v-container pt-0 fluid>
    <v-layout row wrap pb-3>
      <v-flex v-if="user.parentType !== 'Company::Depository'" xs12 class="pb-4">
        <v-card>
          <v-card-text class="">
            <v-layout row wrap>
              <template v-if="user.parentType === 'Company::Central' || user.parentType === 'Company::Promotor'">
                <v-flex xs8 class="pa-2">
                  <v-select
                    v-model="companyLocalSelected"
                    :items="companyLocalsData"
                    outlined
                    item-text="name"
                    item-value="id"
                    :label="$t('fieldBatches.companyLocal')"
                    hide-details
                  />
                </v-flex>
              </template>

              <v-flex xs4 class="pa-2 d-flex align-center justify-end">
                <v-btn v-if="isFiltered" class="mr-1" icon small color="secondary"
                       @click="reloadData()"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-btn color="primary" @click="filterData()">{{ $t('actions.filter') }}</v-btn>
              </v-flex>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-flex>
      <v-flex>
        <v-card
          class="elevation-12"
        >
          <v-toolbar flat color="primary">
            <v-toolbar-title class="yellow-color-text">
              {{ $t(`pages.${$route.name}`) }}
            </v-toolbar-title>
            
            <v-spacer />
            
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  slot="activator"
                  color="white"
                  text
                  icon
                  ma-0
                  rounded
                  style="margin: 0;"
                  v-on="on"
                  @click="newItem"
                >
                  <v-icon class="yellow-color-text">mdi-plus-circle-outline</v-icon>
                </v-btn>
              </template>

              <span class="white--text">{{ $t(`${objectName.multiple}.titleNew`) }}</span>
            </v-tooltip>
          </v-toolbar>
          <v-card-text class="py-2">
          
            <v-text-field
              v-model="search"
              append-icon="search"
              :label="$t('actions.search')"
              single-line
              :color="inputColor"
              hide-details
            />
            <v-data-table
              :headers="headers"
              :items="dataList"
              :search="search"
              :pagination.sync="pagination"
              next-icon="mdi-chevron-right"
              prev-icon="mdi-chevron-left"
            >
              <template v-slot:body="{ items }">
                <tr v-for="item in items" :key="item.id">
                  <td>{{ item.name }}</td>
                  <td>{{ item.fiscalName }}</td>
                  <td>{{ item.fiscalNumber }}</td>
                  <td style="text-align: right; padding: 0;">
                    <v-btn
                      flat
                      icon
                      round
                      color="info"
                      @click="showItem(item.id)"
                    >
                      <v-icon>mdi-file-find-outline</v-icon>
                    </v-btn>
                    <v-btn
                      flat
                      icon
                      round
                      color="warning"
                      @click="editItem(item.id)"
                    >
                      <v-icon>mdi-file-document-edit-outline</v-icon>
                    </v-btn>

                    <v-btn
                      flat
                      icon
                      round
                      color="error"
                      @click="deleteItem(item.id)"
                    >
                      <v-icon>mdi-file-cancel-outline</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </template>
              <tr slot="no-results">
                <td :colspan="headers.length" v-html="$t('helpers.notFound', { search })" />
              </tr>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>

    <v-dialog
      id="formModal"
      v-model="formModal"
      scrollable
      persistent
      :fullscreen="isFullScreen"
      :overlay="false"
      :max-width="width.dialog"
      transition="dialog-transition"
      :dark="dark"
    >
      <v-card class="elevation-12">
        <!-- <v-card-title class="headline primary elevation-3" style="text-align: center; max-height: 58px;"><h5 class="white--text ma-0">{{ title }}</h5></v-card-title> -->
        <v-toolbar flat color="primary">
          <v-toolbar-title class="yellow-color-text">
            {{ title }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="py-2">
          <v-layout row wrap align-center justify-center>
            <v-flex class="px-2" xs12 sm6 :md4="user.parentType === 'Administrator'">
              <v-text-field
                v-model="object.name"
                :label="$t(`${objectName.multiple}.name`)"
                prepend-icon="mdi-account-circle-outline"
                :disabled="!isEditting"
                :color="inputColor"
                :error="objectErrors.name !== undefined"
                :error-messages="parseErrors(objectErrors.name)"
              />
            </v-flex>

            <v-flex class="px-2" xs12 sm6 :md4="user.parentType === 'Administrator'">
              <v-text-field
                v-model="object.phone"
                :label="$t(`${objectName.multiple}.phone`)"
                prepend-icon="mdi-phone-in-talk"
                :disabled="!isEditting"
                :color="inputColor"
                :error="objectErrors.phone !== undefined"
                :error-messages="parseErrors(objectErrors.phone)"
              />
            </v-flex>

            <v-flex v-if="user.parentType !== 'Company::Local'" class="px-2" xs12 sm6 md4>
              <v-select
                v-model="object.parentId"
                prepend-icon="mdi-car-multiple"
                :items="companyLocals"
                :disabled="!isEditting"
                :label="$t(`${objectName.multiple}.parentId`)"
                item-text="name"
                item-value="id"
              />
            </v-flex>

            <v-flex class="px-2" xs12 sm6 :md4="isEditting">
              <v-text-field
                v-model="object.username"
                :label="$t(`${objectName.multiple}.username`)"
                prepend-icon="mdi-account-card-details-outline"
                :disabled="!isEditting"
                :color="inputColor"
                :error="objectErrors.username !== undefined"
                :error-messages="parseErrors(objectErrors.username)"
              />
            </v-flex>

            <v-flex class="px-2" xs12 sm6 :md4="isEditting">
              <v-text-field
                v-model="object.email"
                :label="$t(`${objectName.multiple}.email`)"
                prepend-icon="mdi-email-outline"
                :disabled="!isEditting"
                :color="inputColor"
                :error="objectErrors.email !== undefined"
                :error-messages="parseErrors(objectErrors.email)"
              />
            </v-flex>

            <v-flex class="px-2" v-if="isEditting" xs12 md4>
              <v-text-field
                v-model="object.password"
                :label="$t(`${objectName.multiple}.password`)"
                prepend-icon="mdi-key"
                :append-icon="(showPassword ? 'mdi-eye-off' : 'mdi-eye-outline')"
                :type="(showPassword ? 'text' : 'password')"
                :disabled="!isEditting"
                :color="inputColor"
                :error="objectErrors.password !== undefined || (object.password.length > 0 && object.id !== undefined)"
                :error-messages="passwordErrors"
                @click:append="showPassword = !showPassword"
              />
            </v-flex>

            <v-flex class="px-2" xs12 sm6 md4>
              <v-text-field
                v-model="object.fiscalName"
                :label="$t(`${objectName.multiple}.fiscalName`)"
                prepend-icon="mdi-briefcase-outline"
                :disabled="!isEditting"
                :color="inputColor"
                :error="objectErrors.fiscalName !== undefined"
                :error-messages="parseErrors(objectErrors.fiscalName)"
              />
            </v-flex>

            <v-flex class="px-2" xs12 sm6 md4>
              <v-text-field
                v-model="object.fiscalNumber"
                :label="$t(`${objectName.multiple}.fiscalNumber`)"
                prepend-icon="mdi-numeric"
                :disabled="!isEditting"
                :color="inputColor"
                :error="objectErrors.fiscalNumber !== undefined"
                :error-messages="parseErrors(objectErrors.fiscalNumber)"
              />
            </v-flex>

            <v-flex class="px-2" xs12>
              <v-text-field
                v-model="object.fiscalAddress"
                :label="$t(`${objectName.multiple}.fiscalAddress`)"
                prepend-icon="mdi-map-marker"
                :disabled="!isEditting"
                :color="inputColor"
                :error="objectErrors.fiscalAddress !== undefined"
                :error-messages="parseErrors(objectErrors.fiscalAddress)"
              />
            </v-flex>
            <v-flex class="pl-2 pr-2 pb-2" xs12 sm6>
              <v-select
                v-model="object.province"
                :items="provinces"
                :label="$t(`${objectName.multiple}.province`)" 
                :disabled="!isEditting"
                :color="inputColor"
                item-text="iso_nombre"
                item-value="iso_nombre"
                :error="objectErrors.province !== undefined"
                :error-messages="parseErrors(objectErrors.province)"
              />
            </v-flex>
            <v-flex class="pl-2 pr-2 pb-2" xs12 sm6>
              <v-select
                v-model="object.location"
                :items="locations"
                :label="$t(`${objectName.multiple}.location`)" 
                :disabled="!isEditting"
                :color="inputColor"
                item-text="nombre"
                item-value="nombre"
                :error="objectErrors.location !== undefined"
                :error-messages="parseErrors(objectErrors.location)"
              />
            </v-flex>
            <!-- Add user information one by one -->
            <v-flex class="px-2" xs12 sm6>
              <v-text-field
                v-model="object.userName"
                :label="$t(`${objectName.multiple}.userName`)"
                :disabled="!isEditting"
                :color="inputColor"
                :error="objectErrors.userName !== undefined"
                :error-messages="parseErrors(objectErrors.userName)"
              />
            </v-flex>
            <v-flex class="px-2" xs12 sm6>
              <v-text-field
                v-model="object.userLastName"
                :label="$t(`${objectName.multiple}.userLastName`)"
                :disabled="!isEditting"
                :color="inputColor"
                :error="objectErrors.userLastName !== undefined"
                :error-messages="parseErrors(objectErrors.userLastName)"
              />
            </v-flex>
            <v-flex class="px-2" xs12 sm6>
              <v-text-field
                v-model="object.userCharacter"
                :label="$t(`${objectName.multiple}.userCharacter`)"
                :disabled="!isEditting"
                :color="inputColor"
                :error="objectErrors.userCharacter !== undefined"
                :error-messages="parseErrors(objectErrors.userCharacter)"
              />
            </v-flex>
            <v-flex class="pl-2 pr-2" xs12 sm6>
              <v-dialog
                ref="dialog"
                v-model="modal"
                :return-value.sync="object.userBirthDate"
                persistent
                lazy
                full-width
                width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="object.userBirthDate"
                    :label="$t(`${objectName.multiple}.userBirthDate`)" 
                    :disabled="!isEditting"
                    :color="inputColor"
                    :error="objectErrors.userBirthDate !== undefined"
                    :error-messages="parseErrors(objectErrors.userBirthDate)"
                    readonly
                    v-on="on"
                  />
                </template>
                <v-date-picker v-model="object.userBirthDate" scrollable>
                  <v-spacer />
                  <v-btn flat color="primary" @click="modal = false">Cancel</v-btn>
                  <v-btn flat color="primary" @click="$refs.dialog.save(object.userBirthDate)">OK</v-btn>
                </v-date-picker>
              </v-dialog>
            </v-flex>
          </v-layout>
        </v-card-text>

        <v-card-actions v-if="isEditting" style="max-height: 70px;">
          <v-btn color="error" @click="discardChanges">{{ $t('actions.discard') }}</v-btn>

          <v-spacer />

          <a-button v-if="object.id !== undefined" :state="buttonState" color="primary" @click="saveChanges">{{ $t('actions.save') }}</a-button>
          
          <a-button v-else-if="object.id === undefined" :state="buttonState" color="primary" @click="createItem">{{ $t('actions.create', { name: $t(`${objectName.multiple}.objectName`) }) }}</a-button>
        </v-card-actions>

        <v-card-actions v-else style="max-height: 70px;">
          <v-btn color="error" @click="discardChanges">{{ $t('actions.close') }}</v-btn>
          
          <v-spacer />
          
          <v-btn color="primary" @click="editItem(object.id)">{{ $t('actions.edit', { name: $t(`${objectName.multiple}.objectName`)}) }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="deleteDialog"
      max-width="290"
    >
      <v-card>
        <v-card-title class="headline" style="font-size: 1.2em !important; text-align: center;">{{ $t('helpers.confirmDelete', { name: $t(`${objectName.multiple}.objectName`) }) }}</v-card-title>

        <v-card-actions style="text-align: center;">
          <v-spacer />

          <v-btn
            color="error"
            flat
            icon
            @click="deleteConfirm = false; deleteDialog = false; deleteId = 0"
          >
            <v-icon>mdi-cancel</v-icon>
          </v-btn>

          <v-btn
            color="success"
            flat
            icon
            @click="deleteConfirmation"
          >
            <v-icon>mdi-checkbox-marked-circle-outline</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import AButton from '@/components/Button.vue'

import { mapState, mapGetters } from 'vuex'

import add from '@/graphql/mutations/companyDepositories/add.gql'
import edit from '@/graphql/mutations/companyDepositories/edit.gql'
import object from '@/graphql/queries/companyDepositories/detail.gql'

// Json of the provinces and locations
import provinces from '@/json/provinces.json'
import locations from '@/json/locations.json'

const objectName = {
  single: 'companyDepository',
  multiple: 'companyDepositories',
  object: {
    single: 'CompanyDepository',
    multiple: 'CompanyDepositories'
  }
}

export default {
  components: {
    AButton
  },

  data () {
    return {
      objectName,
      companyLocalSelected: null,
      isFiltered: false,
      dataList: [],
      pagination: { rowsPerPage: 20 },
      formModal: false,
      isEditting: true,
      deleteDialog: false,
      showPassword: false,
      deleteConfirm: false,
      buttonState: false,
      deleteId: 0,
      object: {},
      objectErrors: {},
      search: '',
      headers: [
        {
          text: this.$i18n.t(`${objectName.multiple}.name`),
          align: 'left',
          sortable: true,
          value: 'name'
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.fiscalName`),
          align: 'left',
          sortable: true,
          value: 'fiscalName'
        },
        {
          text: this.$i18n.t(`${objectName.multiple}.fiscalNumber`),
          align: 'left',
          sortable: true,
          value: 'fiscalNumber'
        },
        {
          text: this.$i18n.t('helpers.actions'),
          align: 'right',
          sortable: false,
          value: 'actions'
        }
      ]
    }
  },

  computed: {
    ...mapState(['user', 'width', 'dark', 'language', 'companyLocals']),
    ...mapState({ data: objectName.multiple }),
    ...mapGetters(['inputColor']),
    provinces () {
      return provinces.provincias.sort((a, b) => {
        if (a.iso_nombre < b.iso_nombre) {
          return -1
        }
        if (a.iso_nombre > b.iso_nombre) {
          return 1
        }
        return 0
      })
    },

    locations () {
      return locations.localidades.filter((loc) => loc.provincia.nombre === this.object.province).sort((a, b) => {
        if (a.nombre < b.nombre) {
          return -1
        }
        if (a.nombre > b.nombre) {
          return 1
        }
        return 0
      })
    },
    companyLocalsData () {
      return [
        {
          id: null,
          name: this.$i18n.t('helpers.withoutCompanyLocal')
        },
        ...this.companyLocals
      ]
    },
    defaultObject () {
      return {
        id: undefined,
        name: '',
        phone: '',
        username: '',
        email: '',
        password: '',
        province: '',
        location: '',
        userName: '',
        userLastName: '',
        userCharacter: '',
        userBirthDate: ''
      }
    },

    passwordErrors () {
      if (this.object.id === undefined) {
        return this.parseErrors(this.objectErrors.password)
      } else {
        let errors = this.parseErrors(this.objectErrors.password)
        if (this.object.password.length > 0 && errors.length === 0) {
          return [this.$i18n.t('helpers.passwordWillChange')]
        } else {
          return errors
        }
      }
    },

    isFullScreen () {
      return this.width.full < 990
    },

    title () {
      if (!this.isEditting) {
        return this.$i18n.t(`${objectName.multiple}.titleShow`, { name: this.object.name })
      } else {
        if (this.object.id === undefined) {
          return this.$i18n.t(`${objectName.multiple}.titleNew`)
        } else {
          return this.$i18n.t(`${objectName.multiple}.titleEdit`, { name: this.object.name })
        }
      }
    }
  },
  watch: {
    data (value) {
      this.dataList = value
    }
  },
  created () {
    this.object = Object.assign({}, this.defaultObject)
  },
  mounted () {
    this.dataList = this.data
  },
  methods: {
    filterData () {
      if (this.companyLocalSelected !== undefined) {
        this.dataList = this.data.filter(element => element.parentId === this.companyLocalSelected)
        this.isFiltered = true
      }
    },

    reloadData () {
      this.dataList = this.data
      this.isFiltered = false
    },
    formatPhone (value) {
      if (value.length === 14) {
        return `+${value[0]}${value[1]}-${value[2]}-${value[3]}${value[4]}${value[5]}-${value[6]}${value[7]}${value[8]}${value[9]}-${value[10]}${value[11]}${value[12]}${value[13]}`
      } else {
        return 'NA'
      }
    },

    parseErrors (array) {
      if (array === undefined) {
        return []
      }

      return array.map((currentValue, index, array) => {
        return currentValue.charAt(0).toUpperCase() + currentValue.substr(1)
      }).join(', ')
    },

    saveChanges () {
      this.graphQLWrapper(`edit${objectName.object.single}`)
    },

    async editItem (id) {
      if (this.object.id === undefined) {
        await this.showItem(id)
      }
      
      this.isEditting = true
    },

    async showItem (id) {
      await this.$apollo.query({
        query: object,
        variables: {
          id,
          apiToken: this.user.apiToken,
          language: this.language.toUpperCase()
        },
        fetchPolicy: 'no-cache'
      }).then(response => {
        let result = response.data[objectName.single]
        
        if (result.status) {
          this.object = {
            ...result[objectName.single],
            password: ''
          }
          this.formModal = true
          this.isEditting = false
        } else {
          if (errors.limit === undefined) {
            this.$store.commit('toggleAlert', {
              type: 'error',
              message: (errors.id === undefined ? errors.apiToken : errors.id)
            })
          } else {
            this.$store.commit('toggleAlert', {
              type: 'error',
              message: errors.limit
            })
          }
        }
      }).catch(error => {
        
        this.$store.commit('toggleAlert', {
          type: 'error',
          message: this.$i18n.t('errors.internalError')
        })
      })
    },

    discardChanges () {
      this.objectErrors = {}
      this.object = {}
      this.object = Object.assign({}, this.defaultObject)
      this.formModal = false
      this.isEditting = false
    },

    newItem () {
      this.objectErrors = {}
      this.object = {}
      this.object = Object.assign({}, this.defaultObject)
      this.formModal = true
      this.isEditting = true
    },

    createItem () {
      this.graphQLWrapper(`add${objectName.object.single}`)
    },

    graphQLWrapper (mutationName) {
      this.buttonState = true
      this.objectErrors = {}
      let variables = {
        object: this.object,
        apiToken: this.user.apiToken,
        language: this.language.toUpperCase()
      }

      delete variables.object.__typename
      
      let mutation = add

      if (mutationName.includes('edit')) {
        mutation = edit
      }

      this.$apollo.mutate({
        mutation,
        variables
      }).then(response => {
        
        let { status, errors, result } = response.data[mutationName]

        if (status === 'OK') {
          this.object = {
            ...result,
            password: ''
          }
          this.objectErrors = {}
          this.isEditting = false
          this.$store.dispatch(`get${objectName.object.multiple}`)
          this.$store.commit('toggleAlert', {
            type: 'success',
            message: this.$i18n.t(`${objectName.multiple}.success.${(mutationName.includes('edit') ? 'updated' : 'created')}`, { name: result.name })
          })
        } else {
          this.objectErrors = errors
          this.$store.commit('toggleAlert', {
            type: 'warning',
            message: this.$i18n.t('errors.invalidFields')
          })
        }
      }).catch(error => {
        this.$store.commit('toggleAlert', {
          type: 'error',
          message: this.$i18n.t('errors.internalError')
        })
      }).finally(() => {
        this.buttonState = false
      })
    },

    async deleteItem (id) {
      this.deleteId = id
      this.deleteDialog = true
    },

    async deleteConfirmation () {
      await this.$store.dispatch('deleteItem', {
        mutation: {
          graphql: `delete${objectName.object.single}`,
          vuex: `get${objectName.object.multiple}`
        },
        object: objectName.multiple,
        variables: {
          id: this.deleteId,
          apiToken: this.user.apiToken,
          language: this.language
        }
      })

      this.deleteDialog = false
      this.deleteId = 0
      this.deleteConfirm = false
    }
  }
}
</script> 