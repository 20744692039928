<template>
  <v-btn
    :block="block !== undefined"
    :small="small !== undefined"
    :depressed="depressed !== undefined"
    :color="color"
    @click="$emit('click')"
    :loading="state"
    class="ml-0 mr-0"
  >
    <slot />
  </v-btn>
</template>

<script>
export default {
  props: {
    state: {
      type: Boolean,
      default: false
    },

    small: {
      type: String,
      default: undefined
    },

    block: {
      type: String,
      default: undefined
    },

    depressed: {
      type: String,
      default: undefined
    },

    color: {
      type: String,
      default: 'primary'
    }
  }
}
</script>
