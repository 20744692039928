<template>
  <v-dialog
    id="bagStatesDialog"
    v-model="modal"
    scrollable
    persistent
    :fullscreen="isFullScreen"
    :overlay="false"
    transition="dialog-transition"
    :dark="dark"
  >
    <v-card>
      <v-card-title class="headline primary elevation-3" style="text-align: center; max-height: 58px;"><h5 class="white--text ma-0">{{ title }}</h5></v-card-title>
      <v-card-text style="flex: 1;" class="pa-4">
        <v-layout row wrap pa-1 align-center justify-center>
          <v-flex class="pl-1 pr-1" xs12>
            <v-autocomplete
              v-model="state"
              :items="states"
              :label="$t(`bags.state.title`)" 
              :color="inputColor"
              item-text="name"
              item-value="value"
            />
          </v-flex>
          <v-flex class="pl-1 pr-1" xs12>
            <v-autocomplete
              v-model="bagsIds"
              :items="bags"
              :label="$t(`bags.state.bags`)" 
              :color="inputColor"
              multiple
              item-text="reference"
              item-value="id"
            />
          </v-flex>
        </v-layout>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" text @click="modal = false">Cerrar</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="success" text @click="saveConfiguration">Guardar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
  
  <script>
  
  import { mapState, mapGetters } from 'vuex'
  export default {
  
    name: 'BagStatesDialog',
    props: {
      dialog: {
        type: Boolean,
        default: false
      }
    },
    data: () => ({
      title: 'Actualización de estados de bolsa',
      forceUpdate: 0,
      state: 'active',
      bagsIds: []
    }),
    computed : {
      ...mapState(['user', 'width', 'dark', 'language']),
      ...mapGetters(['inputColor']),
      ...mapGetters([ 'unclosedBags' ]),
      bags () {
        return this.unclosedBags.filter(bag => bag.state !== this.state)
      },
      states () {
        return [
          {
            name: this.$i18n.t('bags.state.active'),
            value: 'active'
          },
          {
            name: this.$i18n.t('bags.state.test'),
            value: 'test'
          },
          {
            name: this.$i18n.t('bags.state.on_load'),
            value: 'on_load'
          }
        ]
      },
      isFullScreen () {
        return this.width.full < 990
      },
      modal: {
        get () {
          return this.dialog
        },
        set (value) {
          this.$emit('update-dialog', value)
        }
      }
    },
    methods: {
      saveConfiguration() {
        // Save the configuration
        this.$store.dispatch('updateBagStates', { state: this.state, bagsIds: this.bagsIds, i18n: this.$i18n })
        this.modal = false
        // Clean state
        this.state = 'active'
        this.bagsIds = []
      }
    }
  }
  </script>
  