<template>
  <v-container pt-0 fluid>
    <v-layout row wrap pb-3>
      <div id="messagesSearchFixed">
        <div class="searchFixed">
          <v-card outlined elevation="2" class="ma-3">
            <v-card-text>
              <v-text-field
                v-model="search"
                append-icon="search"
                :label="$t('actions.search')"
                single-line
                :color="inputColor"
                hide-details
              />
            </v-card-text>
          </v-card>
        </div>
      </div>
      <v-flex>
        <v-card
          id="messagesCard"
          elevation="12"
        >
          <v-toolbar flat color="primary">
            <v-toolbar-title class="yellow-color-text">
              {{ $t(`pages.${$route.name}`) }}
            </v-toolbar-title>

            <v-spacer />
            <v-text-field
              id="messagesSearch"
              v-model="search"
              append-icon="search"
              :label="$t('actions.search')"
              single-line
              :color="inputColor"
              hide-details
            />
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  slot="activator"
                  color="white"
                  text
                  icon
                  ma-0
                  rounded
                  style="margin: 0;"
                  v-on="on"
                  @click="updateMessages"
                >
                  <v-icon>mdi-package-down</v-icon>
                </v-btn>
              </template>

              <span class="white--text">{{ $t(`${objectName.multiple}.updateMessages`) }}</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  slot="activator"
                  :color="(filtered && viewType === 'messages') ? 'yellow' : 'white'"
                  text
                  icon
                  ma-0
                  rounded
                  style="margin: 0;"
                  v-on="on"
                  @click="() => changeType(viewType === 'filters' ? 'messages' : 'filters')"
                >
                  <v-icon>{{ viewType === 'messages' ? 'mdi-filter' : 'mdi-email-outline' }}</v-icon>
                </v-btn>
              </template>

              <span class="white--text">{{ $t(`${objectName.multiple}.changeFilter`) }}</span>
            </v-tooltip>
          </v-toolbar>
          <v-card-text v-if="viewType ==='messages'">  
            <v-data-table
              fixed-header
              height="800"
              :headers="headers"
              :items="filtered ? filteredMessages : data"
              :search="search"
              :sort-by="['message_time']"
              :sort-desc="[true]"
              :items-per-page="50"
              :footer-props="{
                'next-icon': 'mdi-chevron-right',
                'prev-icon': 'mdi-chevron-left'
              }"
            >
              <template v-slot:body="{ items }">
                <tbody>
                  <tr v-for="item in items" :key="item.id">
                    <td class="text-xs-right">
                      {{ item.message_id }}
                    </td>
                    <td class="text-xs-right">{{ date(item.message_time) }} <br> {{ time(item.message_time) }}</td>
                    <td>{{ item.my_event_name }}</td>
                    <td>{{ item.bag_name }}</td>
                    <td>{{ item.field_batch }}</td>
                    <td>{{ item.lora_node }}</td>
                    <td>{{ item.subscriber_id }}</td>
                    <td>{{ item.lora_sensor }}</td>
                    <td class="text-xs-right">{{ item.vbats }}</td>
                    <td class="text-xs-right">{{ item.vbatg }}</td>
                  </tr>
                </tbody>
              </template>
              <tr slot="no-results">
                <td :colspan="headers.length" v-html="$t('helpers.notFound', { search })" />
              </tr>
            </v-data-table>
          </v-card-text>
          <v-card-text v-if="viewType === 'filters'">
            <v-layout row wrap pa-1 align-center justify-center>
              <v-flex class="pl-1 pr-1" xs12 md4>
                <!-- Start Date selector -->
                <v-menu
                  ref="startMenu"
                  v-model="startMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="startDate"
                      :label="$t(`${objectName.multiple}.startDate`)"
                      prepend-icon="event"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="startDate" no-title scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="startMenu = false">Cancel</v-btn>
                    <v-btn text color="primary" @click="$refs.startMenu.save(startDate)">OK</v-btn>
                  </v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex class="pl-1 pr-1" xs12 md4>
                <!-- Start Date selector -->
                <v-menu
                  ref="endMenu"
                  v-model="endMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="endDate"
                      :label="$t(`${objectName.multiple}.endDate`)"
                      prepend-icon="event"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="endDate" no-title scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="endMenu = false">Cancel</v-btn>
                    <v-btn text color="primary" @click="$refs.endMenu.save(endDate)">OK</v-btn>
                  </v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex class="pl-1 pr-1" xs12 md4>
                <v-autocomplete
                  v-model="eventType"
                  :items="eventTypes"
                  :label="$t(`${objectName.multiple}.eventType`)" 
                  :color="inputColor"
                  item-text="name"
                  item-value="value"
                />
              </v-flex>
              <v-flex class="pl-1 pr-1" xs12 md4>
                <v-autocomplete
                  v-model="bag"
                  :items="bags"
                  :label="$t(`${objectName.multiple}.bags`)" 
                  :color="inputColor"
                  item-text="name"
                  item-value="value"
                />
              </v-flex>
            <v-flex class="pl-1 pr-1" xs12 md4>
              <v-autocomplete
                v-model="fieldbatch"
                :items="fieldbatches"
                :label="$t(`${objectName.multiple}.fieldBatches`)" 
                :color="inputColor"
                item-text="name"
                item-value="value"
              />
            </v-flex>
            <v-flex class="pl-1 pr-1" xs12 md4>
                <v-autocomplete
                  v-model="node"
                  :items="nodes"
                  :label="$t(`${objectName.multiple}.nodes`)" 
                  :color="inputColor"
                  item-text="name"
                  item-value="value"
                />
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-card-actions v-if="viewType === 'filters'">
            <v-spacer></v-spacer>
            <v-btn
              color="danger"
              text
              @click="cleanFilters"
            >
              {{ $t(`${objectName.multiple}.cleanFilters`) }}
            </v-btn>
            <v-btn
              color="primary"
              @click="filterData"
            >
              {{ $t(`${objectName.multiple}.filter`) }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import AButton from '@/components/Button.vue'
import DualList from 'vuetify-dual-list'

import { mapState, mapGetters } from 'vuex'

import add from '@/graphql/mutations/bags/add.gql'
import edit from '@/graphql/mutations/bags/edit.gql'
import object from '@/graphql/queries/bags/detail.gql'
import moment from 'moment'
const objectName = {
  single: 'bagmessage',
  multiple: 'messages',
  object: {
    single: 'Message',
    multiple: 'Messages'
  }
}

export default {
  components: {
    AButton,
    DualList
  },

  data () {
    return {
      objectName,
      filtered: false,
      viewType: 'messages',
      keys: { primary: 'name', secondary: 'deviceEui' },
      i18n: this.$i18n.t('duallist3'),
      dense: true,
      drawingManager: null,
      pagination: { rowsPerPage: 20 },
      formModal: false,
      isEditting: true,
      deleteDialog: false,
      showPassword: false,
      deleteConfirm: false,
      buttonState: false,
      deleteId: 0,
      map: null,
      geofence: null,
      drawer: null,
      object: {},
      objectErrors: {},
      search: '',
      // ViewType: messages or filters
      viewType: 'messages',
      // Filters vriables
      startMenu: false,
      endMenu: false,
      startDate: null,
      endDate: null,
      eventType: null,
      bag: null,
      fieldbatch: null,
      node: null,
      filteredMessages: [],
      headers: [
        { 
          align: 'left',
          sortable: true,
          value: 'message_id',
          text: this.$i18n.t(`${objectName.multiple}.id`) 
        },
        { 
          align: 'left',
          sortable: true,
          text: this.$i18n.t(`${objectName.multiple}.messageTime`),
          value: 'message_time'
        },
        { 
          align: 'left',
          sortable: true,
          text: this.$i18n.t(`${objectName.multiple}.event`),
          value: 'my_event_name' 
        },
        { 
          align: 'left',
          sortable: true,
          text: this.$i18n.t(`${objectName.multiple}.bagName`),
          value: 'bag_name' 
        },
        { 
          align: 'left',
          sortable: true,
          text: this.$i18n.t(`${objectName.multiple}.fieldBatch`),
          value: 'field_batch' 
        },
        { 
          align: 'left',
          sortable: true,
          text: this.$i18n.t(`${objectName.multiple}.loraNode`),
          value: 'lora_node' 
        },
        { 
          align: 'left',
          sortable: true,
          text: this.$i18n.t(`${objectName.multiple}.subscriberId`),
          value: 'subscriber_id' 
        },
        { 
          align: 'left',
          sortable: true,
          text: this.$i18n.t(`${objectName.multiple}.loraSensors`),
          value: 'lora_sensor' 
        },
        { 
          align: 'left',
          sortable: true,
          text: this.$i18n.t(`${objectName.multiple}.voltage`),
          value: 'vbats'
        },
        { 
          align: 'left',
          sortable: true,
          text: this.$i18n.t(`${objectName.multiple}.nodeVoltage`),
          value: 'vbatsg'
        },
      ]
    }
  },

  computed: {
    ...mapState(['user', 'width', 'dark', 'language', 'messages']),
    ...mapState({ data: objectName.multiple }),
    ...mapGetters(['inputColor', 'unlinkedLoraSensors']),
    nodes () {
      return this.$store.state.loraNodes.map(node => {
        return {
          name: node.name,
          value: node.name
        }
      })
    },
    bags () {
      return this.$store.state.bags.map(bag => {
        return {
          name: bag.reference,
          value: bag.reference
        }
      })
    },
    fieldbatches () {
      return this.$store.state.fieldBatches.map(fieldbatch => {
        return {
          name: fieldbatch.name,
          value: fieldbatch.name
        }
      })
    },
    eventTypes () {
      return this.messages.map(message => {
        return {
          name: message.my_event_name,
          value: message.my_event_name
        }
      })
    },
    isMobile () {
      return this.width.full < 600
    },
    defaultObject () {
      return {
        id: undefined,
        reference: '',
        nomenclature: '',
        cycleId: null,
        productId: null,
        fieldBatchId: null,
        geofence: [],
        estimatedWeight: 0,
        realWeight: 0,
        localContactName: '',
        localContactPhone: '',
        loraSensors: [],
        newShape: null
      }
    },

    isFullScreen () {
      return this.width.full < 990
    },

    title () {
      if (!this.isEditting) {
        return this.$i18n.t(`${objectName.multiple}.titleShow`, { name: this.object.reference })
      } else {
        if (this.object.id === undefined) {
          return this.$i18n.t(`${objectName.multiple}.titleNew`)
        } else {
          return this.$i18n.t(`${objectName.multiple}.titleEdit`, { name: this.object.reference })
        }
      }
    }
  },

  created () {
    this.object = Object.assign({}, this.defaultObject)
    window.addEventListener('scroll', this.handleScroll)
  },

  destroyed () {
    window.removeEventListener('scroll', this.handleScroll)
  },

  mounted () {
    const card = document.querySelector('#messagesCard')
    const search = document.getElementById('messagesSearchFixed')

    var observer = new ResizeObserver(function (entries) {
      entries.forEach(function (entry) {
        search.style.width = `${card.offsetWidth}px`
      })
    })

    observer.observe(card)
  },

  methods: {
    changeType (type) {
      this.viewType = type
    },
    handleScroll (event) {
      const elem = document.getElementById('messagesCard')
      const search = document.getElementById('messagesSearchFixed').childNodes[0]
      const searchCard = document.getElementById('messagesSearch')

      var posTopView = window.scrollY
      var elemTop = elem.offsetTop + 126

      if (elemTop > posTopView) {
        search.classList.remove('visible')
        if (this.keyFocus) {
          searchCard.focus()
          this.keyFocus = false
        }
      } else {
        search.classList.add('visible')
        this.keyFocus = true
      }
    },

    date (date) {
      return moment(date).format('YYYY-MM-DD')
    },

    time (time) {
      return moment(time).format('HH:mm:ss')
    },
    
    updateMessages () {
      this.$store.dispatch('getMessages')
    },
    cleanFilters () {
      this.startDate = null
      this.endDate = null
      this.eventType = null
      this.bag = null
      this.fieldbatch = null
      this.node = null
      this.filteredMessages = this.data
      this.filtered = false
      this.viewType = 'messages'
    },
    filterData () {
      // Validate at least one filter is not null
      if (this.startDate === null && this.endDate === null && this.eventType === null && this.bag === null && this.fieldbatch === null && this.node === null) {
        this.$store.commit('toggleAlert', {
          type: 'error',
          message: this.$i18n.t('helpers.noFilterSelected')
        })
        return
      }
      this.filteredMessages = []
      for (const message of this.data) {
        let valid = true
        if (this.startDate !== null) {
          if (moment(message.message_time).isBefore(this.startDate)) {
            valid = false
          }
        }
        if (this.endDate !== null) {
          if (moment(message.message_time).isAfter(this.endDate)) {
            valid = false
          }
        }
        if (this.eventType !== null) {
          if (message.my_event_name !== this.eventType) {
            valid = false
          }
        }
        if (this.bag !== null) {
          if (message.bag_name !== this.bag) {
            valid = false
          }
        }
        if (this.fieldbatch !== null) {
          if (message.field_batch !== this.fieldbatch) {
            valid = false
          }
        }
        if (this.node !== null) {
          if (message.lora_node !== this.node) {
            valid = false
          }
        }
        if (valid) {
          this.filteredMessages.push(message)
        }
      }
      this.filtered = true
      this.viewType = 'messages'
      console.log(this.filteredMessages)
    },
    searchMethod (value, search, item) {
      if (search === '') {
        return true
      }
      let normalSearch = ''
      const searchValue = search.toLowerCase()
      // Do a split for ,
      const searchArray = searchValue.split(',')
      // Do a split for space : its going key value
      const searchMap = {}
      searchArray.forEach((search) => {
        const searchSplit = search.split(':')
        if (searchSplit.length === 2) {
          searchMap[searchSplit[0].trim()] = searchSplit[1].trim()
        }
        else {
          normalSearch += search + ' '
        }
      })

      // Convert searchMap to lower case and key from headers text to headers value
      for (const key in searchMap) {
        searchMap[key] = searchMap[key].toLowerCase()
        for (const header of this.headers) {
          if (header.text.toLowerCase() === key) {
            searchMap[header.value] = searchMap[key]
            delete searchMap[key]
          }
        }
      }
      console.log(searchMap)
      // Do normal search
      console.log(normalSearch)
      if (normalSearch !== '') {
        for (const key in item) {
          console.log(key)
          console.log(item[key])
          if (item[key] !== null) {
            normalSearch = normalSearch.trim()
            if (item[key].toString().toLowerCase().includes(normalSearch)) {
              return true
            }
          }
        }
      }
      console.log(searchMap)
      if (Object.keys(searchMap).length > 0) {
        for (const key in searchMap) {
          console.log(key + ' ' + searchMap[key])
          if (item[key] !== null) {
            if (item[key].toString().toLowerCase().includes(searchMap[key])) {
              return true
            }
          }
        }
      }

      return false

    }
  }
}
</script> 