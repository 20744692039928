// Main imports
import Vue from 'vue'
import VueI18n from 'vue-i18n'

// // Vuetify locales
import messages from '@/locales'

// Create component
Vue.use(VueI18n)

export default new VueI18n({
  locale: 'es',
  messages
})