<template>
  <v-footer :inset="true" height="50" style="padding: 15px 24px;">
    <v-spacer />

    <span class="font-weight-light copyright" :class="($store.state.dark ? 'white--text' : '')">
      &copy;
      {{ (new Date()).getFullYear() }}
      <a
        :class="$store.state.dark ? 'white--text' : 'primary--text'"
        style="font-weight: bold;"
        :href="$t('copyright.company.website')"
        target="_blank"
      >
        {{ $t('copyright.company.name') }}
      </a>
       | {{ $t('copyright.developer.by') }}
      <a
        :class="$store.state.dark ? 'white--text' : 'primary--text'"
        style="font-weight: bold;"
        :href="$t('copyright.developer.website')"
        target="_blank"
      >
        {{ $t('copyright.developer.name') }}
      </a>
    </span>
  </v-footer>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: mapGetters(['inputColor'])
}
</script>