<template>
  <v-container pt-0 fluid>
    <v-layout row wrap pb-3>
      <div id="btSensorsSearchFixed">
        <div class="searchFixed">
          <v-card outlined elevation="2" class="ma-3">
            <v-card-text>
              <v-text-field
                v-model="search"
                append-icon="search"
                :label="$t('actions.search')"
                single-line
                :color="inputColor"
                hide-details
              />
            </v-card-text>
          </v-card>
        </div>
      </div>
      <v-flex>
        <v-card
          id="btSensorsCard"
          elevation="12"
        >
          <v-toolbar flat color="primary">
            <v-toolbar-title class="yellow-color-text">
              {{ $t(`pages.${$route.name}`) }}
            </v-toolbar-title>

            <v-spacer />

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  color="white"
                  icon
                  ma-0
                  style="margin: 0;"
                  v-on="on"
                  @click="newItem"
                >
                  <v-icon class="yellow-color-text">mdi-plus-circle-outline</v-icon>
                </v-btn>  
              </template> 

              <span class="white--text">{{ $t(`${objectName.multiple}.titleNew`) }}</span>
            </v-tooltip>
          </v-toolbar>
          <v-card-text class="py-2">
            <v-text-field
              id="btSensorsSearch"
              v-model="search"
              append-icon="search"
              :label="$t('actions.search')"
              single-line
              :color="inputColor"
              hide-details
            />
            <v-data-table
              fixed-header
              height="800"
              :headers="headers"
              :items="data"
              :search="search"
              :items-per-page="-1"
              :footer-props="{
                'next-icon': 'mdi-chevron-right',
                'prev-icon': 'mdi-chevron-left'
              }"
            >
              <template v-slot:body="{ items }">
                <tr v-for="item in items" :key="item.id">
                  <td>{{ item.sensorId }}</td>
                  <!-- <td>{{ item.parameters }}</td> -->
                  <td>{{ item.loraSensorName === null ? "NA" : item.loraSensorName }} </td>
                  <td style="text-align: right; padding: 0;">
                    <v-btn
                      text
                      icon
                      rounded
                      color="info"
                      @click="showItem(item.id)"
                    >
                      <v-icon>mdi-file-find-outline</v-icon>
                    </v-btn>
                    <v-btn
                      text
                      icon
                      rounded
                      color="warning"
                      @click="editItem(item.id)"
                    >
                      <v-icon>mdi-file-document-edit-outline</v-icon>
                    </v-btn>

                    <v-btn
                      text
                      icon
                      rounded
                      color="error"
                      @click="deleteItem(item.id)"
                    >
                      <v-icon>mdi-file-cancel-outline</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </template>
              <tr slot="no-results">
                <td :colspan="headers.length" v-html="$t('helpers.notFound', { search })" />
              </tr>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>

    <v-dialog
      id="formModal"
      v-model="formModal"
      scrollable
      persistent
      :fullscreen="isFullScreen"
      :overlay="false"
      :max-width="width.dialog"
      transition="dialog-transition"
      :dark="dark"
    >
      <v-card>
        <v-toolbar flat color="primary">
          <v-toolbar-title class="yellow-color-text">
            {{ title }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="pa-0">
          <v-layout row wrap pa-1 align-center justify-center
                    pa-4
          >
            <v-flex class="pl-1 pr-1" xs12 sm6>
              <v-text-field
                v-model="object.sensorId"
                :label="$t(`${objectName.multiple}.sensorId`)"
                prepend-icon="mdi-label-variant"
                :disabled="!isEditting"
                :color="inputColor"
                :error="objectErrors.sensorId !== undefined"
                :error-messages="parseErrors(objectErrors.sensorId)"
              />
            </v-flex>

            <v-flex class="pl-1 pr-1" xs12 sm6>
              <v-text-field
                v-model="object.parameters"
                :label="$t(`${objectName.multiple}.parameters`)"
                prepend-icon="mdi-label-variant"
                :disabled="!isEditting"
                :color="inputColor"
                :error="objectErrors.parameters !== undefined"
                :error-messages="parseErrors(objectErrors.parameters)"
              />
            </v-flex>

            <v-flex class="pl-1 pr-1" xs12 sm6>
              <v-text-field
                v-model="object.latitude"
                :label="$t(`${objectName.multiple}.latitude`)"
                :disabled="!isEditting"
                type="number"
                max="90"
                min="-90"
                :color="inputColor"
                :error="objectErrors.latitude !== undefined"
                :error-messages="parseErrors(objectErrors.latitude)"
              />
            </v-flex>

            <v-flex class="pl-1 pr-1" xs12 sm6>
              <v-text-field
                v-model="object.longitude"
                :label="$t(`${objectName.multiple}.longitude`)"
                :disabled="!isEditting"
                type="number"
                max="180"
                min="-180"
                :color="inputColor"
                :error="objectErrors.longitude !== undefined"
                :error-messages="parseErrors(objectErrors.longitude)"
              />
            </v-flex>

            <v-flex class="pl-1 pr-1" xs12 sm6>
              <v-autocomplete
                v-model="object.location"
                :items="locations"
                :label="$t(`${objectName.multiple}.location`)"
                prepend-icon="mdi-nas"
                :disabled="!isEditting"
                :color="inputColor"
                :error="objectErrors.location !== undefined"
                :error-messages="parseErrors(objectErrors.location)"
              >
                <template slot="item" slot-scope="{item}">
                  <span>{{ item.locationName }}</span>
                  <v-spacer />
                  <small style="opacity: 0.5">{{ $t(`locations.${item.locationType.toLowerCase()}`) }}</small>
                </template>

                <template slot="selection" slot-scope="{item}">
                  <span>{{ item.locationName }} <small style="opacity: 0.5">({{ $t(`locations.${item.locationType.toLowerCase()}`) }})</small></span>
                </template>
              </v-autocomplete>
            </v-flex>

            <v-flex class="pl-1 pr-1" xs12 sm6>
              <v-autocomplete
                v-model="object.loraSensorId"
                :items="loraSensors"
                :label="$t(`${objectName.multiple}.loraSensor`)"
                prepend-icon="mdi-access-point"
                item-value="id"
                item-text="deviceEui"
                :disabled="!isEditting"
                :color="inputColor"
                :error="objectErrors.loraSensorId !== undefined"
                :error-messages="parseErrors(objectErrors.loraSensorId)"
              />
            </v-flex>
          </v-layout>
        </v-card-text>

        <v-card-actions v-if="isEditting" style="max-height: 70px;">
          <v-btn color="error" @click="discardChanges">{{ $t('actions.discard') }}</v-btn>

          <v-spacer />

          <a-button v-if="object.id !== undefined" :state="buttonState" color="primary" @click="saveChanges">{{ $t('actions.save') }}</a-button>
          
          <a-button v-else-if="object.id === undefined" :state="buttonState" color="primary" @click="createItem">{{ $t('actions.create', { name: $t(`${objectName.multiple}.objectName`) }) }}</a-button>
        </v-card-actions>

        <v-card-actions v-else style="max-height: 70px;">
          <v-btn color="error" @click="discardChanges">{{ $t('actions.close') }}</v-btn>
          
          <v-spacer />
          
          <v-btn color="primary" @click="editItem(object.id)">{{ $t('actions.edit', { name: $t(`${objectName.multiple}.objectName`)}) }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="deleteDialog"
      max-width="290"
    >
      <v-card>
        <v-card-title class="headline" style="font-size: 1.2em !important; text-align: center;">{{ $t('helpers.confirmDelete', { name: $t(`${objectName.multiple}.objectName`) }) }}</v-card-title>

        <v-card-actions style="text-align: center;">
          <v-spacer />

          <v-btn
            color="error"
            flat
            icon
            @click="deleteConfirm = false; deleteDialog = false; deleteId = 0"
          >
            <v-icon>mdi-cancel</v-icon>
          </v-btn>

          <v-btn
            color="success"
            flat
            icon
            @click="deleteConfirmation"
          >
            <v-icon>mdi-checkbox-marked-circle-outline</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import AButton from '@/components/Button.vue'

import { mapState, mapGetters } from 'vuex'

import add from '@/graphql/mutations/btSensors/add.gql'
import edit from '@/graphql/mutations/btSensors/edit.gql'
import object from '@/graphql/queries/btSensors/detail.gql'
import moment from 'moment'

const objectName = {
  single: 'btSensor',
  multiple: 'btSensors',
  object: {
    single: 'BtSensor',
    multiple: 'BtSensors'
  }
}

export default {
  components: {
    AButton
  },

  data () {
    return {
      objectName,
      pagination: { rowsPerPage: 20 },
      formModal: false,
      sensorModal: false,
      isEditting: true,
      deleteDialog: false,
      showPassword: false,
      deleteConfirm: false,
      buttonState: false,
      deleteId: 0,
      map: null,
      marker: null,
      object: {},
      sensorObj: {},
      objectErrors: {},
      search: '',
      ruleObject: {
        calculation: {},
        deliveryMethods: {
          email: {
            active: false,
            destinations: []
          },
          telegram: {
            active: false,
            chatId: ''
          },
          sendRequest: {
            active: false,
            type: 'GET',
            url: ''
          }
        }
      },
      ruleModal: false,
      ruleIndex: null,
      headers: [
        {
          text: this.$i18n.t(`${objectName.multiple}.sensorId`),
          align: 'left',
          sortable: true,
          value: 'sensorId'
        },
        // {
        //   text: this.$i18n.t(`${objectName.multiple}.parameters`),
        //   align: 'left',
        //   sortable: true,
        //   value: 'parameters'
        // },
        {
          text: this.$i18n.t(`${objectName.multiple}.loraSensor`),
          align: 'left',
          sortable: true,
          value: 'loraSensorName'
        },
        {
          text: this.$i18n.t('helpers.actions'),
          align: 'right',
          sortable: false,
          value: 'actions'
        }
      ]
    }
  },

  computed: {
    ...mapState(['user', 'width', 'dark', 'language']),
    ...mapState({ data: objectName.multiple }),
    ...mapGetters(['inputColor', 'locations', 'categoryAlertsBluetoothSensor']),
    messageHeaders () {
      return [
        {
          text: this.$i18n.t(`variables.name`),
          align: 'left',
          sortable: true,
          value: 'name'
        },
        {
          text: this.$i18n.t(`variables.variable`),
          align: 'left',
          sortable: true,
          value: 'variable'
        }
      ]
    },
    messageValues () {
      return [
        {
          name: this.$i18n.t(`variables.unitName`),
          value: '{unitName}'
        },
        {
          name: this.$i18n.t(`variables.unitId`),
          value: '{unitId}'
        },
        {
          name: this.$i18n.t(`variables.unitIdent`),
          value: '{unitIdent}'
        },
        {
          name: this.$i18n.t(`variables.triggeredSensorName`),
          value: '{triggeredSensorName}'
        },
        {
          name: this.$i18n.t(`variables.triggeredSensorValue`),
          value: '{triggeredSensorValue}'
        },
        {
          name: this.$i18n.t(`variables.notificationName`),
          value: '{notificationName}'
        },
        {
          name: this.$i18n.t(`variables.triggeredSensorValue`),
          value: '{triggeredSensorValue}'
        },
        {
          name: this.$i18n.t(`variables.timestamp`),
          value: '{timestamp}'
        },
        {
          name: this.$i18n.t(`variables.unixTimestamp`),
          value: '{unixTimestamp}'
        }
      ]
    },
    rulesHeaders () {
      return [
        {
          text: this.$i18n.t('loraNodes.ruleNotifications.name'),
          value: 'name',
          sortable: false
        },
        {
          text: this.$i18n.t('loraNodes.ruleNotifications.types.title'),
          value: 'types',
          sortable: false
        },
        {
          text: this.$i18n.t('loraNodes.ruleNotifications.calculation.title'),
          value: 'calculation',
          sortable: false
        },
        {
          text: this.$i18n.t('helpers.actions'),
          align: 'right',
          value: 'actions',
          sortable: false
        }
      ]
    },
    loraSensors () {
      return [
        {
          id: null,
          deviceEui: 'NA'
        },
        ...this.$store.state.loraSensors
      ]
    },

    defaultObject () {
      return {
        id: undefined,
        sensorId: '',
        loraSensorId: null,
        parameters: '',
        location: {},
        latitude: 0.0,
        longitude: 0.0
      }
    },

    isFullScreen () {
      return this.width.full < 990
    },

    title () {
      if (!this.isEditting) {
        return this.$i18n.t(`${objectName.multiple}.titleShow`, { name: this.object.name })
      } else {
        if (this.object.id === undefined) {
          return this.$i18n.t(`${objectName.multiple}.titleNew`)
        } else {
          return this.$i18n.t(`${objectName.multiple}.titleEdit`, { name: this.object.name })
        }
      }
    },
    ruleTitle () {
      return this.$i18n.t(`${objectName.multiple}.ruleNotifications.title`, { name: this.ruleObject.name })
    }
  },

  created () {
    this.object = Object.assign({}, this.defaultObject)
    window.addEventListener('scroll', this.handleScroll)
  },

  destroyed () {
    window.removeEventListener('scroll', this.handleScroll)
  },

  mounted () {
    const card = document.querySelector('#btSensorsCard')
    const search = document.getElementById('btSensorsSearchFixed')

    var observer = new ResizeObserver(function (entries) {
      entries.forEach(function (entry) {
        search.style.width = `${card.offsetWidth}px`
      })
    })

    observer.observe(card)
  },

  methods: {
    handleScroll (event) {
      const elem = document.getElementById('btSensorsCard')
      const search = document.getElementById('btSensorsSearchFixed').childNodes[0]
      const searchCard = document.getElementById('btSensorsSearch')

      var posTopView = window.scrollY
      var elemTop = elem.offsetTop + 126

      if (elemTop > posTopView) {
        search.classList.remove('visible')
        if (this.keyFocus) {
          searchCard.focus()
          this.keyFocus = false
        }
      } else {
        search.classList.add('visible')
        this.keyFocus = true
      }
    },

    addRule () {
      this.object.ruleNotifications.push({
        id: null,
        name: '',
        typeValue: 'temperature',
        categoryAlertId: 0,
        calculation: {
          type: 'RANGE',
          operatorMinimum: '>=',
          operatorMaximum: '<=',
          valueMinimum: 0,
          valueMaximum: 0,
          mask: '%val%'
        },
        message: '',
        deliveryMethods: {
          email: {
            active: false,
            destinations: []
          },
          telegram: {
            active: false,
            chatId: ''
          },
          sendRequest: {
            active: false,
            type: 'GET',
            url: ''
          }
        }
      })
    },

    editRule (i) {
      this.ruleIndex = i
      this.ruleObject = Object.assign({}, this.object.ruleNotifications[i])
      this.ruleModal = true
    },

    saveRule () {
      if (this.ruleIndex !== null) {
        this.object.ruleNotifications[this.ruleIndex] = Object.assign({}, this.ruleObject)
        this.ruleModal = false
        this.ruleIndex = null

        const rules = this.object.ruleNotifications
        this.object.ruleNotifications = []
        this.object.ruleNotifications = Object.values(Object.assign({}, rules))
      }
    },

    discardRule () {
      this.ruleModal = false
      this.ruleObject = { calculation: {},
        deliveryMethods: {
          email: {
            active: false,
            destinations: []
          },
          telegram: {
            active: false,
            chatId: ''
          },
          sendRequest: {
            active: false,
            type: 'GET',
            url: ''
          }
        }
      }
      this.ruleIndex = null
    },

    deleteRule (i) {
      this.object.ruleNotifications.splice(i, 1)
      this.discardRule()
    },
    parseErrors (array) {
      if (array === undefined) {
        return []
      }

      return array.map((currentValue, index, array) => {
        return currentValue.charAt(0).toUpperCase() + currentValue.substr(1)
      }).join(', ')
    },

    saveChanges () {
      this.graphQLWrapper(`edit${objectName.object.single}`)
    },

    async editItem (id) {
      if (this.object.id === undefined) {
        await this.showItem(id)
      }
      
      this.isEditting = true
    },

    async showItem (id) {
      await this.$apollo.query({
        query: object,
        variables: {
          id,
          apiToken: this.user.apiToken,
          language: this.language.toUpperCase()
        },
        fetchPolicy: 'no-cache'
      }).then(response => {
        const result = response.data[objectName.single]
        
        if (result.status) {
          this.object = {
            ...result[objectName.single]
          }

          delete this.object.location.__typename

          this.formModal = true
          this.isEditting = false
        } else {
          if (errors.limit === undefined) {
            this.$store.commit('toggleAlert', {
              type: 'error',
              message: (errors.id === undefined ? errors.apiToken : errors.id)
            })
          } else {
            this.$store.commit('toggleAlert', {
              type: 'error',
              message: errors.limit
            })
          }
        }
      }).catch(error => {
        this.$store.commit('toggleAlert', {
          type: 'error',
          message: this.$i18n.t('errors.internalError')
        })
      })
    },

    discardChanges () {
      this.objectErrors = {}
      this.object = {}
      this.object = Object.assign({}, this.defaultObject)
      this.formModal = false
      this.isEditting = false
    },

    newItem () {
      this.objectErrors = {}
      this.object = {}
      this.object = Object.assign({}, this.defaultObject)
      this.formModal = true
      this.isEditting = true
    },

    createItem () {
      this.graphQLWrapper(`add${objectName.object.single}`)
    },

    graphQLWrapper (mutationName) {
      this.buttonState = true
      this.objectErrors = {}
      const variables = {
        object: this.object,
        apiToken: this.user.apiToken,
        language: this.language.toUpperCase()
      }

      delete variables.object.__typename
      delete variables.object.location.__typename

      variables.object.latitude = parseFloat(variables.object.latitude)
      variables.object.longitude = parseFloat(variables.object.longitude)

      let mutation = add

      if (mutationName.includes('edit')) {
        mutation = edit
      }

      this.$apollo.mutate({
        mutation,
        variables
      }).then(response => {
        const { status, errors, result } = response.data[mutationName]

        if (status === 'OK') {
          this.object = {
            ...result
          }
          this.objectErrors = {}
          this.isEditting = false
          this.$store.dispatch(`get${objectName.object.multiple}`)
          this.$store.commit('toggleAlert', {
            type: 'success',
            message: this.$i18n.t(`${objectName.multiple}.success.${(mutationName.includes('edit') ? 'updated' : 'created')}`, { name: result.name })
          })
        } else {
          this.objectErrors = errors
          this.$store.commit('toggleAlert', {
            type: 'warning',
            message: this.$i18n.t('errors.invalidFields')
          })
        }
      }).catch(error => {
        this.$store.commit('toggleAlert', {
          type: 'error',
          message: this.$i18n.t('errors.internalError')
        })
      }).finally(() => {
        this.buttonState = false
      })
    },

    async deleteItem (id) {
      this.deleteId = id
      this.deleteDialog = true
    },

    async deleteConfirmation () {
      await this.$store.dispatch('deleteItem', {
        mutation: {
          graphql: `delete${objectName.object.single}`,
          vuex: `get${objectName.object.multiple}`
        },
        object: objectName.multiple,
        variables: {
          id: this.deleteId,
          apiToken: this.user.apiToken,
          language: this.language
        }
      })

      this.deleteDialog = false
      this.deleteId = 0
      this.deleteConfirm = false
    }
  }
}
</script> 