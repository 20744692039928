<template>
  <v-parallax :src="$assets.platform" :height="height">
    <v-container fluid fill-height class="pa-0">
      <transition name="component-fade" mode="out-in">
        <router-view />
      </transition>
    </v-container>
  </v-parallax>
</template>

<script>
export default {
  computed: {
    height () {
      return window.innerHeight
    }
  }
}
</script>
