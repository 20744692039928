<template>
    <v-speed-dial
      v-model="appDownloadState"
      bottom
      left
      fixed
      direction="top"
      transition="slide-y-transition"
    >
      <template v-slot:activator>
        <v-tooltip top>
          <template v-slot:activator="{ on: dial }">
            <button class="button-decorator" v-on="dial">
              <transition name="component-fade" mode="out-in">
                <v-icon>{{ appDownloadState ? 'mdi-close' : 'mdi-application'}}</v-icon>
              </transition>
            </button>
          </template>
  
          <span class="white--text">{{ $t('goto.apps') }}</span>
        </v-tooltip>
      </template>
  
      <template v-for="(path, index) in paths">
        <v-tooltip :key="index" right>
          <template v-slot:activator="{ on }">
            <v-btn
              fab
              dark
              small
              :color="`${path.color} darken-2`"
              @click="openUrl(path.path)"
              v-on="on"
            >
              <v-icon>{{ path.icon }}</v-icon>
            </v-btn>
          </template>
          
          <span class="white--text">{{ $t(`goto.${path.name}`) }}</span>
        </v-tooltip>
      </template>
    </v-speed-dial>
  </template>
  
  <script>
  export default {
    data () {
      return {
        appDownloadState: false,
        paths: [
          { path: '/gravanz-comercial.apk', icon: 'mdi-account', color: 'green', name: 'customer' },
          { path: '/gravanz-instaladores.apk', icon: 'mdi-account-hard-hat', color: 'yellow', name: 'installer' }
        ]
      }
    },
    methods: {
      openUrl (url) {
        window.open(url, '_blank')
      }
    }
  }
  </script>
  
  <style lang="scss">
  .button-decorator {
    width: 40px;
    height: 40px !important;
    background: var(--v-primary-base);
    border-radius: 5px;
  
    &:focus {
      outline: none !important;
    }
  
    &::after {
      content: '';
      height: 5px;
      width: 100%;
      background: var(--v-primary-lighten1);
      position: absolute;
      bottom: 0;
      left: 0;
      border-radius: 5px;
    }
  }
  </style>